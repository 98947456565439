export const REQUEST_ACTION = 'ibms-web/quotes/REQUEST_ACTION';
export const GET_NOTIFICATION_SUCCESS =
  'ibms-web/notify/GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE =
  'ibms-web/notify/GET_NOTIFICATION_FAILURE';
export const UPDATE_NOTIFICATION_SUCCESS =
  'ibms-web/notify/UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE =
  'ibms-web/notify/UPDATE_NOTIFICATION_FAILURE';
export const DELETE_NOTIFICATION = 'ibms-web/notify/DELETE_NOTIFICATION';
