export const REQUEST_ACTION = 'ibms-web/user/REQUEST_ACTION';
export const GET_INSURANCE_PROVIDER_SUCCESS =
  'ibms-web/user/GET_INSURANCE_PROVIDER_SUCCESS';
export const ADD_INSURANCE_PROVIDER_SUCCESS =
  'ibms-web/user/ADD_INSURANCE_PROVIDER_SUCCESS';
export const ADD_INSURANCE_PROVIDER_FAILURE =
  'ibms-web/user/ADD_INSURANCE_PROVIDER_FAILURE';
export const DELETE_INSURANCE_PROVIDER_SUCCESS =
  'ibms-web/user/DELETE_INSURANCE_PROVIDER_SUCCESS';
export const ADD_PROVIDER_RELATIONSHIP_MANAGER_SUCCESS =
'ibms-web/user/ADD_PROVIDER_RELATIONSHIP_MANAGER_SUCCESS';
export const GET_USER_DETAILS_SUCCESS =
  'ibms-web/user/GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE =
  'ibms-web/user/GET_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_SUCCESS =
  'ibms-web/user/UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_FAILURE =
  'ibms-web/user/UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_AVATAR_SUCCESS =
  'ibms-web/user/UPDATE_USER_AVATER_SUCCESS';
export const UPDATE_USER_AVATAR_FAILURE =
  'ibms-web/user/UPDATE_USER_AVATER_SUCCESS';
export const AVATAR_REQUEST_ACTION = 'ibms-web/user/AVATAR_REQUEST_ACTION';
export const UPDATE_PASSWORD_SUCCESS = 'ibms-web/user/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'ibms-web/user/UPDATE_PASSWORD_FAILURE';
export const GET_USER_PERMISSIONS_SUCCESS =
  'ibms-web/user/GET_USER_PERMISSIONS_SUCCESS';
export const GET_USER_PERMISSIONS_FAILURE =
  'ibms-web/user/GET_USER_PERMISSIONS_FAILURE';
