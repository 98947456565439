import {
  REQUEST_ACTION,
  GET_INSURANCE_CATEGORY_SUCCESS,
  GET_INSURANCE_LINE_OF_BUSINESS_SUCCESS,
  GET_INSURANCE_PRODUCT_SUCCESS,
  GET_COVERAGE_TYPE_SUCCESS,
  GET_COVERS_SUCCESS,
  SEARCH_COVERS_SUCCESS,
} from './actionTypes';
import axios from '../../../utils/http';
import { toast } from 'react-toastify';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const coverageTypes = (coverageType) => ({
  type: GET_COVERAGE_TYPE_SUCCESS,
  coverageType,
});

export const covers = (cover) => ({
  type: GET_COVERS_SUCCESS,
  cover,
});

export const searchCovers = (covers) => ({
  type: SEARCH_COVERS_SUCCESS,
  covers,
});

export const insuranceLineOfBusiness = (lineOfBusiness) => ({
  type: GET_INSURANCE_LINE_OF_BUSINESS_SUCCESS,
  lineOfBusiness,
});

export const insuranceCategory = (insuranceCategory) => ({
  type: GET_INSURANCE_CATEGORY_SUCCESS,
  insuranceCategory,
});

export const insuranceProduct = (insuranceProduct) => ({
  type: GET_INSURANCE_PRODUCT_SUCCESS,
  insuranceProduct,
});

/**
 * Create a thunk which makes an API call to get inusrance line of business
 *
 * @returns {Funtion}
 */
export const insuranceLineOfBusinessAction = () => async (dispatch) => {
  dispatch(requestAction());
  try {
    const response = await axios.get('/lineOfBusiness');
    const { data } = response;
    dispatch(insuranceLineOfBusiness(data.data));

    return data;
  } catch (error) {
    errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get insurance category
 *
 * @returns {Function}
 */
export const insuranceCategoryAction = (lineOfBusinessId) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(
      `/insuranceCategory?lineOfBusinessId=${lineOfBusinessId}`,
    );

    dispatch(insuranceCategory(data.data));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get insurance products
 *
 * @returns {Function}
 */
export const insuranceProductAction = (categoryId, providerId) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(
      `/product?providerId=${providerId}&categoryId=${categoryId}`,
    );

    dispatch(insuranceProduct(data.data));
    return data;
  } catch (error) {
    errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get the product types based on selected category
 * @returns {Function}
 */
export const getCoverageTypesAction = () => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/coverage/types`);
    dispatch(coverageTypes(data.data));

    return data;
  } catch (error) {
    errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call search for product type
 * @param {String} searchQuery - filter param
 * @returns {Function}
 */
export const searchProductTypeAction = (
  searchQuery,
  categoryId,
) => async () => {
  try {
    const data = await axios.post(
      `product/coverageType/search?categoryId=${categoryId}`,
      {
        searchQuery,
      },
    );
    return { success: true, data };
  } catch (error) {
    return errorHandler(error);
  }
};

export const getCoversAction = () => async (dispatch) => {
  dispatch(requestAction());
  try {
    const response = await axios.get('/covers');
    const { data } = response;
    dispatch(covers(data.data));

    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const searchCoversAction = (coverName) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('product/covers/search', {
      searchQuery: coverName,
    });

    return { success: true, data };
  } catch (error) {
    errorHandler(error);
  }
};

export const initialState = {
  insuranceCategoryData: [],
  lineOfBusinessData: [],
  productData: [],
  coverageTypesData: [],
  coversData: [],
  isLoading: false,
};

/**
 * This reducer changes the subscription state of the application
 *
 * @param {SubscriptionState} state
 * @param {Action} action
 *
 * @returns {SubscriptionState} state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INSURANCE_LINE_OF_BUSINESS_SUCCESS:
      return {
        ...state,
        lineOfBusinessData: action.lineOfBusiness,
        isLoading: false,
      };
    case GET_INSURANCE_CATEGORY_SUCCESS:
      return {
        ...state,
        insuranceCategoryData: action.insuranceCategory,
        isLoading: false,
      };
    case GET_INSURANCE_PRODUCT_SUCCESS:
      return {
        ...state,
        productData: action.insuranceProduct,
        isLoading: false,
      };
    case GET_COVERAGE_TYPE_SUCCESS:
      return {
        ...state,
        coverageTypesData: action.coverageType,
        isLoading: false,
      };
    case GET_COVERS_SUCCESS:
      return {
        ...state,
        coversData: action.cover,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
