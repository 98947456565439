import {
  GET_ACCOUNTING_PERIOD_SUCCESS,
  GET_ACCOUNTING_PERIOD_FAILURE,
  GET_ACCOUNTING_PERIOD_LOADING,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const getAccountingPeriodSuccess = (payload) => {
  return {
    type: GET_ACCOUNTING_PERIOD_SUCCESS,
    payload,
    accountingPeriodLoading: false,
  };
};

export const getAccountingPeriodFailure = (error) => {
  return {
    type: GET_ACCOUNTING_PERIOD_FAILURE,
    error,
    accountingPeriodLoading: false,
  };
};

export const getAccountingPeriod = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ACCOUNTING_PERIOD_LOADING });
    const { data } = await axios.get(`/accounting/periods`);
    const currentDate = new Date();
    const period = data.data.find(
      (item) =>
        new Date(item.period_start) <= currentDate &&
        new Date(item.period_end) > currentDate,
    );

    dispatch(getAccountingPeriodSuccess(period));

    return period;
  } catch (error) {
    dispatch(getAccountingPeriodFailure({ error }));

    return errorHandler(error);
  }
};

export const initialState = {
  accountingPeriodData: null,
  accountingPeriodLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNTING_PERIOD_LOADING:
      return {
        ...state,
        accountingPeriodLoading: true,
      };

    case GET_ACCOUNTING_PERIOD_SUCCESS:
      return {
        ...state,
        accountingPeriodData: action.payload,
        accountingPeriodLoading: false,
      };
    case GET_ACCOUNTING_PERIOD_FAILURE:
      return {
        ...state,
        error: action.error,
        accountingPeriodLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
