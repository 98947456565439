import { errorHandler } from '../../../utils';
import axios from '../../../utils/http';
import {
  GET_SUCCESS,
  PATCH_SUCCESS,
  POST_SUCCESS,
  DELETE_SUCCESS,
} from './actionTypes';

export const getSignatures = (payload) => ({
  type: GET_SUCCESS,
  payload,
});

export const editSignature = (payload) => ({
  type: PATCH_SUCCESS,
  payload,
});

export const createSignature = (payload) => ({
  type: POST_SUCCESS,
  payload,
});

export const deleteSignature = (payload) => ({
  type: DELETE_SUCCESS,
  payload,
});

/**
 * Create a thunk which makes an API call to get signatures
 *
 *
 * @returns {Function}
 */
export const getSignaturesAction = () => async (dispatch) => {
  try {
    const { data } = await axios.get('/broker/signatories');
    dispatch(getSignatures(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to create a signature
 *
 *
 * @returns {Function}
 */
export const createSignatureAction = (payload) => async (dispatch) => {
  try {
    const { data } = await axios.post('/broker/signatories', payload);
    dispatch(createSignature(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to edit a signature
 *
 *
 * @returns {Function}
 */
export const editSignatureAction = (id, payload) => async (dispatch) => {
  try {
    const { data } = await axios.patch(`/broker/signatories/${id}`, payload);
    dispatch(editSignature(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to delete a signature
 *
 *
 * @returns {Function}
 */
export const deleteSignatureAction = (id) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`/broker/signatories/${id}`);
    dispatch(deleteSignature(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const initialState = {
  data: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SUCCESS:
    case PATCH_SUCCESS:
    case DELETE_SUCCESS:
      return {
        data: payload,
      };
    case POST_SUCCESS:
      return {
        data: [...state.data, payload],
      };
    default:
      return state;
  }
};

export default reducer;
