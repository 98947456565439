import { createTheme } from '@mui/material/styles';

// Colour Profiles
const AuxBlue = {
  light: '#8ACDE5',
  main: '#227C9D',
  contrastText: '#FFFFFF',
  tableHead: '#457EAC',
};
const AuxYellow = {
  main: '#F3B700',
  dark: '#D4A106',
  contrastText: '#F5F6FA',
};
const AuxRed = {
  light: '#F0AB9A',
  main: '#FE7D7D',
  contrastText: '#FFFFFF',
};
const AuxWhite = {
  light: '#fff',
  main: '#FFFFFF', //rep the main BG
  dark: '#fcfbfb',
  contrastText: '#515151',
};
const AuxLight = {
  main: '#F5F6FA',
  contrastText: '#3F316B',
};
const AuxGrey = {
  main: '#E9E9E9',
  dark: '#333333',
  contrastText: '#292F36',
};

// Create a theme instance.
const AuxTheme = createTheme({
  palette: {
    primary: AuxBlue,
    secondary: AuxWhite,
    error: AuxRed,
    warning: AuxYellow,
    text: {
      primary: AuxGrey.dark,
      secondary: AuxBlue.main,
      disabled: AuxGrey.main,
    },
    background: {
      paper: AuxWhite.main,
      default: AuxLight.main,
      main: AuxWhite.main,
    },
    type: 'light',
  },
  shape: {
    borderRadius: 5,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    },
  },
  typography: {
    fontFamily: 'Segoe Pro Display, sans-serif',
    h1: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 700,
      fontSize: '7.5rem',
    },
    h2: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 700,
      fontSize: '5rem',
    },
    h3: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 600,
      fontSize: '3rem',
    },
    h4: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 500,
      fontSize: '1.875rem',
    },
    h5: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    subtitle1: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 500,
      fontSize: `1.5rem`,
    },
    subtitle2: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 400,
      fontSize: `2rem`,
    },
    body1: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 400,
      fontSize: `1rem`,
    },
    body2: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 500,
      fontSize: `1rem`,
    },
    caption: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 400,
      fontSize: `0.75rem`,
    },
    button: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 500,
      fontSize: `1rem`,
    },
    h6: {
      fontFamily: 'Segoe Pro Display, sans-serif',
      fontWeight: 550,
      fontSize: `1.2rem`,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 30px white inset !important',
        },
        '&:-webkit-autofill:hover': {
          '-webkit-box-shadow': '0 0 0 30px white inset !important',
        },
        '&:-webkit-autofill:focus': {
          '-webkit-box-shadow': '0 0 0 30px white inset !important',
        },
        '&:-webkit-autofill:active': {
          '-webkit-box-shadow': '0 0 0 30px white inset !important',
        },
      },
      root: {
        '& $notchedOutline': {
          borderWidth: '1.5px',
          borderRadius: '5px',
        },
        '&$focused $notchedOutline': {
          borderWidth: '1.5px',
        },
        '&:hover $notchedOutline': {
          borderColor: AuxBlue.main,
        },
      },
    },
  },
});

AuxTheme.overrides.MuiChip = {
  root: {
    cursor: 'pointer',
  },
  outlined: {
    borderWidth: '1.5px',
  },
};
export { AuxTheme };
