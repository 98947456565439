export const REQUEST_ACTION = 'ibms-web/underwriters/REQUEST_ACTION';
export const GET_UNDERWRITERS_SUCCESS =
  'ibms-web/underwriters/GET_UNDERWRITERS_SUCCESS';
export const GET_UNDERWRITERS_FAILURE =
  'ibms-web/underwriters/GET_UNDERWRITERS_FAILURE';
export const ADD_UNDERWRITERS_SUCCESS =
  'ibms-web/underwriters/ADD_UNDERWRITERS_SUCCESS';
export const ADD_UNDERWRITERS_FAILURE =
  'ibms-web/underwriters/ADD_UNDERWRITERS_FAILURE';
export const EDIT_UNDERWRITERS_SUCCESS =
  'ibms-web/underwriters/EDIT_UNDERWRITERS_SUCCESS';
export const EDIT_UNDERWRITERS_FAILURE =
  'ibms-web/underwriters/EDIT_UNDERWRITERS_FAILURE';
