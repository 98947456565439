import { REQUEST_ACTION, GET_CO_BROKERS } from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const getCoBrokers = (coBrokers) => ({
  type: GET_CO_BROKERS,
  coBrokers,
});

export const getBrokerAction = () => async (dispatch) => {
  dispatch(requestAction());
  try {
    const response = await axios.get('/cobrokers');
    const { data } = response;
    dispatch(getCoBrokers(data.data));

    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const searchCoBrokerAction = (nameOfCoBroker) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('/quote/coBroker/search', {
      searchQuery: nameOfCoBroker,
    });

    return { success: true, data };
  } catch (error) {
    errorHandler(error);
  }
};

export const searchCoversAction = (coverName) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('product/covers/search', {
      searchQuery: coverName,
    });

    return { success: true, data };
  } catch (error) {
    errorHandler(error);
  }
};

export const initialState = {
  coBrokersData: [],
  isLoading: false,
};

/**
 * This reducer changes the subscription state of the application
 *
 * @param {SubscriptionState} state
 * @param {Action} action
 *
 * @returns {SubscriptionState} state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CO_BROKERS:
      return {
        ...state,
        coBrokersData: action.coBrokers,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
