import { errorHandler } from '../../../utils';
import axios from '../../../utils/http';
import {
  REQUEST_ACTION,
  SAVING_REQUEST_ACTION,
  TEST_CONNECTION_SUCCESS,
  TEST_CONNECTION_FAILURE,
  SAVE_CONNECTION_SUCCESS,
  EDIT_CONNECTION_SUCCESS,
  GET_CONNECTION_SUCCESS,
} from './actionTypes';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const savingRequestAction = () => ({
  type: SAVING_REQUEST_ACTION,
});

export const getConnectionSetting = (payload) => ({
  type: GET_CONNECTION_SUCCESS,
  payload,
});

export const testConnection = (payload) => ({
  type: TEST_CONNECTION_SUCCESS,
  payload,
});

export const testConnectionFailure = (error) => ({
  type: TEST_CONNECTION_SUCCESS,
  payload: error,
});

export const saveConnection = (payload) => ({
  type: SAVE_CONNECTION_SUCCESS,
  payload,
});

export const editConnection = (payload) => ({
  type: EDIT_CONNECTION_SUCCESS,
  payload,
});

/**
 * Create a thunk which makes an API call to get connection settings
 *
 *
 * @returns {Function}
 */
export const getConnectionSettingsAction = () => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get('/mailer/connection-settings');
    dispatch(getConnectionSetting(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to test connection settings
 *
 *
 * @returns {Function}
 */
export const testConnectionSettingsAction = (payload) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.post('/mailer/test-connection', payload);
    dispatch(testConnection(data));

    return data;
  } catch (error) {
    dispatch(testConnectionFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to save connection settings
 *
 *
 * @returns {Function}
 */
export const saveConnectionSettingsAction = (payload) => async (dispatch) => {
  dispatch(savingRequestAction());

  try {
    const { data } = await axios.post('/mailer/save-connection', payload);
    dispatch(saveConnection(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to modify connection settings
 *
 *
 * @returns {Function}
 */
export const editConnectionSettingsAction = (payload) => async (dispatch) => {
  dispatch(savingRequestAction());

  try {
    const { data } = await axios.put('/mailer/modify-connection', payload);
    dispatch(editConnection(data.data));

    return data;
  } catch (error) {
    errorHandler(error);
  }
};

export const initialState = {
  data: {},
  error: {},
  isLoading: false,
  isSaving: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case SAVING_REQUEST_ACTION:
      return {
        ...state,
        isSaving: true,
      };
    case GET_CONNECTION_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case TEST_CONNECTION_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case TEST_CONNECTION_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case SAVE_CONNECTION_SUCCESS:
      return {
        ...state,
        data: payload,
        isSaving: false,
      };
    case EDIT_CONNECTION_SUCCESS:
      return {
        ...state,
        data: payload,
        isSaving: false,
      };
    default:
      return state;
  }
};

export default reducer;
