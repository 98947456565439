import {
  REQUEST_ACTION,
  GET_SUMMARY_OF_ACCOUNTS_FAILURE,
  GET_SUMMARY_OF_ACCOUNTS_SUCCESS,
} from './actionTypes';

import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
  isLoading: true,
});

export const getSummaryOfAccountSuccess = (data) => ({
  type: GET_SUMMARY_OF_ACCOUNTS_SUCCESS,
  data,
  isLoading: false,
});

export const getSummaryOfAccountFailure = (error) => ({
  type: GET_SUMMARY_OF_ACCOUNTS_FAILURE,
  error,
  isLoading: false,
});

export const getSummaryOfAccountAction = (startDate, endDate, search) => async (
  dispatch,
) => {
  dispatch(requestAction());
  let data;

  try {
    if (startDate && endDate && search) {
      const res = await axios.get(
        `/accounting/summary?startDate=${startDate}&endDate=${endDate}&search=${search}`,
      );
      data = res.data;
    } else if (startDate && endDate) {
      const res = await axios.get(
        `/accounting/summary?startDate=${startDate}&endDate=${endDate}`,
      );
      data = res.data;
    } else if (search) {
      const res = await axios.get(`/accounting/summary?search=${search}`);
      data = res.data;
    } else {
      const res = await axios.get(`/accounting/summary`);
      data = res.data;
    }
    dispatch(getSummaryOfAccountSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getSummaryOfAccountFailure(error));

    return errorHandler(error);
  }
};

export const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SUMMARY_OF_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case GET_SUMMARY_OF_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
