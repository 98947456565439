import React from 'react';
import closeBtnIcon from '../../assets/icons/x.svg';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchBox = (props) => {
  const {
    searchItem,
    clearSearch,
    handleChange,
    autoFocus,
    placeholder,
    inputStyle,
    inputContainerStyle
  } = props;
  const renderIcon = () =>
    searchItem ? (
      <img
        src={closeBtnIcon}
        className="p-2 m-auto cursor-pointer"
        alt="cancel"
        onClick={clearSearch}
      />
    ) : (
      <FontAwesomeIcon
        icon={faSearch}
        size="1x"
        className="absolute top-1/2 label bg-white right-0.4 sm:right-1 z-10 cursor-pointer"
        color="#e0e0e0"
      />
    );

  return (
    <div className='relative flex w-auto h-12 pl-1 pr-8 text-gray-700 border-2 border-solid rounded justify-evenly sm:pr-10 sm:pl-3 overflow-ellipsis border-gray-350 focus:border-blue-750 focus:border-2 focus:border-solid' style={inputContainerStyle}>
      <input
        type="text"
        placeholder={placeholder}
        value={searchItem}
        onChange={handleChange}
        autoFocus={autoFocus}
        className={`w-full overflow-hidden text-xs focus:outline-none sel ${inputStyle}`}
      />
      {renderIcon()}
    </div>
  );
};

export default SearchBox;
