/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {useHistory} from 'react-router-dom'
import Modal from '../Modal';
import Button from '../Button';
import { connect } from 'react-redux';
import { showLogoutModal } from '../../utils/auth';

const Logout = (props) => {
    const { children, showModal} = props;
    const history = useHistory();

    const content = () => {
        return <div>
            <div className="flex">
                <svg className="mr-3" width="40" height="39" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.7262 3.40575C11.4735 1.97091 13.5265 1.97091 14.2738 3.40575L23.4772 21.0761C24.1706 22.4076 23.2046 24 21.7033 24H3.29668C1.79544 24 0.829374 22.4076 1.52285 21.0761L10.7262 3.40575Z" fill="#F4ACAC"/>
                    <path d="M10.9911 10.1486C10.9624 9.85465 11.1934 9.60001 11.4887 9.60001H13.5113C13.8066 9.60001 14.0376 9.85465 14.0089 10.1486L13.3253 17.1486C13.3003 17.4047 13.085 17.6 12.8277 17.6H12.1723C11.915 17.6 11.6997 17.4047 11.6747 17.1486L10.9911 10.1486Z" fill="#D75858"/>
                    <rect x="11.7188" y="19.2" width="1.5625" height="1.6" rx="0.5" fill="#D75858"/>
                </svg>
                <p className="sm:w-64.3">Your session has expired, click on the button below to sign in.</p>
            </div>
            <Button
                className='bg-blue-850 block mx-auto mt-5 hover:bg-blue-730 text-white w-30 font-bold'
                type="submit"
                name="Go to sign in"
                onClick={() => {
                    showLogoutModal(false)
                    history.push('/login')
                }}
            />
        </div>
    }

    return <>
        {children}
        <Modal
            showModal={showModal}
            renderContent={content}
        />
    </>
}

const mapStateToProps = (state) => ({
    showModal: state.auth.logout
})

export default connect(mapStateToProps)(Logout);