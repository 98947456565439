export const REQUEST_ACTION = 'ibms-web/clients/REQUEST_ACTION';
export const UPDATE_REQUEST_ACTION = 'ibms-web/clients/UPDATE_REQUEST_ACTION';
export const GET_INDIVIDUAL_CLIENT_SUCCESS =
  'ibms-web/clients/GET_INDIVIDUAL_CLIENT_SUCCESS';
export const GET_INDIVIDUAL_CLIENT_FAILURE =
  'ibms-web/clients/GET_INDIVIDUAL_CLIENT_FAILURE';
export const GET_CORPORATE_CLIENT_SUCCESS =
  'ibms-web/clients/GET_CORPORATE_CLIENT_SUCCESS';
export const GET_CORPORATE_CLIENT_FAILURE =
  'ibms-web/clients/GET_CORPORATE_CLIENT_FAILURE';
export const GET_SINGLE_CORPORATE_CLIENT_SUCCESS =
  'ibms-web/clients/GET_SINGLE_CORPORATE_CLIENT_SUCCESS';
export const GET_SINGLE_CORPORATE_CLIENT_FAILURE =
  'ibms-web/clients/GET_SINGLE_CORPORATE_CLIENT_FAILURE';
export const GET_SINGLE_INDIVIDUAL_CLIENT_SUCCESS =
  'ibms-web/clients/GET_SINGLE_INDIVIDUAL_CLIENT_SUCCESS';
export const GET_SINGLE_INDIVIDUAL_CLIENT_FAILURE =
  'ibms-web/clients/GET_SINGLE_INDIVIDUAL_CLIENT_FAILURE';
export const CREATE_INDIVIDUAL_CLIENT_SUCCESS =
  'ibms-web/clients/CREATE_INDIVIDUAL_CLIENT_SUCCESS';
export const CREATE_INDIVIDUAL_CLIENT_FAILURE =
  'ibms-web/clients/CREATE_INDIVIDUAL_CLIENT_FAILURE';
export const CREATE_CORPORATE_CLIENT_SUCCESS =
  'ibms-web/clients/CREATE_CORPORATE_CLIENT_SUCCESS';
export const CREATE_CORPORATE_CLIENT_FAILURE =
  'ibms-web/clients/CREATE_CORPORATE_CLIENT_FAILURE';
export const ADD_CORPORATE_DIRECTOR_SUCCESS =
  'ibms-web/clients/ADD_CORPORATE_DIRECTOR_SUCCESS';
export const ADD_CORPORATE_DIRECTOR_FAILURE =
  'ibms-web/clients/ADD_CORPORATE_DIRECTOR_FAILURE';
export const UPDATE_INDIVIDUAL_CLIENT_SUCCESS =
  'ibms-web/clients/UPDATE_INDIVIDUAL_CLIENT_SUCCESS';
export const UPDATE_INDIVIDUAL_CLIENT_FAILURE =
  'ibms-web/clients/UPDATE_INDIVIDUAL_CLIENT_FAILURE';
export const UPDATE_CORPORATE_CLIENT_SUCCESS =
  'ibms-web/clients/UPDATE_CORPORATE_CLIENT_SUCCESS';
export const UPDATE_CORPORATE_CLIENT_FAILURE =
  'ibms-web/clients/UPDATE_CORPORATE_CLIENT_FAILURE';
export const GET_ALL_CLIENTS_SUCCESS =
  'ibms-web/clients/GET_ALL_CLIENTS_SUCCESS';
export const GET_ALL_CLIENTS_FAILURE =
  'ibms-web/clients/GET_ALL_CLIENTS_FAILURE';
export const UPDATE_STATUS_LIST_SUCCESS =
  'ibms-web/clients/UPDATE_STATUS_LIST_SUCCESS';
export const UPDATE_STATUS_SUCCESS =
  'ibms-web/clients/UPDATE_STATUS_LIST_SUCCESS';
