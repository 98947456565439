import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import AvatarDropDown from '../AvatarDropDown';
import profileIcon from '../../assets/icons/profile-icon.png';
import NotificationDropDown from '../NotificationDropDown';

const UserAvatar = ({
  imgUrl,
  userName,
  userEmail,
  toggleNotification,
  toggleDropDownMenu,
  dropDownHidden,
  notificationHidden,
  profile,
  logOut,
  notificationData,
  notificationCount,
  setNotificationState,
  removeNotification,
  permission,
}) => {
  return (
    <div className="flex items-center">
      {// close dropdown by clicking on the page
      !dropDownHidden && (
        <div
          style={{ position: 'fixed', top: 0, bottom: 0, right: 0, left: 0 }}
          onClick={toggleDropDownMenu}
        ></div>
      )}
      {// close notification dropdown by clicking on the page
      !notificationHidden && (
        <div
          style={{ position: 'fixed', top: 0, bottom: 0, right: 0, left: 0 }}
          onClick={toggleNotification}
        ></div>
      )}
      <div
        className="relative flex items-center justify-center w-8 h-8 rounded-full cursor-pointer hover:bg-blue-300"
        onClick={toggleNotification}
      >
        {notificationCount > 0 && (
          <div className="absolute flex items-center justify-center w-4 h-4 text-xs font-bold text-center text-white bg-red-500 rounded-full  top-0.125 left-1">
            {notificationCount}
          </div>
        )}
        <FontAwesomeIcon icon={faBell} size="lg" color="#4E74A6" />
      </div>

      <img
        src={imgUrl ? imgUrl : profileIcon}
        className="w-auto h-8 ml-5 rounded-full cursor-pointer"
        alt="userAvatar"
        onClick={toggleDropDownMenu}
      />
      <>
        {!dropDownHidden && (
          <AvatarDropDown
            profile={profile}
            userName={userName}
            userEmail={userEmail}
            logOut={logOut}
            imgUrl={imgUrl}
          />
        )}
      </>
      <>
        {!notificationHidden && (
          <NotificationDropDown
            notificationData={notificationData}
            notificationCount={notificationCount}
            setNotificationState={setNotificationState}
            removeNotification={removeNotification}
            permission={permission}
          />
        )}
      </>
    </div>
  );
};

export default UserAvatar;
