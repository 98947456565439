import {
  REQUEST_ACTION,
  GET_CORPORATE_CLIENT_SUCCESS,
  GET_CORPORATE_CLIENT_FAILURE,
  GET_INDIVIDUAL_CLIENT_SUCCESS,
  GET_INDIVIDUAL_CLIENT_FAILURE,
  GET_SINGLE_CORPORATE_CLIENT_SUCCESS,
  GET_SINGLE_CORPORATE_CLIENT_FAILURE,
  GET_SINGLE_INDIVIDUAL_CLIENT_SUCCESS,
  GET_SINGLE_INDIVIDUAL_CLIENT_FAILURE,
  CREATE_INDIVIDUAL_CLIENT_SUCCESS,
  CREATE_INDIVIDUAL_CLIENT_FAILURE,
  CREATE_CORPORATE_CLIENT_SUCCESS,
  CREATE_CORPORATE_CLIENT_FAILURE,
  ADD_CORPORATE_DIRECTOR_SUCCESS,
  ADD_CORPORATE_DIRECTOR_FAILURE,
  UPDATE_INDIVIDUAL_CLIENT_SUCCESS,
  UPDATE_INDIVIDUAL_CLIENT_FAILURE,
  UPDATE_CORPORATE_CLIENT_SUCCESS,
  UPDATE_CORPORATE_CLIENT_FAILURE,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  UPDATE_REQUEST_ACTION,
  UPDATE_STATUS_LIST_SUCCESS,
  UPDATE_STATUS_SUCCESS,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const updateRequestAction = () => ({
  type: UPDATE_REQUEST_ACTION,
});

export const individualClients = (individualClient, meta) => ({
  type: GET_INDIVIDUAL_CLIENT_SUCCESS,
  individualClient,
  meta,
});

export const individualClientsFailure = (error) => ({
  type: GET_INDIVIDUAL_CLIENT_FAILURE,
  payload: error,
});

export const corporateClients = (corporateClient, meta) => ({
  type: GET_CORPORATE_CLIENT_SUCCESS,
  corporateClient,
  meta,
});

export const corporateClientsFailure = (error) => ({
  type: GET_CORPORATE_CLIENT_FAILURE,
  payload: error,
});

export const singleIndividualClient = (payload) => ({
  type: GET_SINGLE_INDIVIDUAL_CLIENT_SUCCESS,
  payload,
});

export const singleCorporateClient = (payload) => ({
  type: GET_SINGLE_CORPORATE_CLIENT_SUCCESS,
  payload,
});

export const singleIndividualClientFailure = (payload) => ({
  type: GET_SINGLE_INDIVIDUAL_CLIENT_FAILURE,
  payload,
});

export const singleCorporateClientFailure = (payload) => ({
  type: GET_SINGLE_CORPORATE_CLIENT_FAILURE,
  payload,
});

export const createIndividualClient = (payload) => ({
  type: CREATE_INDIVIDUAL_CLIENT_SUCCESS,
  payload,
});

export const createIndividualClientFailure = (error) => ({
  type: CREATE_INDIVIDUAL_CLIENT_FAILURE,
  payload: error,
});

export const createCorporateClient = (payload) => ({
  type: CREATE_CORPORATE_CLIENT_SUCCESS,
  payload,
});

export const createCorporateClientFailure = (error) => ({
  type: CREATE_CORPORATE_CLIENT_FAILURE,
  payload: error,
});

export const addCorporateDirector = (payload) => ({
  type: ADD_CORPORATE_DIRECTOR_SUCCESS,
  payload,
});

export const addCorporateDirectorFailure = (error) => ({
  type: ADD_CORPORATE_DIRECTOR_FAILURE,
  payload: error,
});

export const updateIndividualClient = (payload) => ({
  type: UPDATE_INDIVIDUAL_CLIENT_SUCCESS,
  payload,
});

export const updateIndividualClientFailure = (error) => ({
  type: UPDATE_INDIVIDUAL_CLIENT_FAILURE,
  payload: error,
});

export const updateCorporateClient = (payload) => ({
  type: UPDATE_CORPORATE_CLIENT_SUCCESS,
  payload,
});

export const updateCorporateClientFailure = (error) => ({
  type: UPDATE_CORPORATE_CLIENT_FAILURE,
  payload: error,
});

export const allClientsSuccess = (payload, meta) => ({
  type: GET_ALL_CLIENTS_SUCCESS,
  payload,
  meta,
});

export const updateStatusListSuccess = (payload) => {
  return {
    type: UPDATE_STATUS_LIST_SUCCESS,
    payload,
  };
};

export const updateStatusSuccess = (payload) => {
  return {
    type: UPDATE_STATUS_SUCCESS,
    payload,
  };
};

export const allClientsFailure = (error) => ({
  type: GET_ALL_CLIENTS_FAILURE,
  payload: error,
});

/**
 * Create a thunk which makes an API call to get individual clients
 *
 * @returns {Funtion}
 */
export const individualClientsAction = (limit, page, status) => async (
  dispatch,
) => {
  dispatch(requestAction());
  limit = limit ? `limit=${limit}` : '';
  page = page ? `&page=${page}` : '';
  status = status ? `&status=${status}` : '';

  try {
    const response = await axios.get(`/client?${limit}${page}${status}`);
    const { data, meta } = response.data;
    dispatch(individualClients(data, meta));

    return response.data;
  } catch (error) {
    dispatch(individualClientsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get corporate clients
 *
 * @returns {Function}
 */
export const corporateClientsAction = (limit, page, status) => async (
  dispatch,
) => {
  dispatch(requestAction());
  limit = limit ? `limit=${limit}` : '';
  page = page ? `&page=${page}` : '';
  status = status ? `&status=${status}` : '';

  try {
    const response = await axios.get(
      `/client/corporate?${limit}${page}${status}`,
    );
    const { data, meta } = response.data;
    dispatch(corporateClients(data, meta));

    return response.data;
  } catch (error) {
    dispatch(corporateClientsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get single individual client
 *
 * @param {string} identifier - email or clientId
 *
 * @returns {Function}
 */
export const singleIndividualClientAction = (identifier) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/client/${identifier}`);
    dispatch(singleIndividualClient(data));

    return data;
  } catch (error) {
    dispatch(singleIndividualClientFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get single corporate client
 *
 * @param {string} identifier - email or clientId
 *
 * @returns {Function}
 */
export const singleCorporateClientAction = (identifier) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/client/corporate/${identifier}`);
    dispatch(singleCorporateClient(data));

    return data;
  } catch (error) {
    dispatch(singleCorporateClientFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to create individual clients
 *
 * @returns {Funtion}
 */
export const createIndividualClientAction = (payload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const response = await axios.post('/client', payload);
    dispatch(createIndividualClient(response.data));

    return response.data;
  } catch (error) {
    dispatch(createIndividualClientFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to create corporate clients
 *
 * @returns {Funtion}
 */
export const createCorporateClientAction = (payload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const response = await axios.post('/client/corporate', payload);
    dispatch(createCorporateClient(response.data));

    return response.data;
  } catch (error) {
    dispatch(createCorporateClientFailure(error?.response?.data));
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to add directors information for corporate clients
 *
 * @returns {Funtion}
 */
export const addCorporateDirectorAction = (clientId, payload) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    const response = await axios.post(
      `client/corporate/${clientId}/director`,
      payload,
    );

    dispatch(addCorporateDirector(response.data));
    return response.data;
  } catch (error) {
    dispatch(addCorporateDirectorFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to update individual clients
 *
 * @returns {Funtion}
 */
export const updateIndividualClientAction = (clientId, payload) => async (
  dispatch,
) => {
  dispatch(updateRequestAction());
  try {
    const response = await axios.put(`/client/${clientId}`, payload);
    dispatch(updateIndividualClient(response.data));

    return response.data;
  } catch (error) {
    dispatch(updateIndividualClientFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to update corporate clients
 *
 * @returns {Funtion}
 */
export const updateCorporateClientAction = (clientId, payload) => async (
  dispatch,
) => {
  dispatch(updateRequestAction());
  try {
    const response = await axios.put(`/client/corporate/${clientId}`, payload);
    dispatch(updateCorporateClient(response.data));

    return response.data;
  } catch (error) {
    dispatch(updateCorporateClientFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get all clients
 *
 * @returns {Function}
 */
export const allClientsAction = (limit, page, status) => async (dispatch) => {
  dispatch(requestAction());
  limit = limit ? `limit=${limit}` : '';
  page = page ? `&page=${page}` : '';
  status = status ? `&status=${status}` : '';

  try {
    const response = await axios.get(
      `/client/allClients?${limit}${page}${status}`,
    );
    const { data, meta } = response.data;
    dispatch(allClientsSuccess(data, meta));

    return response.data;
  } catch (error) {
    dispatch(allClientsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get all clients
 *
 * @returns {Function}
 */
export const updateClientStatusAction = (
  clientType,
  clientId,
  status,
  list,
  all,
) => async (dispatch) => {
  status = status ? `?status=${status}` : '';

  try {
    const response = await axios.patch(
      `/client/${clientType}/${clientId}/status${status}`,
    );
    dispatch(updateStatusListSuccess({ ...response.data, all, list }));

    return response.data;
  } catch (error) {
    return errorHandler(error);
  }
};

const updateStatus = (state, action) => {
  const { payload } = action;

  if (payload.data.client_id) {
    return {
      ...state,
      singleClient: {
        ...state.singleClient,
        individual: payload,
      },
    };
  }

  return {
    ...state,
    singleClient: {
      ...state.singleClient,
      corporate: action.data,
    },
  };
};

const updateStatusList = (state, action) => {
  let { payload } = action;
  if (!payload.list) {
    return updateStatus(state, action);
  }

  payload = payload.data.client;

  if (payload.client_id && !payload.all) {
    const data =
      state.individualAgentClient.data.result ||
      state.individualAgentClient.data ||
      [];
    const client = data.findIndex(
      (client) => client.client_id === payload.client_id,
    );
    const newList = [...data];

    newList[client] = payload;

    return {
      ...state,
      individualAgentClient: {
        ...state.individualAgentClient,
        data: newList,
      },
    };
  } else if (payload.corporate_client_id && !payload.all) {
    const client = state.corporateAgentClient.data.findIndex(
      (client) => client.corporate_client_id === payload.corporate_client_id,
    );
    const newList = [...state.corporateAgentClient.data];

    newList[client] = payload;

    return {
      ...state,
      corporateAgentClient: {
        ...state.corporateAgentClient,
        data: newList,
      },
    };
  }
  const client = state.data.result.findIndex((client) =>
    client.corporate_client_id
      ? client.corporate_client_id === payload.corporate_client_id
      : client.client_id === payload.client_id,
  );
  const newList = [...state.data.result];

  newList[client] = {
    ...payload,
    name: payload.corporate_client_id
      ? payload.corporate_name
      : `${payload.firstname} ${payload.lastname}`,
  };

  return {
    ...state,
    data: newList,
  };
};

export const initialState = {
  data: [],
  meta: {},
  individualAgentClient: {
    data: [],
  },
  corporateAgentClient: {
    data: [],
  },
  singleClient: {
    individual: {},
    corporate: {},
    updatedIndividual: {},
    updatedCorporate: {},
    error: {},
  },
  isUpdating: false,
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_REQUEST_ACTION:
      return {
        ...state,
        isUpdating: true,
      };
    case GET_CORPORATE_CLIENT_SUCCESS:
      return {
        ...state,
        corporateAgentClient: {
          ...state.corporateAgentClient,
          data: action.corporateClient,
        },
        meta: action.meta,
        isLoading: false,
      };
    case GET_CORPORATE_CLIENT_FAILURE:
      return {
        ...state,
        corporateAgentClient: {
          ...state.corporateAgentClient,
          data: action.payload,
        },
        isLoading: false,
      };
    case GET_INDIVIDUAL_CLIENT_SUCCESS:
      return {
        ...state,
        individualAgentClient: {
          ...state.individualAgentClient,
          data: action.individualClient,
        },
        meta: action.meta,
        isLoading: false,
      };
    case GET_INDIVIDUAL_CLIENT_FAILURE:
      return {
        ...state,
        individualAgentClient: {
          ...state.individualAgentClient,
          data: action.payload,
        },
        isLoading: false,
      };
    case GET_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_ALL_CLIENTS_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_SINGLE_INDIVIDUAL_CLIENT_SUCCESS:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          individual: action.payload,
        },
        isLoading: false,
      };
    case GET_SINGLE_INDIVIDUAL_CLIENT_FAILURE:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          error: action.payload,
        },
        isLoading: false,
      };
    case GET_SINGLE_CORPORATE_CLIENT_SUCCESS:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          corporate: action.payload,
        },
        isLoading: false,
      };
    case GET_SINGLE_CORPORATE_CLIENT_FAILURE:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          error: action.payload,
        },
        isLoading: false,
      };
    case CREATE_INDIVIDUAL_CLIENT_SUCCESS:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          individual: action.payload,
        },
        isLoading: false,
      };
    case CREATE_INDIVIDUAL_CLIENT_FAILURE:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          error: action.payload,
        },
        isLoading: false,
      };
    case CREATE_CORPORATE_CLIENT_SUCCESS:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          corporate: action.payload,
        },
        isLoading: false,
      };
    case CREATE_CORPORATE_CLIENT_FAILURE:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          error: action.payload,
        },
        isLoading: false,
      };
    case ADD_CORPORATE_DIRECTOR_SUCCESS:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          corporate: action.payload,
        },
        isLoading: false,
      };
    case ADD_CORPORATE_DIRECTOR_FAILURE:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          error: action.payload,
        },
        isLoading: false,
      };
    case UPDATE_INDIVIDUAL_CLIENT_SUCCESS:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          updatedIndividual: action.payload,
        },
        isUpdating: false,
      };
    case UPDATE_INDIVIDUAL_CLIENT_FAILURE:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          error: action.payload,
        },
        isUpdating: false,
      };
    case UPDATE_CORPORATE_CLIENT_SUCCESS:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          updatedCorporate: action.payload,
        },
        isUpdating: false,
      };
    case UPDATE_CORPORATE_CLIENT_FAILURE:
      return {
        ...state,
        singleClient: {
          ...state.singleClient,
          error: action.payload,
        },
        isUpdating: false,
      };
    case UPDATE_STATUS_SUCCESS:
      return updateStatusList(state, action);
    default:
      return state;
  }
};

export default reducer;
