import React from 'react';
import { HashLoader } from 'react-spinners';

const LazyScreenLoader = (props) => {
  return (
    <div className="h-screen flex justify-center items-center">
      <HashLoader loading size={30} color="#0396A6" />
    </div>
  );
};

export default LazyScreenLoader;
