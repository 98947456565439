const floatStyle = {
  singleValue: (base) => ({
    ...base,
    paddingTop: "15px"
  }),
  valueContainer: (base) => ({
    ...base,
    paddingTop: "15px",
    color: "white",
    width: "100%"
  })
};

export default floatStyle;

