import {
  REQUEST_ACTION,
  GET_INVOICE_PAYMENTS_SUCCESS,
  GET_INVOICE_PAYMENTS_FAILURE,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
  isLoading: true,
});

export const getInvoicePaymentsSuccess = (data) => ({
  type: GET_INVOICE_PAYMENTS_SUCCESS,
  data,
  isLoading: false,
});

export const getInvoicePaymentsFailure = (error) => ({
  type: GET_INVOICE_PAYMENTS_FAILURE,
  error,
  isLoading: false,
});

export const getInvoicePaymentsAction = (invoiceNo) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/invoice/${invoiceNo}/transactions`);
    dispatch(getInvoicePaymentsSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getInvoicePaymentsFailure(error));

    return errorHandler(error);
  }
};

export const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INVOICE_PAYMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case GET_INVOICE_PAYMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
