const formatMoney = (amount, minimumFractionDigits = 2) => {
  if (typeof +amount !== 'number') {
    return '';
  }

  let lastChar = String(amount).slice(-1);

  const formatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits,
  });

  let formatted = formatter.format(amount);

  return lastChar === '.' ? `${formatted}.` : formatted;
};

const formatNumber = (n) => {
  // format number 1000000 to 1,234,567
  return String(n)
    .replace(/[^\d.]/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatNumber2 = (amount) => {
  if (amount.indexOf('.') >= 0) {
    const decimalPosition = amount.indexOf('.');

    let leftSide = amount.substring(0, decimalPosition);
    let rightSide = amount.substring(decimalPosition);

    if (rightSide) {
      // validate right side
      rightSide = rightSide.replace(/\D/g, '');

      return leftSide + '.' + rightSide;
    }

    return leftSide;
  }

  return amount;
};

export const formatMoney2 = (amount) => {
  if (String(amount).indexOf('.') >= 0) {
    const decimalPosition = String(amount).indexOf('.');

    let leftSide = String(amount).substring(0, decimalPosition);
    let rightSide = String(amount).substring(decimalPosition);

    // add commas to left side of number
    leftSide = formatNumber(leftSide);

    // validate right side
    rightSide = rightSide.replace(/\D/g, '');

    // Limit decimal to only 2 digits
    rightSide = rightSide.substring(0, 2);

    return leftSide + '.' + rightSide;
  }

  return formatNumber(amount);
};

export default formatMoney;
