import {
  REQUEST_ACTION,
  FETCH_PAID_INVOICE_SUCCESS,
  FETCH_PAID_INVOICE_FAILURE,
  // GET_REMITTANCE_SUCCESS,
  // GET_REMITTANCE_FAILURE,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
  isLoading: true,
});

export const getPaidInvoices = (payload) => ({
  type: FETCH_PAID_INVOICE_SUCCESS,
  payload,
  isLoading: false,
});

export const getPaidInvoiceFailure = (payload) => ({
  type: FETCH_PAID_INVOICE_FAILURE,
  payload,
  isLoading: false,
});

// export const getRemittanceSuccess = (payload) => ({
//   type: GET_REMITTANCE_SUCCESS,
//   isLoading: false,
// });

// export const getRemittanceFailure = (payload) => ({
//   type: GET_REMITTANCE_FAILURE,
//   isLoading: false,
// });

export const getPaidInvoicesAction = (
  limit,
  page,
  // userId,
  // status,
  startDate,
  endDate,
  underwriterSearch,
) => async (dispatch) => {
  dispatch(requestAction());

  try {
    limit = limit ? `limit=${limit}` : '';
    page = page ? `&page=${page}` : '';
    // status = status ? `&status=${status}` : '';

    // userId = userId ? `&userId=${userId}` : '';
    startDate = startDate ? `&from=${startDate}` : '';
    endDate = endDate ? `&to=${endDate}` : '';
    underwriterSearch = underwriterSearch
      ? `&insuranceProvider=${underwriterSearch}`
      : '';
    const { data } = await axios.get(
      `/paidpremium?${limit}${page}${startDate}${endDate}${underwriterSearch}`,
    );

    dispatch(getPaidInvoices(data.data.invoices));
    return data;
  } catch (error) {
    dispatch(getPaidInvoiceFailure());

    return errorHandler(error);
  }
};

export const getRemittedPremiumAction = (
  limit,
  page,
  userId,
  // status,
  startDate,
  endDate,
  underwriterSearch,
) => async (dispatch) => {
  dispatch(requestAction());

  try {
    limit = limit ? `limit=${limit}` : '';
    page = page ? `&page=${page}` : '';
    // status = status ? `&status=${status}` : '';

    userId = userId ? `&userId=${userId}` : '';
    startDate = startDate ? `&from=${startDate}` : '';
    endDate = endDate ? `&to=${endDate}` : '';
    underwriterSearch = underwriterSearch
      ? `&insuranceProvider=${underwriterSearch}`
      : '';
    const { data } = await axios.get(
      `/all-remitted?${limit}${page}${startDate}${endDate}${underwriterSearch}`,
    );

    dispatch(getPaidInvoices(data.data.invoices));
    console.log('this is the frontend', data);
    return data;
  } catch (error) {
    dispatch(getPaidInvoiceFailure());

    return errorHandler(error);
  }
};

export const initialState = {
  data: {},
  invoices: [],
  // invoiceIsLoaded: false,
  // invoiceLoadingFailure: false,
  accountHeaderStatus: true,
  isLoading: true,
  newInvoiceLoading: false,
  invoiceData: null,
  // invoiceUpdateData: null,
  newInvoiceData: null,
  // addingPaymentToAnInvoice: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PAID_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        isLoading: false,
      };
    case FETCH_PAID_INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    // case GET_REMITTANCE_SUCCESS:
    //   return {
    //     ...state,
    //     invoices: action.payload,
    //     isLoading: false,
    //   };
    // case GET_REMITTANCE_FAILURE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //   };

    default:
      return state;
  }
};

export default reducer;
