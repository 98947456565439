import React from 'react';
import AsyncSelect from 'react-select/async';
import NormalSelect from 'react-select';
import ValueContainer from '../SelectValueContainer';
import styles from '../../utils/selectConfig';


const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    borderWidth: '2px',
    fontSize: '14px',
    borderColor: state.isFocused ? '#03658C' : '#EBEDED',
    boxShadow: state.isFocused && '0px',
    height: '48px',
    '&:hover': {
      borderColor: state.isFocused ? '#03658C' : '#EBEDED',
    },
  }),
};

const SearchSelect = (props) => {
  const {
    value,
    placeholder,
    getOptionLabel,
    getOptionValue,
    handleChange,
    isDisabled,
    search,
    Option,
    options
  } = props;
 

  const customOptions = { ValueContainer };
  
  if (Option) {
    customOptions.Option = Option;
  }

  return (
    <div>
    {  !props.options ? <AsyncSelect
        styles={{ ...colourStyles, ...styles }}
        cacheOptions
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        components={customOptions}
        loadOptions={search}
        onChange={handleChange}
        isDisabled={isDisabled}
        label={placeholder}
        placeholder=""
        noOptionsMessage={() => 'Type to search'}
        value={value}
        isClearable
      /> : <NormalSelect
        styles={{ ...colourStyles, ...styles }}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        components={customOptions}
        onChange={handleChange}
        isDisabled={isDisabled}
        label={placeholder}
        placeholder=""
        noOptionsMessage={() => 'Type to search'}
        value={value}
        isClearable
      />}
    </div>
  );
};

export default SearchSelect;
