import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticate } from '../../utils/auth';

//Component to render if the user is authenticated
const renderComponent = (Component) => (props) => <Component {...props} />;

const AuthenticatedRoute = (props) => {
  const { component: Component, updateError, history, ...rest } = props;

  const getToken = authenticate();
  const passwordChangeRequired = localStorage.getItem('auth_change');
  
  if (passwordChangeRequired  === 'true') {
    return <Redirect to='/change-password' />
  }

  if (!getToken || getToken === 'undefined') {
    localStorage.removeItem('ibmsToken');
    return <Redirect to='/login' />
  }

  return <Route {...rest} render={renderComponent(Component)} />;
};

export default AuthenticatedRoute;
