import { toast } from 'react-toastify';
import {
  REQUEST_ACTION,
  CHARGE_CARD_SUCCESS,
  CHARGE_CARD_FAILURE,
  VALIDATE_CARD_SUCCESS,
  VALIDATE_CARD_FAILURE,
  GET_PAYMENT_PLAN_SUCCESS,
  SHOW_SUBSCRIPTION_MODAL,
  HIDE_SUBSCRIPTION_MODAL,
} from './actionTypes';
import axios from '../../../utils/http';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const chargeUserCard = (payload) => ({
  type: CHARGE_CARD_SUCCESS,
  payload,
});

export const chargeUserCardFailure = (error) => ({
  type: CHARGE_CARD_FAILURE,
  payload: error,
});

export const validateUserCard = (payload) => ({
  type: VALIDATE_CARD_SUCCESS,
  payload,
});

export const validateUserCardFailure = (error) => ({
  type: VALIDATE_CARD_FAILURE,
  payload: error,
});

export const paymentPlan = (payload) => ({
  type: GET_PAYMENT_PLAN_SUCCESS,
  payload,
});

/**
 * Create a thunk which makes an API call to charge users card
 *
 * @param {Object} payload
 *
 * @returns {Funtion}
 */
export const chargeUserCardAction = (payload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('/charge', payload);
    return dispatch(chargeUserCard(data));
  } catch (error) {
    if (!error.response) {
      return dispatch(chargeUserCardFailure({ error: 'network error!' }));
    }
    return dispatch(chargeUserCardFailure(error.response.data));
  }
};

/**
 * Create a thunk which makes an API call to validate users card
 *
 * @param {Object} payload
 *
 * @returns {Function}
 */
export const validateUserCardAction = (payload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('/validate', payload);
    if (data.data.chargecode === '00') {
      toast.success('Payment successful!');
    }
    return dispatch(validateUserCard(data));
  } catch ({ response }) {
    toast.error('An error occur!');
    return dispatch(validateUserCardFailure(response.data));
  }
};

/**
 * Create a thunk which makes an API call to get all payment plan
 *
 * @param {Object} payload
 *
 * @returns {Function}
 */
export const getPaymentPlanAction = (payload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get('/paymentPlan', payload);
    return dispatch(paymentPlan(data.data));
  } catch (error) {
    console.log(error);
  }
};

export const showSubscriptionModal = () => (dispatch) => {
  dispatch({
    type: SHOW_SUBSCRIPTION_MODAL,
    showModal: true,
  });
};

export const hideSubscriptionModal = () => (dispatch) => {
  dispatch({
    type: HIDE_SUBSCRIPTION_MODAL,
    showModal: false,
  });
};

export const initialState = {
  data: {},
  paymentPlanData: [],
  isLoading: false,
  showModal: false,
};

/**
 * This reducer changes the subscription state of the application
 *
 * @param {SubscriptionState} state
 * @param {Action} action
 *
 * @returns {SubscriptionState} state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case CHARGE_CARD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case HIDE_SUBSCRIPTION_MODAL:
      return {
        ...state,
        showModal: false,
      };
    case SHOW_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        showModal: true,
      };
    }
    case CHARGE_CARD_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case VALIDATE_CARD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case VALIDATE_CARD_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_PAYMENT_PLAN_SUCCESS:
      return {
        ...state,
        paymentPlanData: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
