import {
  REQUEST_ACTION,
  GET_INSURANCE_PROVIDER_SUCCESS,
  ADD_INSURANCE_PROVIDER_SUCCESS,
  ADD_INSURANCE_PROVIDER_FAILURE,
  DELETE_INSURANCE_PROVIDER_SUCCESS,
  ADD_PROVIDER_RELATIONSHIP_MANAGER_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_AVATAR_FAILURE,
  UPDATE_USER_AVATAR_SUCCESS,
  AVATAR_REQUEST_ACTION,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_USER_PERMISSIONS_FAILURE,
} from './actionTypes';
import axios from '../../../utils/http';
import { authenticate, decodedToken } from '../../../utils/auth';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const avatarRequestAction = () => ({
  type: AVATAR_REQUEST_ACTION,
});

export const getProvider = (payload) => ({
  type: GET_INSURANCE_PROVIDER_SUCCESS,
  payload,
});

export const addProvider = (payload) => ({
  type: ADD_INSURANCE_PROVIDER_SUCCESS,
  payload,
});

export const addProviderFailure = (error) => ({
  type: ADD_INSURANCE_PROVIDER_FAILURE,
  payload: error,
});

export const removeProvider = (payload) => ({
  type: DELETE_INSURANCE_PROVIDER_SUCCESS,
  payload,
});

export const addManagerEmail = (payload) => ({
  type: ADD_PROVIDER_RELATIONSHIP_MANAGER_SUCCESS,
  payload,
});

export const updateUserDetails = (payload) => ({
  type: UPDATE_USER_DETAILS_SUCCESS,
  payload,
});

export const updateUserDetailsFailure = (payload) => ({
  type: UPDATE_USER_DETAILS_FAILURE,
  payload,
});

export const updateUserAvater = (payload) => ({
  type: UPDATE_USER_AVATAR_SUCCESS,
  payload,
});

export const updateUserAvatarFailure = (payload) => ({
  type: UPDATE_USER_AVATAR_FAILURE,
  payload,
});

export const getUserDetails = (payload) => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload,
});

export const getUserDetailsFailure = () => ({
  type: GET_USER_DETAILS_FAILURE,
});

export const updatePassword = (payload) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload,
});

export const updatePasswordFailure = (payload) => ({
  type: UPDATE_PASSWORD_FAILURE,
  payload,
});

export const getUserPermissionsSuccess = (payload) => ({
  type: GET_USER_PERMISSIONS_SUCCESS,
  payload,
});

export const getUserPermissionsFailure = (payload) => ({
  type: GET_USER_PERMISSIONS_FAILURE,
  payload,
});

/**
 * Create a thunk which makes an API call to get list of insurance providers
 *
 * @returns {Function}
 */
export const getUserProviderAction = () => async (dispatch) => {
  dispatch(requestAction());
  try {
    const response = await axios.get('/user/insurance_provider');
    const { data } = response;
    dispatch(getProvider(data.data));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to add new insurance provider for user
 *
 * @param {integer} providerId
 *
 * @returns {Function}
 */
export const addUserProviderAction = (providerId) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('/user/insurance_provider', providerId);
    dispatch(addProvider(data.data));

    return data;
  } catch (error) {
    dispatch(addProviderFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to remove provider for a user
 *
 * @param {integer} providerId
 *
 * @returns {Function}
 */
export const deleteUserProviderAction = (providerId) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.delete('/user/insurance_provider', {
      data: providerId,
    });
    dispatch(removeProvider(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to add Underwriter Relationship Manager
 *
 * @param {string} email
 * @param {integer} providerId
 *
 * @returns {Function}
 */
export const addUnderwriterRelationshipManager = (email, providerId) => async (
  dispatch,
) => {
  try {
    const { data } = await axios.put(
      '/user/insurance_provider/relationship_manager',
      {
        providerId,
        relationshipManagerEmail: email,
      },
    );

    dispatch(addManagerEmail(data.data.relationship_manager_email));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const updateUserAvatarAction = (payload) => async (dispatch) => {
  dispatch(avatarRequestAction());

  try {
    const { data } = await axios.put('/user/avatar', payload);
    dispatch(updateUserAvater(data.data));

    return data;
  } catch (error) {
    dispatch(updateUserAvatarFailure());

    return errorHandler(error);
  }
};

export const updateUserDetailsAction = (payload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.put('/user/profile', payload);
    dispatch(updateUserDetails(data.data));

    return data;
  } catch (error) {
    dispatch(updateUserDetailsFailure(error.response.data));
    return errorHandler(error);
  }
};

export const getUserDetailsAction = () => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get('/user/profile');
    dispatch(getUserDetails(data.data));

    return data;
  } catch (error) {
    dispatch(getUserDetailsFailure());
    return errorHandler(error);
  }
};

export const updatePasswordAction = (payload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.put('/auth/change-password', payload);
    dispatch(updatePassword(data));

    return data;
  } catch (error) {
    dispatch(updatePasswordFailure(error?.response?.data));
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get user permission
 * @returns {Function}
 */
export const getUserPermissionsAction = () => async (dispatch) => {
  dispatch(requestAction());

  const token = authenticate();
  const userData = token ? decodedToken(token) : null;

  try {
    const { data } = await axios.get(`user/permission/${userData.id}`);
    dispatch(getUserPermissionsSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getUserPermissionsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const initialState = {
  data: {},
  providerData: [],
  userData: {},
  avatar: null,
  avatarLoading: false,
  isLoaded: false,
  isLoading: false,
  userPermission: [],
  relationshipManagerEmail: '',
};

/**
 * This reducer changes the subscription state of the application
 *
 * @param {SubscriptionState} state
 * @param {Action} action
 *
 * @returns {SubscriptionState} state
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case AVATAR_REQUEST_ACTION:
      return {
        ...state,
        avatarLoading: true,
      };
    case GET_INSURANCE_PROVIDER_SUCCESS:
      return {
        ...state,
        providerData: action.payload,
        isLoading: false,
      };
    case ADD_INSURANCE_PROVIDER_SUCCESS:
      return {
        ...state,
        providerData: action.payload,
        isLoading: false,
      };
    case DELETE_INSURANCE_PROVIDER_SUCCESS:
      return {
        ...state,
        providerData: action.payload,
        isLoading: false,
      };
    case ADD_PROVIDER_RELATIONSHIP_MANAGER_SUCCESS:
      return {
        ...state,
        relationshipManagerEmail: action.payload,
      };
    case UPDATE_USER_AVATAR_SUCCESS:
      return {
        ...state,
        avatar: action.payload.avatar_uri,
        avatarLoading: false,
      };
    case UPDATE_USER_AVATAR_FAILURE:
      return {
        ...state,
        avatarLoading: false,
      };
    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        isLoading: false,
      };
    case UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        avatar: action.payload && action.payload.additionalDetails.avatar_uri,
        isLoading: false,
        isLoaded: true,
      };
    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userPermission: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
