import { toast } from 'react-toastify';
import {
  REQUEST_ACTION,
  GET_ROLES_FAILURE,
  GET_ROLES_SUCCESS,
  GET_ROLE_PERMISSIONS_FAILURE,
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLE_USERS_FAILURE,
  GET_ROLE_USERS_SUCCESS,
  ADD_USERS_TO_ROLE_FAILURE,
  ADD_USERS_TO_ROLE_SUCCESS,
} from './actionTypes';
import axios from '../../../utils/http';
import { getErrorMessage } from '../../../utils/auth';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const getRolesSuccess = (payload) => ({
  type: GET_ROLES_SUCCESS,
  payload,
});

export const getRolesFailure = (payload) => ({
  type: GET_ROLES_FAILURE,
  payload,
});

export const getRolePermissionsSuccess = (payload) => ({
  type: GET_ROLE_PERMISSIONS_SUCCESS,
  payload,
});

export const getRolePermissionsFailure = (payload) => ({
  type: GET_ROLE_PERMISSIONS_FAILURE,
  payload,
});

export const getRoleUsersSuccess = (payload) => ({
  type: GET_ROLE_USERS_SUCCESS,
  payload,
});

export const getRoleUsersFailure = (payload) => ({
  type: GET_ROLE_USERS_FAILURE,
  payload,
});

export const addUsersToRoleSuccess = (payload) => ({
  type: ADD_USERS_TO_ROLE_SUCCESS,
  payload,
});

export const addUsersToRoleFailure = (payload) => ({
  type: ADD_USERS_TO_ROLE_FAILURE,
  payload,
});

export const getRolesAction = () => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get('/roles');
    dispatch(getRolesSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getRolesFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getRolePermissionsAction = (roleId) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get(`/roles/${roleId}/permissions`);
    dispatch(getRolePermissionsSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getRolePermissionsFailure(error?.response?.data));
    return errorHandler(error);
  }
};

export const getRoleUsersAction = (roleId) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get(`/roles/${roleId}/users`);
    dispatch(getRoleUsersSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getRoleUsersFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getUsersAction = (search) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/user?search=${search}`);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const addUsersToRoleAction = (roleId, users) => async (dispatch) => {
  const modUsers = users.map((user) => user.user_id);

  try {
    const { data } = await axios.put(`/roles/${roleId}/users`, {
      users: modUsers,
    });
    dispatch(addUsersToRoleSuccess(data));

    return data;
  } catch (error) {
    dispatch(addUsersToRoleFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getRoleAction = (roleId) => async () => {
  try {
    const { data } = await axios.get(`/roles/${roleId}`);
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const initialState = {
  isLoading: false,
  data: [],
  permissions: [],
  users: [],
  update: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_ROLES_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        permissions: action.payload,
      };
    case GET_ROLE_PERMISSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_ROLE_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    case GET_ROLE_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_USERS_TO_ROLE_SUCCESS:
      return {
        ...state,
        update: !state.update,
      };
    default:
      return state;
  }
};

export default reducer;
