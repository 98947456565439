import React from 'react';
import { components } from 'react-select';

const ValueContainer = ({ children, ...props }) => {
  const isOpen = props.selectProps.menuIsOpen;
  const value = props.selectProps.value;
  const label = props.selectProps.label;

  return (
    <div className="relative h-12 flex-1">
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
      <span
        className={
          isOpen || value
            ? 'absolute left-0.62 top-0.2 text-xs font-bold text-gray-450 labelTrans'
            : 'absolute left-0.62 top-0.75 text-gray-450 labelTrans'
        }
      >
        {label}
      </span>
    </div>
  );
};

export default ValueContainer;
