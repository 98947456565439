import React from 'react';
import { useHistory } from 'react-router-dom';
import capitalize from '../../utils/capitalize';
import useErrorHandler from '../hooks/useErrorHandler';

const NotificationDropDown = ({
  notificationData,
  notificationCount,
  setNotificationState,
  removeNotification,
  permission,
}) => {
  const history = useHistory();
  const { handleReqError } = useErrorHandler();

  return (
    <div className="w-56 border absolute flex flex-col top-3.8 right-1 bg-white rounded shadow-pc pb-2">
      <div className="mt-4 text-center">
        <h2 className="text-base text-gray-800">Notifications</h2>
      </div>
      <div className="px-4 py-1">
        <hr />
      </div>
      {notificationCount < 1 ? (
        <p className="px-6 py-2 text-sm">No new notification</p>
      ) : (
        <div className="overflow-auto max-h-10">
          {notificationData.map((data, i) => (
            <div
              className="px-4 py-3 cursor-pointer hover:bg-gray-150"
              onClick={async () => {
                setNotificationState(true);
                history.push(`/quotes/${data.quote_id}`);

                const res = await removeNotification(data.notification_id);

                if (!res?.success) {
                  handleReqError(res);
                }
              }}
              key={i}
            >
              <h4
                className={`cursor-pointer hover:opacity-1 flex items-center text-xs font-semibold ${
                  permission ? 'text-gray-800' : 'text-blue-350'
                }`}
              >
                <span
                  className={`flex items-center w-1 h-1 p-1 mr-2 ${
                    permission ? 'bg-yellow-600' : 'bg-blue-350'
                  }`}
                ></span>
                {`Quote ${permission ? 'Submitted' : 'Approved'}`}
              </h4>
              <p className="ml-4 text-gray-800 text-tiny">
                {permission
                  ? `${capitalize(
                      data.Creator.username,
                    )} created new quote waiting for approval`
                  : `Your ${data.Quote.InsuranceProduct.InsuranceCategory.name} quote has been approved by ${data.Editor.username}`}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationDropDown;
