import {
  GET_COUNTRY_SUCCESS,
  GET_STATE_SUCCESS,
  GET_LGA_SUCCESS,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const countryList = (payload) => ({
  type: GET_COUNTRY_SUCCESS,
  payload,
});

export const stateList = (payload) => ({
  type: GET_STATE_SUCCESS,
  payload,
});

export const lgaList = (payload) => ({
  type: GET_LGA_SUCCESS,
  payload,
});

/**
 * Create a thunk which makes an API call to country lists
 *
 * @returns {Funtion}
 */
export const countryListAction = () => async (dispatch) => {
  try {
    const response = await axios.get('/countries');
    const { data } = response;
    dispatch(countryList(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get state lists
 *
 * @param {integer} - countryId
 *
 * @returns {Function}
 */
export const stateListAction = (countryId) => async (dispatch) => {
  try {
    const response = await axios.get(`/countries/${countryId}/states`);
    const { data } = response;
    dispatch(stateList(data.data.states));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get lga lists
 *
 * @param {integer} - stateId
 *
 * @returns {Function}
 */
export const lgaListAction = (stateId) => async (dispatch) => {
  try {
    const response = await axios.get(`/states/${stateId}/lgas`);
    const { data } = response;
    dispatch(lgaList(data.data.lgas));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const initialState = {
  countryData: [],
  stateData: [],
  lgaData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        countryData: action.payload,
      };
    case GET_STATE_SUCCESS:
      return {
        ...state,
        stateData: action.payload,
      };
    case GET_LGA_SUCCESS:
      return {
        ...state,
        lgaData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
