import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './styles/tailwind.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import store from './store';
import { ThemeProvider } from '@mui/material/styles';
import { AuxTheme } from './styles/theme';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={AuxTheme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
