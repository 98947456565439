import React, { useState } from 'react';
import whiteCaret from '../../assets/icons/whiteCaret.svg';

const Accordion = ({ accordionName, title, renderContent, titleClass }) => {
  const [state, setState] = useState({});
  const toggleAccordion = () => {
    setState({ [accordionName]: !state[accordionName] });
  };

  return (
    <div className="mb-2">
      <div
        onClick={toggleAccordion}
        className={
          !state[accordionName]
            ? `flex justify-between items-center cursor-pointer ${titleClass}`
            : `flex justify-between items-center cursor-pointer ${titleClass} bg-blue-900 rounded-tl-md font-bold`
        }
      >
        <div className="flex items-center">{title}</div>
        <div className="pr-5">
          <img
            src={whiteCaret}
            alt=""
            className={`${
              !state[accordionName]
                ? 'transition-all duration-75 ease-in'
                : 'transition-all duration-75 ease-in transform rotate-180'
            }`}
          />
        </div>
      </div>
      {state[accordionName] ? (
        <div className="flex">
          <div className="mt-2">{renderContent}</div>
        </div>
      ) : null}
    </div>
  );
};

export default Accordion;
