import React from 'react';

/**
 * @description Button component
 *
 * @param {Object} props
 *
 * @returns {JSX}
 */
const Button = (props) => {
  const { className, type, disabled } = props;

  const buttonContent = (props) => {
    // Show spinner when isLoading is true
    if (props.isLoading) {
      return <span className="spinner">{props.name}</span>;
    }
    return props.icon ? (
      <>
        <span className="mr-2">{props.name}</span>
        <span>{props.icon}</span>
      </>
    ) : (
      <span className="font-medium text-xs">{props.name}</span>
    );
  };

  return (
    <button
      data-testid="button"
      type={type}
      disabled={disabled}
      onClick={props.onClick}
      style={props.style}
      className={`text-sm py-2 px-4 rounded-sm focus:outline-none ${
        className || ''
      } ${disabled ? 'cursor-not-allowed' : ''}`}
    >
      {buttonContent(props)}
    </button>
  );
};

export default Button;
