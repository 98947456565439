import { IS_NOT_SUB_ACCOUNT, IS_SUB_ACCOUNT } from './actionTypes';

export const isSubAccountAction = () => (dispatch) => {
  dispatch({ type: IS_SUB_ACCOUNT, isSubAccount: true });
};

export const isNotSubAccountAction = () => (dispatch) => {
  dispatch({ type: IS_NOT_SUB_ACCOUNT, isSubAccount: false });
};

export const initialState = {
  isSubAccount: null,
  toggleReload: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_SUB_ACCOUNT:
      return {
        ...state,
        isSubAccount: true,
        toggleReload: !state.toggleReload,
      };
    case IS_NOT_SUB_ACCOUNT:
      return {
        ...state,
        isSubAccount: false,
        toggleReload: !state.toggleReload,
      };
    default:
      return state;
  }
};

export default reducer;
