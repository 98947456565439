export const REQUEST_ACTION = 'ibms-web/insurance/REQUEST_ACTION';
export const GET_INSURANCE_CATEGORY_SUCCESS =
  'ibms-web/insurance/GET_INSURANCE_CATEGORY_SUCCESS';
export const GET_INSURANCE_LINE_OF_BUSINESS_SUCCESS =
  'ibms-web/insurance/GET_INSURANCE_LINE_OF_BUSINESS_SUCCESS';
export const GET_INSURANCE_PRODUCT_SUCCESS =
  'ibms-web/insurance/GET_INSURANCE_PRODUCT_SUCCESS';
export const GET_COVERAGE_TYPE_SUCCESS =
  'ibms-web/insurance/GET_COVERAGE_TYPE_SUCCESS';
export const GET_COVERS_SUCCESS = 'ibms-web/insurance/GET_COVERS_SUCCESS';

export const SEARCH_COVERS_SUCCESS = 'ibms-web/insurance/GET_COVERS_SUCCESS';
