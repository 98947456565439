import { combineReducers } from 'redux';
import auth from './modules/auth/index';
import clients from './modules/clients';
import insurance from './modules/insurance';
import insuranceProviders from './modules/insuranceProviders';
import quotes from './modules/quotes';
import subscription from './modules/subscription';
import country from './modules/country';
import user from './modules/user';
import policy from './modules/policy';
import brokerUsers from './modules/brokerUsers';
import roles from './modules/roles';
import groups from './modules/groups';
import permissions from './modules/permissions';
import product from './modules/product';
import vehicleTypes from './modules/vehicleTypes';
import vehicleTypesOptions from './modules/vehicleTypesOptions';
import notify from './modules/notify';
import invoices from './modules/invoice';
import transaction from './modules/transaction';
import premiumToRemit from './modules/remittanceManagement';
import accountTypes from './modules/accountTypes';
import accounts from './modules/accounts';
import isItSubAccount from './modules/isItSubAccount';
import summaryOfAccounts from './modules/summaryOfAccounts';
import invoicePayments from './modules/invoicePayments';
import accountingPeriod from './modules/accountingPeriods';
import underwriters from './modules/underwriters';
import brokerProfileSettings from './modules/BrokerProfileSettings';
import overviewAnalyticsData from './modules/analytics';
import claims from './modules/claims';
import mailIntegration from './modules/mailIntegration';
import signatures from './modules/signatureIntegration';
import coBrokers from './modules/coBrokers';

const rootReducer = combineReducers({
  auth,
  clients,
  country,
  insurance,
  insuranceProviders,
  quotes,
  subscription,
  user,
  policy,
  brokerUsers,
  roles,
  groups,
  product,
  permissions,
  vehicleTypes,
  vehicleTypesOptions,
  notify,
  invoices,
  transaction,
  premiumToRemit,
  accountTypes,
  accounts,
  isItSubAccount,
  summaryOfAccounts,
  invoicePayments,
  accountingPeriod,
  underwriters,
  brokerProfileSettings,
  overviewAnalyticsData,
  claims,
  mailIntegration,
  signatures,
  coBrokers,
});

export default rootReducer;
