export const REQUEST_ACTION = 'ibms-web/user/REQUEST_ACTION';
export const USER_ROLES_REQUEST_ACTION =
  'ibms-web/user/USER_ROLES_REQUEST_ACTION';
export const GET_BRANCHES_REQUEST_ACTION =
  'ibms-web/user/GET_BRANCHES_REQUEST_ACTION';
export const ROLES_REQUEST_ACTION = 'ibms-web/user/ROLES_REQUEST_ACTION';
export const BRANCHES_REQUEST_ACTION = 'ibms-web/user/BRANCHES_REQUEST_ACTION';
export const USER_BRANCHES_REQUEST_ACTION =
  'ibms-web/user/USER_BRANCHES_REQUEST_ACTION';
export const GET_BROKER_USERS_SUCCESS =
  'ibms-web/user/GET_BROKER_USERS_SUCCESS';
export const GET_BROKER_USERS_FAILURE =
  'ibms-web/user/GET_BROKER_USERS_FAILURE';
export const SEND_BROKER_INVITE_SUCCESS =
  'ibms-web/user/SEND_BROKER_INVITE_SUCCESS';
export const SEND_BROKER_INVITE_FAILURE =
  'ibms-web/user/SEND_BROKER_INVITE_FAILURE';
export const GET_BROKER_USER_SUCCESS = 'ibms-web/user/GET_BROKER_USER_SUCCESS';
export const GET_BROKER_USER_FAILURE = 'ibms-web/user/GET_BROKER_USER_FAILURE';
export const GET_USER_ROLES_SUCCESS = 'ibms-web/user/GET_USER_ROLES_SUCCESS';
export const GET_USER_BRANCHES_SUCCESS =
  'ibms-web/user/GET_USER_BRANCHES_SUCCESS';
export const GET_BRANCHES_SUCCESS = 'ibms-web/user/GET_BRANCHES_SUCCESS';
export const GET_BRANCHES_FAILURE = 'ibms-web/user/GET_BRANCHES_FAILURE';
export const GET_USER_ROLES_FAILURE = 'ibms-web/user/GET_USER_ROLES_FAILURE';
export const GET_USER_BRANCHES_FAILURE =
  'ibms-web/user/GET_USER_BRANCHES_FAILURE';
export const GET_USER_GROUPS_SUCCESS = 'ibms-web/user/GET_USER_GROUPS_SUCCESS';
export const GET_USER_GROUPS_FAILURE = 'ibms-web/user/GET_USER_GROUPS_FAILURE';
export const GET_USER_PERMISSIONS_SUCCESS =
  'ibms-web/user/GET_USER_PERMISSIONS_SUCCESS';
export const GET_USER_PERMISSIONS_FAILURE =
  'ibms-web/user/GET_USER_PERMISSIONS_FAILURE';
export const GET_ALL_ROLES_SUCCESS = 'ibms-web/user/GET_ALL_ROLES_SUCCESS';
export const GET_ALL_BRANCHES_SUCCESS =
  'ibms-web/user/GET_ALL_BRANCHES_SUCCESS';
export const GET_ALL_USER_BRANCHES_SUCCESS =
  'ibms-web/user/GET_ALL_USER_BRANCHES_SUCCESS';
export const GET_ALL_ROLES_FAILURE = 'ibms-web/user/GET_ALL_ROLES_FAILURE';
export const GET_ALL_BRANCHES_FAILURE =
  'ibms-web/user/GET_ALL_BRANCHES_FAILURE';
export const GET_ALL_PERMISSIONS_SUCCESS =
  'ibms-web/user/GET_ALL_PERMISSIONS_SUCCESS';
export const GET_ALL_PERMISSIONS_FAILURE =
  'ibms-web/user/GET_ALL_PERMISSIONS_FAILURE';
export const GET_ALL_GROUPS_SUCCESS = 'ibms-web/user/GET_ALL_GROUPS_SUCCESS';
export const GET_ALL_GROUPS_FAILURE = 'ibms-web/user/GET_ALL_GROUPS_FAILURE';
export const ADD_ROLES_TO_USER_SUCCESS =
  'ibms-web/user/ADD_ROLES_TO_USER__SUCCESS';
export const ADD_BRANCHES_TO_USER_SUCCESS =
  'ibms-web/user/ADD_BRANCHES_TO_USER_SUCCESS';
export const ADD_BRANCHES_TO_USER_FAILURE =
  'ibms-web/user/ADD_BRANCHES_TO_USER_FAILURE';
export const ADD_ROLES_TO_USER_FAILURE =
  'ibms-web/user/ADD_ROLES_TO_USER_FAILURE';
export const ADD_GROUPS_TO_USER_SUCCESS =
  'ibms-web/user/ADD_GROUPS_TO_USER_SUCCESS';
export const ADD_GROUPS_TO_USER_FAILURE =
  'ibms-web/user/ADD_GROUPS_TO_USER_FAILURE';
export const ADD_PERMISSIONS_TO_USER_SUCCESS =
  'ibms-web/user/ADD_PERMISSIONS_TO_USER_SUCCESS';
export const ADD_PERMISSIONS_TO_USER_FAILURE =
  'ibms-web/user/ADD_PERMISSIONS_TO_USER_FAILURE';
export const REMOVE_ROLE_FROM_USER_SUCCESS =
  'ibms-web/user/REMOVE_ROLE_FROM_USER_SUCCESS';
export const REMOVE_ROLE_FROM_USER_FAILURE =
  'ibms-web/user/REMOVE_ROLE_FROM_USER_FAILURE';
export const REMOVE_PERMISSION_FROM_USER_SUCCESS =
  'ibms-web/user/REMOVE_PERMISSION_FROM_USER_SUCCESS';
export const REMOVE_PERMISSION_FROM_USER_FAILURE =
  'ibms-web/user/REMOVE_PERMISSION_FROM_USER_FAILURE';
export const REMOVE_USER_FROM_GROUP_SUCCESS =
  'ibms-web/user/REMOVE_USER_FROM_GROUP_SUCCESS';
export const REMOVE_USER_FROM_GROUP_FAILURE =
  'ibms-web/user/REMOVE_USER_FROM_GROUP_FAILURE';
export const CHANGE_USER_STATUS = 'ibms-web/user/CHANGE_USER_STATUS';
export const DELETE_USER_SUCCESS = 'ibms-web/user/DELETE_USER_SUCCESS';
