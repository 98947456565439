import {
  GET_ACCOUNT_TYPE_FAILURE,
  GET_ACCOUNT_TYPE_LOADING,
  GET_ACCOUNT_TYPE_SUCCESS,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const getAccountTypeSuccess = (response) => {
  return {
    type: GET_ACCOUNT_TYPE_SUCCESS,
    response,
  };
};

export const startGetAccountTypes = () => {
  return {
    type: GET_ACCOUNT_TYPE_LOADING,
    accountTypeLoading: true,
  };
};

export const getAccountTypeFail = (error) => ({
  type: GET_ACCOUNT_TYPE_FAILURE,
  error,
});

export const getAccountTypeAction = () => async (dispatch) => {
  dispatch(startGetAccountTypes());
  try {
    const { data } = await axios.get('/accounting/account-type');
    dispatch(getAccountTypeSuccess(data));

    return data;
  } catch (error) {
    dispatch(getAccountTypeFail({ error }));

    return errorHandler(error);
  }
};

export const initialState = {
  response: {},
  accountTypeLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_TYPE_LOADING:
      return {
        ...state,
        accountTypeLoading: true,
      };
    case GET_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        response: action.response,
        accountTypeLoading: false,
      };
    case GET_ACCOUNT_TYPE_FAILURE:
      return {
        ...state,
        response: action.error,
        accountTypeLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
