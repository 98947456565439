/**
 * @description - This function formats the image url to be served with static file.
 * @param {string} url
 * @returns {string} - formatted image url
 */
const formatImageUrl = (url) => {
  const base =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_STATIC_BASE_API
      : window.location.origin + process.env.REACT_APP_STATIC_BASE_PATH;
  const fileNameData = url && url.split('/');
  const fileName = fileNameData && fileNameData[fileNameData.length - 1];
  const src = url && `${base}/${fileName}`;

  return src;
};

export const detectFileType = (file) => {
  if (!file) {
    return '';
  }

  const fragments = file.split('.');
  let extension = fragments[fragments.length - 1];
  extension = extension.toLowerCase();

  if (extension === 'pdf') {
    return 'pdf';
  } else if (
    extension === 'jpg' ||
    extension === 'jpeg' ||
    extension === 'png'
  ) {
    return 'image';
  } else {
    return 'other';
  }
};

export default formatImageUrl;
