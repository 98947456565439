export const REQUEST_ACTION = 'ibms-web/accounts/REQUEST_ACTION';
export const GET_ACCOUNTS_SUCCESS = 'ibms-web/accounts/GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'ibms-web/accounts/GET_ACCOUNTS_FAILURE';
export const START_GETTING_ACCOUNTS =
  'ibms-web/accounts/START_GETTING_ACCOUNTS';
export const CREATE_ACCOUNT_SUCCESS =
  'ibms-web/accounts/CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE =
  'ibms-web/accounts/CREATE_ACCOUNT_FAILURE';
export const START_CREATING_ACCOUNT =
  'ibms-web/accounts/START_CREATING_ACCOUNT';
export const UPDATE_LIST_OF_ACCOUNT =
  'ibms-web/accounts/UPDATE_LIST_OF_ACCOUNT';
export const PATCH_ACCOUNT_SUCCESS = 'ibms-web/accounts/PATCH_ACCOUNT_SUCCESS';
export const PATCH_ACCOUNT_FAILURE = 'ibms-web/accounts/PATCH_ACCOUNT_FAILURE';
export const START_PATCH_ACCOUNT = 'ibms-web/accounts/START_PATCH_ACCOUNT';
export const START_DELETING_ACCOUNT =
  'ibms-web/accounts/START_DELETING_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS =
  'ibms-web/accounts/DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE =
  'ibms-web/accounts/DELETE_ACCOUNT_FAILURE';
export const GET_SINGLE_ACCOUNTS_SUCCESS =
  'ibms-web/accounts/GET_SINGLE_ACCOUNTS_SUCCESS';
export const GET_SINGLE_ACCOUNTS_FAILURE =
  'ibms-web/accounts/GET_SINGLE_ACCOUNTS_FAILURE';
export const GET_TRIAL_BALANCE_SUCCESS =
  'ibms-web/accounts/GET_TRIAL_BALANCE_SUCCESS';
export const GET_TRIAL_BALANCE_FAILURE =
  'ibms-web/accounts/GET_TRIAL_BALANCE_FAILURE';
export const GET_BALANCE_SHEET_SUCCESS =
  'ibms-web/accounts/GET_BALANCE_SHEET_SUCCESS';
export const GET_BALANCE_SHEET_FAILURE =
  'ibms-web/accounts/GET_BALANCE_SHEET_FAILURE';
export const GET_INCOME_STATEMENT_SUCCESS =
  'ibms-web/accounts/GET_INCOME_STATEMENT_SUCCESS';
export const GET_INCOME_STATEMENT_FAILURE =
  'ibms-web/accounts/GET_INCOME_STATEMENT_FAILURE';
