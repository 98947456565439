import React, { useState } from 'react';
import caretIcon from '../../assets/icons/caret.svg';
import capitalize from '../../utils/capitalize';
import get from 'lodash.get';

const SelectBox = (props) => {
  const {
    options,
    onChange,
    value,
    name,
    style,
    description,
    className,
    disabled,
    errorMessage,
    config,
    onBlur,
    edited,
  } = props;

  const [active, setActive] = useState(false);
  const { valueKey, titleKey, optionKey, idKey } = config;
  const backGround = caretIcon;

  const handleFocus = () => {
    setActive(true);
  };

  const handleBlur = (e) => {
    onBlur && onBlur(e);
    if (!value) {
      setActive(false);
    }
  };

  const touched = edited
    ? get(edited, props.name) && errorMessage
    : errorMessage;

  const classes = `block pt-4.5 ${
    disabled ? 'bg-gray-310 cursor-auto' : 'cursor-pointer'
  } text-gray-700 w-full overflow-hidden border-2 
    ${
      (touched || value) && errorMessage
        ? 'border-red-500'
        : 'border-gray-350 focus:border-blue-750'
    }
    leading-tight outline-none focus:outline-none appearance-none bg-white bg-no-repeat border-solid h-12 rounded-md text-sm py-2 px-2 mb-1`;

  return (
    <div className={className}>
      <div className="relative">
        <select
          name={name}
          onChange={onChange}
          onFocus={handleFocus}
          value={value && value}
          className={classes}
          disabled={disabled}
          onBlur={handleBlur}
          style={{
            backgroundImage: `url(${backGround})`,
            textOverflow: 'ellipsis',
            ...style,
          }}
        >
          {name && <option hidden value=""></option>}
          {options &&
            options.map((option, index) =>
              typeof option !== 'string' ? (
                <option
                  value={option[valueKey]}
                  key={index}
                  title={option[titleKey]}
                  id={option[idKey]}
                >
                  {capitalize(option[optionKey])}
                </option>
              ) : (
                <option value={option} key={option}>
                  {capitalize(option)}
                </option>
              ),
            )}
        </select>
        <label
          htmlFor={name}
          className={`absolute w-10/12 text-elipsis overflow-hidden left-0.62 whitespace-no-wrap  text-gray-450 pointer-events-none labelTrans 
          ${
            active || props.value
              ? 'top-0.4 text-xs font-bold'
              : 'text-sm top-0.9'
          }`}
        >
          {description}
        </label>
      </div>
      {touched || value ? (
        <p className="mt-1 text-xs text-red-500">{errorMessage}</p>
      ) : null}
    </div>
  );
};

SelectBox.defaultProps = {
  config: {
    optionKey: 'name',
  },
};

export default SelectBox;
