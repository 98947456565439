import {
  CRAETE_BROKER_BRANCH_FAIL,
  CRAETE_BROKER_BRANCH_LOADING,
  CRAETE_BROKER_BRANCH_SUCCESS,
  // readability purposes
  DELETE_BROKER_BRANCH_FAIL,
  DELETE_BROKER_BRANCH_LOADING,
  DELETE_BROKER_BRANCH_SUCCESS,
  // readability purposes
  GET_BROKER_PROFILE_FAILURE,
  GET_BROKER_PROFILE_LOADING,
  GET_BROKER_PROFILE_SUCCESS,
  // readability purposes
  UPDATE_BROKER_AGENT_PROFILE_FAIL,
  UPDATE_BROKER_AGENT_PROFILE_LOADING,
  UPDATE_BROKER_AGENT_PROFILE_SUCCESS,
  // readability purposes
  UPDATE_BROKER_BRANCH_FAIL,
  UPDATE_BROKER_BRANCH_LOADING,
  UPDATE_BROKER_BRANCH_SUCCESS,
  // readability purposes
  UPDATE_BROKER_PROFILE_FAIL,
  UPDATE_BROKER_PROFILE_LOADING,
  UPDATE_BROKER_PROFILE_SUCCESS,
  // readability purposes
  GET_BROKER_BRANCH_LOADING,
  GET_BROKER_BRANCH_SUCCESS,
  GET_BROKER_BRANCH_FAILURE,
  // readability purposes
  GET_BROKER_AGENT_PROFILE_LOADING,
  GET_BROKER_AGENT_PROFILE_SUCCESS,
  GET_BROKER_AGENT_PROFILE_FAILURE,
  //readability purposes
  UPDATE_BROKER_AGENT_AVATAR_LOADING,
  UPDATE_BROKER_AGENT_AVATAR_SUCCESS,
  UPDATE_BROKER_AGENT_AVATAR_FAILURE,
  UPDATE_BROKER_LOGO_SUCCESS,
  UPDATE_BROKER_LOGO_FAILURE,
  UPDATE_BROKER_LOGO_LOADING,
  GET_BROKER_PREMIUM_ACCOUNTS_SUCCESS,
  ADD_PREMIUM_ACCOUNT_SUCCESS,
  EDIT_PREMIUM_ACCOUNT_SUCCESS,
  DELETE_PREMIUM_ACCOUNT_SUCCESS,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const createBrokerBranchSuccess = (payload) => {
  return {
    type: CRAETE_BROKER_BRANCH_SUCCESS,
    payload,
    creatingBrokerBranch: false,
  };
};

export const createBrokerBranchFailure = (error) => {
  return {
    type: CRAETE_BROKER_BRANCH_FAIL,
    error,
    creatingBrokerBranch: false,
  };
};

export const createBrokerBranchLoading = () => {
  return {
    type: CRAETE_BROKER_BRANCH_LOADING,
    creatingBrokerBranch: true,
  };
};

export const updateBrokerAgentAvatarSuccess = (payload) => {
  return {
    type: UPDATE_BROKER_AGENT_AVATAR_SUCCESS,
    payload,
    updatingBrokerAgentAvatar: false,
  };
};

export const updateBrokerLogoSuccess = (payload) => {
  return {
    type: UPDATE_BROKER_LOGO_SUCCESS,
    payload,
    updatingBrokerLogo: false,
  };
};

export const updateBrokerAgentAvatarFailure = (error) => {
  return {
    type: UPDATE_BROKER_AGENT_AVATAR_FAILURE,
    error,
    updatingBrokerAgentAvatar: false,
  };
};

export const updateBrokerLogoFailure = (error) => {
  return {
    type: UPDATE_BROKER_LOGO_FAILURE,
    error,
    updatingBrokerLogo: false,
  };
};

export const updateBrokerAgentAvatarLoading = () => {
  return {
    type: UPDATE_BROKER_AGENT_AVATAR_LOADING,
    updatingBrokerAgentAvatar: true,
  };
};

export const updateBrokerLogoLoading = () => {
  return {
    type: UPDATE_BROKER_LOGO_LOADING,
    updatingBrokerLogo: true,
  };
};

export const updateBrokerBranchSuccess = (payload) => {
  return {
    type: UPDATE_BROKER_BRANCH_SUCCESS,
    payload,
    updatingBrokerBranch: false,
  };
};

export const updateBrokerBranchFailure = (error) => {
  return {
    type: UPDATE_BROKER_BRANCH_FAIL,
    error,
    updatingBrokerBranch: false,
  };
};

export const updateBrokerBranchLoading = () => {
  return {
    type: UPDATE_BROKER_BRANCH_LOADING,
    updatingBrokerBranch: true,
  };
};

export const deleteBrokerBranchSuccess = (payload) => {
  return {
    type: DELETE_BROKER_BRANCH_SUCCESS,
    payload,
    deletingBrokerBranch: false,
  };
};

export const deleteBrokerBranchFailure = (error) => {
  return {
    type: DELETE_BROKER_BRANCH_FAIL,
    error,
    deletingBrokerBranch: false,
  };
};

export const deleteBrokerBranchLoading = () => {
  return {
    type: DELETE_BROKER_BRANCH_LOADING,
    deletingBrokerBranch: true,
  };
};

export const updateBrokerAgentSuccess = (payload) => {
  return {
    type: UPDATE_BROKER_AGENT_PROFILE_SUCCESS,
    payload,
    updatingBrokerAgent: false,
  };
};

export const updateBrokerAgentFailure = (error) => {
  return {
    type: UPDATE_BROKER_AGENT_PROFILE_FAIL,
    error,
    updatingBrokerAgent: false,
  };
};

export const updateBrokerAgentLoading = () => {
  return {
    type: UPDATE_BROKER_AGENT_PROFILE_LOADING,
    updatingBrokerAgent: true,
  };
};

export const updateBrokerProfileSuccess = (payload) => {
  return {
    type: UPDATE_BROKER_PROFILE_SUCCESS,
    payload,
    updatingBrokerProfile: false,
  };
};

export const updateBrokerProfileFailure = (error) => {
  return {
    type: UPDATE_BROKER_PROFILE_FAIL,
    error,
    updatingBrokerProfile: false,
  };
};

export const updateBrokerProfileLoading = () => {
  return {
    type: UPDATE_BROKER_PROFILE_LOADING,
    updatingBrokerProfile: true,
  };
};

export const getBrokerPremiumAccountsSuccess = (payload) => {
  return {
    type: GET_BROKER_PREMIUM_ACCOUNTS_SUCCESS,
    payload,
  };
};

export const addBrokerPremiumAccountsSuccess = (payload) => {
  return {
    type: ADD_PREMIUM_ACCOUNT_SUCCESS,
    payload,
  };
};

export const editBrokerPremiumAccountsSuccess = (payload) => {
  return {
    type: EDIT_PREMIUM_ACCOUNT_SUCCESS,
    payload,
  };
};

export const deleteBrokerPremiumAccountsSuccess = (payload) => {
  return {
    type: DELETE_PREMIUM_ACCOUNT_SUCCESS,
    payload,
  };
};

export const updateBrokerAgentProfileAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BROKER_AGENT_PROFILE_LOADING });
    const { data } = await axios.put('/broker/agent', payload);
    dispatch(updateBrokerAgentSuccess(data));

    return data;
  } catch (err) {
    dispatch(updateBrokerAgentFailure(err));

    return errorHandler(err);
  }
};

export const updateBrokerAgentAvatarAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BROKER_AGENT_AVATAR_LOADING });
    const { data } = await axios.put('/broker/agent/avatar', payload);
    dispatch(updateBrokerAgentAvatarSuccess(data));

    return data;
  } catch (err) {
    dispatch(updateBrokerAgentAvatarFailure(err));

    return errorHandler(err);
  }
};

export const updateBrokerLogoAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BROKER_LOGO_LOADING });
    const { data } = await axios.put('/broker/logo', payload);
    dispatch(updateBrokerLogoSuccess(data));

    return data;
  } catch (err) {
    dispatch(updateBrokerLogoFailure(err));

    return errorHandler(err);
  }
};

export const updateBrokerProfileAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BROKER_PROFILE_LOADING });
    const { data } = await axios.put('/broker', payload);
    dispatch(updateBrokerProfileSuccess(data));

    return data;
  } catch (err) {
    dispatch(updateBrokerProfileFailure(err));

    return errorHandler(err);
  }
};

export const getBrokerProfileAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_BROKER_PROFILE_LOADING });
    const { data } = await axios.get('/broker');

    dispatch({
      type: GET_BROKER_PROFILE_SUCCESS,
      data,
      gettingBrokerProfile: false,
    });

    return data;
  } catch (err) {
    dispatch({
      type: GET_BROKER_PROFILE_FAILURE,
      error: err,
      gettingBrokerProfile: false,
    });

    return errorHandler(err);
  }
};

export const getBrokerAgentProfileAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_BROKER_AGENT_PROFILE_LOADING });
    const { data } = await axios.get('/broker/agent');
    dispatch({
      type: GET_BROKER_AGENT_PROFILE_SUCCESS,
      data,
      gettingBrokerAgentProfile: false,
    });

    return data;
  } catch (err) {
    dispatch({
      type: GET_BROKER_AGENT_PROFILE_FAILURE,
      error: err,
      gettingBrokerAgentProfile: false,
    });

    return errorHandler(err);
  }
};

export const getBrokerBranchAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_BROKER_BRANCH_LOADING });
    const { data } = await axios.get('/broker/branch');
    dispatch({
      type: GET_BROKER_BRANCH_SUCCESS,
      data,
      gettingBrokerBranch: false,
    });

    return data;
  } catch (err) {
    dispatch({
      type: GET_BROKER_BRANCH_FAILURE,
      error: err,
      gettingBrokerBranch: false,
    });

    return errorHandler(err);
  }
};

export const createBrokerBranchAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: CRAETE_BROKER_BRANCH_LOADING });
    const { data } = await axios.post('/broker/branch', payload);
    dispatch(createBrokerBranchSuccess(data));

    return data;
  } catch (err) {
    dispatch(createBrokerBranchFailure(err));

    return errorHandler(err);
  }
};

export const updateBrokerBranchAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BROKER_BRANCH_LOADING });
    const { data } = await axios.put('/broker/branch', payload);
    dispatch(updateBrokerBranchSuccess(data));

    return data;
  } catch (err) {
    dispatch(updateBrokerBranchFailure(err));

    return errorHandler(err);
  }
};

export const deleteBrokerBranchAction = (branchId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BROKER_BRANCH_LOADING });
    const { data } = await axios.delete(`/broker/branch/${branchId}`);
    dispatch(deleteBrokerBranchSuccess(data));

    return data;
  } catch (err) {
    dispatch(deleteBrokerBranchFailure(deleteBrokerBranchFailure(err)));

    return errorHandler(err);
  }
};

export const getPremiumAccountsAction = () => async (dispatch) => {
  try {
    const { data } = await axios.get(`/broker/premium-account`);
    dispatch(getBrokerPremiumAccountsSuccess(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const addPremiumAccountsAction = (payload) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/broker/premium-account`, payload);
    dispatch(addBrokerPremiumAccountsSuccess(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const editPremiumAccountsAction = (payload, id) => async (dispatch) => {
  try {
    const { data } = await axios.put(`/broker/premium-account/${id}`, payload);
    dispatch(editBrokerPremiumAccountsSuccess(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const deletePremiumAccountsAction = (id) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`/broker/premium-account/${id}`);
    dispatch(deleteBrokerPremiumAccountsSuccess(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const initialState = {
  gettingBrokerProfile: false,
  gettingBrokerAgentProfile: false,
  updatingBrokerAgent: false,
  updatingBrokerBranch: false,
  updatingBrokerProfile: false,
  updatingBrokerAgentAvatar: false,
  updatingBrokerLogo: false,
  brokerProfileData: {},
  brokerAgentAvatarData: {},
  brokerAgentAvatarError: null,
  brokerLogoData: {},
  brokerLogoError: null,
  brokerAgentProfileData: {},
  brokerProfileError: null,
  brokerAgentProfileError: null,
  updateBrokerProfileData: {},
  updateBrokerProfileError: null,
  updateBrokerAgentData: {},
  updateBrokerError: null,
  updateBrokerBranchData: {},
  updatebrokerbranchError: null,
  deleteBrokerBranchData: {},
  deleteBrokerBranchError: null,
  brokerBranchData: {},
  brokerBranchError: null,
  premiumAccounts: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BROKER_PROFILE_LOADING:
      return {
        ...state,
        gettingBrokerProfile: true,
      };
    case GET_BROKER_PROFILE_SUCCESS:
      return {
        ...state,
        brokerProfileData: action.data,
        gettingBrokerProfile: false,
      };
    case GET_BROKER_PROFILE_FAILURE:
      return {
        ...state,
        brokerProfileError: action.error,
        gettingBrokerProfile: false,
      };

    case GET_BROKER_AGENT_PROFILE_SUCCESS:
      return {
        ...state,
        brokerAgentProfileData: action.data,
        gettingBrokerAgentProfile: false,
      };
    case GET_BROKER_AGENT_PROFILE_FAILURE:
      return {
        ...state,
        brokerAgentProfileError: action.error,
        gettingBrokerAgentProfile: false,
      };
    case GET_BROKER_AGENT_PROFILE_LOADING:
      return {
        ...state,
        gettingBrokerAgentProfile: true,
      };

    case UPDATE_BROKER_PROFILE_LOADING:
      return {
        ...state,
        updatingBrokerProfile: true,
      };
    case UPDATE_BROKER_PROFILE_SUCCESS:
      return {
        ...state,
        updateBrokerProfileData: action.payload,
        updatingBrokerProfile: false,
      };
    case UPDATE_BROKER_PROFILE_FAIL:
      return {
        ...state,
        updateBrokerProfileError: action.error,
        updatingBrokerProfile: false,
      };

    case UPDATE_BROKER_AGENT_PROFILE_LOADING:
      return {
        ...state,
        updatingBrokerAgent: true,
      };
    case UPDATE_BROKER_AGENT_PROFILE_SUCCESS:
      return {
        ...state,
        updateBrokerAgentData: action.payload,
        updatingBrokerAgent: false,
      };
    case UPDATE_BROKER_AGENT_PROFILE_FAIL:
      return {
        ...state,
        updateBrokerError: action.error,
        updatingBrokerAgent: false,
      };

    case UPDATE_BROKER_BRANCH_LOADING:
      return {
        ...state,
        updatingBrokerBranch: true,
      };
    case UPDATE_BROKER_BRANCH_SUCCESS:
      return {
        ...state,
        updateBrokerBranchData: action.payload,
        updatingBrokerBranch: false,
      };
    case UPDATE_BROKER_BRANCH_FAIL:
      return {
        ...state,
        updatebrokerbranchError: action.error,
        updatingBrokerBranch: false,
      };

    case DELETE_BROKER_BRANCH_LOADING:
      return {
        ...state,
        deletingBrokerBranch: true,
      };
    case DELETE_BROKER_BRANCH_SUCCESS:
      return {
        ...state,
        deleteBrokerBranchData: {},
        deletingBrokerBranch: false,
      };
    case DELETE_BROKER_BRANCH_FAIL:
      return {
        ...state,
        deleteBrokerBranchError: action.error,
        deletingBrokerBranch: false,
      };
    case GET_BROKER_BRANCH_LOADING:
      return {
        ...state,
        gettingBrokerBranch: true,
      };
    case GET_BROKER_BRANCH_SUCCESS:
      return {
        ...state,
        brokerBranchData: action.data,
        gettingBrokerBranch: false,
      };
    case GET_BROKER_BRANCH_FAILURE:
      return {
        ...state,
        brokerBranchError: action.error,
        gettingBrokerBranch: false,
      };

    case UPDATE_BROKER_AGENT_AVATAR_LOADING:
      return {
        ...state,
        updatingBrokerAgentAvatar: true,
      };
    case UPDATE_BROKER_AGENT_AVATAR_SUCCESS:
      return {
        ...state,
        brokerAgentProfileData: {
          ...state.brokerAgentProfileData,
          data: {
            ...state.brokerAgentProfileData.data,
            Agent: action.payload.data,
          },
        },
        updatingBrokerAgentAvatar: false,
      };
    case UPDATE_BROKER_AGENT_AVATAR_FAILURE:
      return {
        ...state,
        brokerAgentAvatarData: action.error,
        updatingBrokerAgentAvatar: false,
      };

    case UPDATE_BROKER_LOGO_LOADING:
      return {
        ...state,
        updatingBrokerLogo: true,
      };
    case UPDATE_BROKER_LOGO_SUCCESS:
      return {
        ...state,
        brokerProfileData: action.payload,
        updatingBrokerLogo: false,
      };
    case UPDATE_BROKER_LOGO_FAILURE:
      return {
        ...state,
        brokerLogoError: action.error,
        updatingBrokerLogo: false,
      };
    case GET_BROKER_PREMIUM_ACCOUNTS_SUCCESS:
    case ADD_PREMIUM_ACCOUNT_SUCCESS:
    case DELETE_PREMIUM_ACCOUNT_SUCCESS:
      return {
        ...state,
        premiumAccounts: action.payload,
      };
    case EDIT_PREMIUM_ACCOUNT_SUCCESS:
      const acctIndex = state.premiumAccounts.findIndex(
        (account) => account.bank_id === action.payload.bank_id,
      );
      const accounts = [...state.premiumAccounts];

      if (acctIndex !== -1) {
        accounts.splice(acctIndex, 1, action.payload);
      }

      return {
        ...state,
        premiumAccounts: accounts,
      };

    default:
      return state;
  }
};

export default reducer;
