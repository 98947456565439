import React from 'react';
import closeBtnIcon from '../../assets/icons/x.svg';

const Modal = ({
  classes,
  showModal,
  renderHeader,
  renderContent,
  renderFooter,
  renderCloseBtn,
  className,
  isLong,
  useRelativeStyle,
  style,
}) => (
  <div
    className={`${
      showModal ? 'block' : 'hidden'
    } fixed z-50 top-0 left-0 bg-modal-background w-full h-full overflow-y-hidden`}
  >
    <div
      className={`${
        showModal
          ? 'flex justify-center items-center overflow-hidden h-screen'
          : 'hidden'
      } h-auto`}
    >
      <div className={className}>
        {renderCloseBtn && (
          <div className="relative -mb-1/10 ml-9/10">
            <img
              className="cursor-pointer"
              src={closeBtnIcon}
              onClick={renderCloseBtn}
              alt="close"
            />
          </div>
        )}
        <div
          className={`md:p-12 px-4 py-8 box-border ${
            useRelativeStyle ? 'relative' : 'static'
          } rounded bg-white ${isLong ? 'h-75' : 'h-auto'} ${classes} `}
          style={style}
        >
          <>{renderHeader && renderHeader()}</>
          <>{renderContent && renderContent()}</>
          <>{renderFooter && renderFooter()}</>
        </div>
      </div>
    </div>
  </div>
);

export default Modal;
