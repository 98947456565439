import { toast } from 'react-toastify';
import axios from './http';

const CLOUD_NAME = process.env.REACT_APP_CLOUD_NAME;
export const getPreviewUrl = async (fileName) => {
  try {
    const data = await axios.get(
      `https://res.cloudinary.com/${CLOUD_NAME}/image/upload/v1664967442/${fileName}`,
    );
    if (data.status === 200) {
      return `https://res.cloudinary.com/${CLOUD_NAME}/image/upload/v1664967442/${fileName}`;
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const downloadFile = (fileKey, fileName) => {
  const fileUrl = `https://res.cloudinary.com/${CLOUD_NAME}/image/upload/fl_attachment/${fileKey}`;
  return fileUrl;
};

export const download = async (fileKey, fileName) => {
  try {
    const url = downloadFile(fileKey, fileName || 'file.pdf');
    window.location.replace(url);
  } catch (err) {
    toast.error(err.message || 'An error occured');
  }
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const snakeCaseToCamelCase = (str) => {
  const splitted = str.split('_');

  let finalStr;

  // If the length of the splitted string is more that one word,
  // then, we don't want to capitalize the first character of
  // the first word
  if (splitted.length >= 2) {
    const [first, ...rest] = splitted;

    finalStr = `${first}${rest.map((r) => capitalize(r)).join('')}`;

    return finalStr;
  }

  return splitted[0];
};

export const objectKeysToCamelcase = (obj) => {
  const convertedObject = {};

  for (const [key, value] of Object.entries(obj)) {
    const newKey = snakeCaseToCamelCase(key);

    convertedObject[newKey] = value;
  }

  return convertedObject;
};
