import { toast } from 'react-toastify';
import {
  REQUEST_ACTION,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  RESEND_SUCCESS,
  RESEND_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  LOG_USER_OUT,
  BROKER_SIGNUP_SUCCESS,
  BROKER_SIGNUP_FAILURE,
  CHANGE_BROKER_URL_SUCCESS,
  CHANGE_BROKER_URL_FAILURE,
  SOCIAL_AUTH_SUCCESS,
  SOCIAL_AUTH_FAIL,
  SOCIAL_AUTH_REGISTER_SUCCESS,
  SOCIAL_AUTH_REGISTER_FAIL,
  SOCIAL_AUTH_LOADING,
  SOCIAL_AUTH_REGISTER_LOADING,
  AUTH_ERROR,
  LOGOUT_MODAL,
} from './actionTypes';
import axios, { setAxiosHeader } from '../../../utils/http';
import { setLocalStorage, getErrorMessage } from '../../../utils/auth';
import Axios from 'axios';
import { errorHandler } from '../../../utils';

export const socialAuthSuccess = (user) => {
  return {
    type: SOCIAL_AUTH_SUCCESS,
    user,
  };
};

export const socialAuthRegisterSuccess = (user) => {
  return {
    type: SOCIAL_AUTH_SUCCESS,
    user,
  };
};

export const startSocialAuthRegister = () => {
  return {
    type: SOCIAL_AUTH_REGISTER_LOADING,
    socialAuthRegisterLoading: true,
  };
};

export const startSocialAuth = () => {
  return { type: SOCIAL_AUTH_LOADING, socialAuthLoading: true };
};

export const socialAuthRegisterFail = (error) => ({
  type: SOCIAL_AUTH_FAIL,
  error,
});

export const socialAuthFail = (error) => ({
  type: SOCIAL_AUTH_FAIL,
  error,
});

export const socialAuthAction = (baseAPI, socialToken, authType) => async (
  dispatch,
) => {
  let url;

  if (authType === 'facebook') {
    url = `${baseAPI}?code=${socialToken}&redirect_uri=${window.location.origin}/auth/facebook`;
  } else if (authType === 'google') {
    url = `${baseAPI}?code=${socialToken}&redirect_uri=${window.location.origin}/auth/google`;
  } else if (authType === 'linkedin') {
    url = `${baseAPI}?code=${socialToken}&redirect_uri=${window.location.origin}/auth/linkedin`;
  }

  try {
    dispatch(startSocialAuth(SOCIAL_AUTH_LOADING));
    const response = await Axios.get(url);

    if (response.data) {
      const { token } = response.data;
      setLocalStorage('ibmsToken', token);
      setAxiosHeader(token);

      dispatch(
        socialAuthSuccess({
          authenticated: response.data.success,
          token: response.data.token,
        }),
      );

      return response.data;
    }
  } catch (error) {
    dispatch(socialAuthFail(error, authType));

    return errorHandler(error);
  }
};

export const socialAuthRegisterAction = (
  baseAPI,
  socialToken,
  authType,
) => async (dispatch) => {
  let url;
  if (authType === 'facebook') {
    url = `${baseAPI}?code=${socialToken}&redirect_uri=${window.location.origin}/auth/facebook/register`;
  } else if (authType === 'google') {
    url = `${baseAPI}?code=${socialToken}&redirect_uri=${window.location.origin}/auth/google/register`;
  } else if (authType === 'linkedin') {
    url = `${baseAPI}?code=${socialToken}&redirect_uri=${window.location.origin}/auth/linkedin/register`;
  }

  try {
    dispatch(startSocialAuthRegister(SOCIAL_AUTH_REGISTER_LOADING));
    const response = await Axios.get(url);

    if (response.data) {
      const { token } = response.data;
      setLocalStorage('ibmsToken', token);
      setAxiosHeader(token);

      dispatch(
        socialAuthSuccess({
          authenticated: response.data.success,
          token: response.data.token,
        }),
      );

      return response.data;
    }
  } catch (error) {
    dispatch(socialAuthRegisterFail(error, authType));

    return errorHandler(error);
  }
};

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const loginUser = (login) => ({
  type: LOGIN_SUCCESS,
  payload: login,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const registerUser = (register) => ({
  type: REGISTER_SUCCESS,
  payload: register,
});

export const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

export const verifyUser = (payload) => ({
  type: VERIFY_USER_SUCCESS,
  payload,
});

export const verifyUserFailure = (error) => ({
  type: VERIFY_USER_FAILURE,
  payload: error,
});

export const resend = (payload) => ({
  type: RESEND_SUCCESS,
  payload,
});

export const resendFailure = (error) => ({
  type: RESEND_FAILURE,
  payload: error,
});

export const forgotPasswordSuccess = (email) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: email,
});

export const forgotPasswordFailure = (error) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
});

export const resetPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
});

export const logOut = () => ({
  type: LOG_USER_OUT,
});

export const registerBroker = (payload) => ({
  type: BROKER_SIGNUP_SUCCESS,
  payload,
});

export const registerBrokerFailure = (error) => ({
  type: BROKER_SIGNUP_FAILURE,
  payload: error,
});

export const setBrokerUrlSuccess = (data) => ({
  type: CHANGE_BROKER_URL_SUCCESS,
  payload: data,
});

export const setBrokerUrlFailure = (error) => ({
  type: CHANGE_BROKER_URL_FAILURE,
  payload: error,
});

export const setAuthError = (payload) => ({
  type: AUTH_ERROR,
  payload,
});

export const setShowLogoutModal = (payload) => ({
  type: LOGOUT_MODAL,
  payload,
});

export const loginAction = (payload, authError) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('/auth/signin', payload);
    const { token } = data;

    setLocalStorage('ibmsToken', token);
    setAxiosHeader(token);

    dispatch(loginUser(data));
    return data;
  } catch (error) {
    dispatch(loginFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const registerAgentAction = (payload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('/auth/agent/register', payload);
    setLocalStorage('regToken', data.registerToken);
    setAxiosHeader(data.registerToken);

    dispatch(registerUser(data));
    return data;
  } catch (error) {
    dispatch(registerFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const userVerificationAction = (key) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.put(`/auth/verify?key=${key}`);
    setLocalStorage('brokerToken', data.token);
    dispatch(verifyUser(data));

    return data;
  } catch (error) {
    dispatch(verifyUserFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const resendMailAction = (email) => async (dispatch) => {
  dispatch(requestAction());

  try {
    setAxiosHeader(localStorage.getItem('regToken'));
    const { data } = await axios.post('/auth/resend/mail', { email });
    dispatch(resend(data));

    return data;
  } catch (error) {
    dispatch(resendFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const forgotPasswordAction = (email) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('/auth/forgot-password', { email });
    dispatch(forgotPasswordSuccess(data));

    return data;
  } catch (error) {
    dispatch(forgotPasswordFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const resetPasswordAction = (key, payload) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.put(
      `/auth/reset-password?key=${key}`,
      payload,
    );

    dispatch(resetPasswordSuccess(data));
    return data;
  } catch (error) {
    dispatch(resetPasswordFailure(error?.response?.data));
    return errorHandler(error);
  }
};

export const registerBrokerAction = (payload) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.post('/auth/broker/register', payload);
    setLocalStorage('regToken', data.data.registerToken);
    setAxiosHeader(data.data.registerToken);

    dispatch(registerBroker(data));
    return data;
  } catch (error) {
    dispatch(registerBrokerFailure(error?.response?.data));

    return error;
  }
};

export const logOutUser = (redirect) => (dispatch) => {
  try {
    localStorage.removeItem('ibmsToken');
    dispatch(logOut());
    redirect.push('/login');
  } catch (error) {
    redirect.push('/login');
  }
};

export const setBrokerUrlAction = (url) => async (dispatch) => {
  dispatch(requestAction());
  setAxiosHeader(localStorage.getItem('brokerToken'));

  try {
    const { data } = await axios.post('/tenants/url', { url });
    setLocalStorage('ibmsToken', data.data.token);
    setAxiosHeader(data.data.token);
    dispatch(setBrokerUrlSuccess(data));

    return data;
  } catch (error) {
    dispatch(setBrokerUrlFailure(error));

    return errorHandler(error);
  }
};

export const checkPasswordAction = (password) => async (dispatch) => {
  try {
    const { data } = await axios.post('auth/check-password', { password });
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const initialState = {
  data: {},
  isLoading: false,
  socialAuthLoading: false,
  authError: '',
  logout: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SOCIAL_AUTH_LOADING:
      return { ...state, socialAuthLoading: true };
    case SOCIAL_AUTH_REGISTER_LOADING:
      return { ...state, socialAuthRegisterLoading: true };
    case SOCIAL_AUTH_SUCCESS:
      return { ...state, data: action.user, socialAuthLoading: false };
    case SOCIAL_AUTH_REGISTER_SUCCESS:
      return { ...state, data: action.user, socialAuthRegisterLoading: false };
    case SOCIAL_AUTH_REGISTER_FAIL:
      return { ...state, data: action.error, socialAuthRegisterLoading: false };
    case SOCIAL_AUTH_FAIL:
      return { ...state, data: action.error, socialAuthLoading: false };
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case VERIFY_USER_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case RESEND_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case RESEND_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case LOG_USER_OUT:
      return {
        ...state,
        data: {},
      };
    case BROKER_SIGNUP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case BROKER_SIGNUP_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case CHANGE_BROKER_URL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case CHANGE_BROKER_URL_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case AUTH_ERROR:
      return {
        ...state,
        authError: action.payload,
      };
    case LOGOUT_MODAL:
      return {
        ...state,
        logout: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
