export const PREMIUM_FREQUENCY_ENUM = [
  {
    name: 'Monthly',
    id: '1',
  },
  {
    name: 'Quarterly',
    id: '2',
  },
  {
    name: 'Yearly',
    id: '3',
  },
];

export const InvoiceTransactionTypes = {
  NEW_POLICY: 'NEW POLICY',
  POLICY_EDORSEMENT: 'POLICY_EDORSEMENT',
  POLICY_EDORSEMENT_REMITTANCE: 'POLICY_EDORSEMENT_REMITTANCE',
  RENEWAL: 'RENEWWAL',
};

export const InvoiceStatus = {
  PARTIAL_PAYMENT: 'PARTIAL PAYMENT',
  PAYMENT_PENDING: 'PAYMENT PENDING',
  PAID: 'PAID',
};

export const ClientTypes = {
  CORPORATE: 'CORPORATE',
  INDIVIDUAL: 'INDIVIDUAL',
};
