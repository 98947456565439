import axios from 'axios';
import { showLogoutModal } from '../utils/auth';

const Axios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_BASE_API
      : window.location.origin + process.env.REACT_APP_BASE_PATH,
  headers: {
    'Content-Type': 'application/json',
  },
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const res = error.response;

    if (res && res.data.code === 'INVALID_SESSION') {
      localStorage.removeItem('ibmsToken');
      showLogoutModal(true);
      return;
    }

    throw error;
  },
);

export const setAxiosHeader = (token) => {
  Axios.defaults.headers.common['x-access-token'] = `${token}`;
};

const userToken = localStorage.getItem('ibmsToken');

if (userToken) {
  Axios.defaults.headers.common['x-access-token'] = `${userToken}`;
}

export default Axios;
