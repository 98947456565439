/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrowBack,
  Print,
  Download,
  NavigateNext,
  NavigateBefore,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfIcon from './icons8-adobe-acrobat-reader.svg';
import imageIcon from './icons8-image-file-template-isolated-on-white-background-64.png';
import { MoonLoader } from 'react-spinners';
import clsx from 'clsx';
import { detectFileType } from '../../utils/formatImageUrl';
import './preview.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <Document
        file={props.pdf}
        onLoadSuccess={props.onDocumentLoadSuccess}
        onLoadError={props.onDocumentLoadError}
        className="flex justify-center bg-transparent"
        loading={<MoonLoader size={50} color="blue" loading />}
      >
        <Page
          className="pdf-doc"
          pageNumber={props.pageNumber}
          loading={<MoonLoader size={50} color="blue" loading />}
        />
      </Document>
    </div>
  );
});

const PreviewModal = (props) => {
  const componentRef = React.useRef();
  const headerRef = React.useRef();
  const footerRef = React.useRef();

  const [pageNumber, setPageNumber] = React.useState(1);
  const [numPages, setNumPages] = React.useState(null);
  const [imgLoading, setImgLoading] = React.useState(true);

  React.useEffect(() => {
    const handleOutsideClick = (e) => {
      const outsideHeader =
        headerRef.current && !headerRef.current.contains(e.target);
      const outsideFooter =
        footerRef && !footerRef?.current?.contains(e.target);

      const outsideComponent =
        componentRef.current && !componentRef.current.contains(e.target);

      if (outsideHeader && outsideComponent && outsideFooter) {
        props.closePreview();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [componentRef, headerRef, footerRef]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageNumber(1);
    setNumPages(numPages);
  };

  const handlePrevious = () => {
    if (pageNumber === 1) {
      return;
    }

    setPageNumber(pageNumber - 1);
  };

  const handleNext = () => {
    if (pageNumber === numPages) {
      return;
    }

    setPageNumber(pageNumber + 1);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getFileName = (file) => {
    const fragments = file.split('/');
    return fragments[fragments.length - 1].split('?')[0];
  };

  const fileType = detectFileType(props.fileName);

  return (
    <div
      className={clsx({
        backdrop: fileType === 'pdf',
        'image-backdrop': fileType === 'image',
        'overflow-auto': numPages,
      })}
    >
      <div className="header" ref={headerRef}>
        <div className="file-info">
          <IconButton className="button-back" onClick={props.closePreview}>
            <ArrowBack color="inherit" />
          </IconButton>
          <img
            src={fileType === 'pdf' ? pdfIcon : imageIcon}
            alt="pdf"
            className="pdf-image"
          />
          <span>{props.fileName}</span>
        </div>
        <div className="flex items-center">
          {fileType === 'pdf' ? (
            <Tooltip title="Print">
              <IconButton
                className="button-back"
                disabled={!numPages}
                onClick={handlePrint}
              >
                <Print color="inherit" />
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title="Download">
            <IconButton
              className="button-back"
              disabled={!numPages}
              onClick={() => props.download(getFileName(props.url))}
            >
              <Download color="inherit" />
            </IconButton>
          </Tooltip>
          {numPages && props.actions ? props.actions : null}
        </div>
      </div>
      {fileType === 'pdf' ? (
        <div className="pager" ref={footerRef}>
          <div className="py-3 pl-4 pr-4">
            <p className="text-xs">
              <span className="mr-4">Page</span>
              <span className="mr-4">{pageNumber}</span>/
              <span className="ml-4">{numPages || '-----'}</span>
            </p>
          </div>
          <div className="navigate-page py-2 pl-4 pr-4">
            <IconButton
              className={clsx({
                'button-3 button-3-f': pageNumber !== 1,
                'button-3-disabled button-3-f': pageNumber === 1,
              })}
              onClick={handlePrevious}
              disabled={pageNumber === 1}
            >
              <NavigateBefore color="inherit" />
            </IconButton>
            <IconButton
              className={clsx({
                'button-3': pageNumber !== 1,
                'button-3-disabled': pageNumber === 1,
              })}
              onClick={handleNext}
              disabled={pageNumber === numPages}
            >
              <NavigateNext color="inherit" />
            </IconButton>
          </div>
        </div>
      ) : null}
      <div className="flex justify-center items-center sm:h-screen w-auto">
        {imgLoading && fileType !== 'pdf' ? (
          <MoonLoader size={50} color="blue" loading />
        ) : null}
        {fileType === 'pdf' ? (
          <DocumentToPrint
            pdf={props.url}
            ref={componentRef}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            pageNumber={pageNumber}
            onDocumentLoadError={(e) => console.log(e)}
          />
        ) : (
          <img
            src={props.url}
            style={{ display: imgLoading ? 'none' : 'block' }}
            alt="uploaded"
            className="img-preview"
            ref={componentRef}
            onLoad={() => setImgLoading(false)}
          />
        )}
      </div>
    </div>
  );
};

PreviewModal.propTypes = {
  url: PropTypes.string.isRequired,
  download: PropTypes.func.isRequired,
  closePreview: PropTypes.func.isRequired,
};

export default PreviewModal;
