import React from 'react';
import { withRouter } from 'react-router-dom';

const Back = ({text, history}) => {
    return (
        <div className="mb-10">
            <div className="text-sm flex cursor-pointer items-center font-semibold text-gray-600" onClick={() => history.goBack()}>
                <span className="mr-3">
                    <svg className="fill-current text-gray-600" width="12" height="12" viewBox="0 0 20 20">
                        <path d="M6.334 8.5H19v3H6.334L11 16.12 9.097 18 1 10l8.097-8L11 3.88 6.334 8.5z"></path>
                    </svg>
                </span>
                <p>{text}</p>
            </div>
        </div>
    );
};

export default withRouter(Back);