export const UPDATE_BROKER_BRANCH_LOADING = 'UPDATE_BROKER_BRANCH_LOADING';
export const UPDATE_BROKER_BRANCH_SUCCESS = 'UPDATE_BROKER_BRANCH_SUCCESS';
export const UPDATE_BROKER_BRANCH_FAIL = 'UPDATE_BROKER_BRANCH_FAIL';
export const DELETE_BROKER_BRANCH_LOADING = 'DELETE_BROKER_BRANCH_LOADING';
export const DELETE_BROKER_BRANCH_SUCCESS = 'DELETE_BROKER_BRANCH_SUCCESS';
export const DELETE_BROKER_BRANCH_FAIL = 'DELETE_BROKER_BRANCH_FAIL';
export const UPDATE_BROKER_PROFILE_LOADING = 'UPDATE_BROKER_PROFILE_LOADING';
export const UPDATE_BROKER_PROFILE_SUCCESS = 'UPDATE_BROKER_PROFILE_SUCCESS';
export const UPDATE_BROKER_PROFILE_FAIL = 'UPDATE_BROKER_PROFILE_FAIL';
export const UPDATE_BROKER_AGENT_PROFILE_LOADING =
  'UPDATE_BROKER_AGENT_PROFILE_LOADING';
export const UPDATE_BROKER_AGENT_PROFILE_SUCCESS =
  'UPDATE_BROKER_AGENT_PROFILE_SUCCESS';
export const UPDATE_BROKER_AGENT_PROFILE_FAIL =
  'UPDATE_BROKER_AGENT_PROFILE_FAIL';
export const CRAETE_BROKER_BRANCH_LOADING = 'CRAETE_BROKER_BRANCH_LOADING';
export const CRAETE_BROKER_BRANCH_SUCCESS = 'CRAETE_BROKER_BRANCH_SUCCESS';
export const CRAETE_BROKER_BRANCH_FAIL = 'CRAETE_BROKER_BRANCH_FAIL';
export const GET_BROKER_PROFILE_LOADING = 'GET_BROKER_PROFILE_LOADING';
export const GET_BROKER_PROFILE_SUCCESS = 'GET_BROKER_PROFILE_SUCCESS';
export const GET_BROKER_PROFILE_FAILURE = 'GET_BROKER_PROFILE_FAILURE';
export const GET_BROKER_BRANCH_LOADING = 'GET_BROKER_BRANCH_LOADING';
export const GET_BROKER_BRANCH_FAILURE = 'GET_BROKER_BRANCH_FAILURE';
export const GET_BROKER_BRANCH_SUCCESS = 'GET_BROKER_BRANCH_SUCCESS';
export const GET_BROKER_AGENT_PROFILE_LOADING =
  'GET_BROKER_AGENT_PROFILE_LOADING';
export const GET_BROKER_AGENT_PROFILE_SUCCESS =
  'GET_BROKER_AGENT_PROFILE_SUCCESS';
export const GET_BROKER_AGENT_PROFILE_FAILURE =
  'GET_BROKER_AGENT_PROFILE_FAILURE';

export const UPDATE_BROKER_AGENT_AVATAR_LOADING =
  'UPDATE_BROKER_AGENT_AVATAR_LOADING';
export const UPDATE_BROKER_AGENT_AVATAR_SUCCESS =
  'UPDATE_BROKER_AGENT_AVATAR_SUCCESS';
export const UPDATE_BROKER_AGENT_AVATAR_FAILURE =
  'UPDATE_BROKER_AGENT_AVATAR_FAILURE';
export const UPDATE_BROKER_LOGO_SUCCESS = 'UPDATE_BROKER_LOGO_SUCCESS';
export const UPDATE_BROKER_LOGO_LOADING = 'UPDATE_BROKER_LOGO_LOADING';
export const UPDATE_BROKER_LOGO_FAILURE = 'UPDATE_BROKER_LOGO_FAILURE';
export const GET_BROKER_PREMIUM_ACCOUNTS_SUCCESS = 'GET_BROKER_PREMIUM_ACCOUNTS_SUCCESS';
export const ADD_PREMIUM_ACCOUNT_SUCCESS = 'ADD_PREMIUM_ACCOUNT_SUCCESS';
export const EDIT_PREMIUM_ACCOUNT_SUCCESS = 'EDIT_PREMIUM_ACCOUNT_SUCCESS';
export const DELETE_PREMIUM_ACCOUNT_SUCCESS = 'DELETE_PREMIUM_ACCOUNT_SUCCESS';