import {
  REQUEST_ACTION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
} from './actionTypes';
import axios from '../../../utils/http';
import socket from '../../../socket';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const getNotification = (payload) => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload,
});

export const getNotificationFailure = (error) => ({
  type: GET_NOTIFICATION_FAILURE,
  payload: error,
});

export const updateNotification = (payload) => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload,
});

export const updateNotificationFailure = (error) => ({
  type: UPDATE_NOTIFICATION_FAILURE,
  payload: error,
});

/**
 * Create a thunk which makes an API call to get notifications
 *
 * @returns {Function}
 */
export const getNotificationAction = () => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get('/notification');
    dispatch(getNotification(data.data));

    return data;
  } catch (error) {
    dispatch(getNotificationFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to update notification
 *
 * @param {integer} notificationId
 *
 * @returns {Function}
 */
export const updateNotificationAction = (notificationId) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.patch(`/notification/${notificationId}`);
    socket.emit('readNotification', data.data);

    dispatch(updateNotification(data.data));
    return data;
  } catch (error) {
    dispatch(updateNotificationFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const initialState = {
  notificationData: [],
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationData: action.payload,
        isLoading: false,
      };
    case UPDATE_NOTIFICATION_SUCCESS:
      const updated = state.notificationData.filter(
        (data) => data.notification_id !== action.payload,
      );

      return {
        ...state,
        isLoading: false,
        notificationData: updated,
      };
    case UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
