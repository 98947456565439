/* eslint-disable no-unused-expressions */
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import capitalize from './capitalize';
import * as queryString from 'query-string';
import store from '../store';
import { setShowLogoutModal } from '../store/modules/auth';
import {
  hideSubscriptionModal,
  showSubscriptionModal,
} from '../store/modules/subscription';

export const decodedToken = (token) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    console.error('invalid token');
  }
};

export const setLocalStorage = (item, value) =>
  localStorage.setItem(item, value);

export const authenticate = () => {
  try {
    const token = localStorage.getItem('ibmsToken');
    return token;
  } catch (error) {
    return null;
  }
};

/**
 *
 * @param {*} redirectUri
 * @param {*} serviceProvider
 */
export const createSocialAuthLink = (redirectUri, serviceProvider) => {
  let link;
  if (serviceProvider === 'facebook') {
    const facebookStringifiedParams = queryString.stringify({
      client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
      scope: 'email',
      redirect_uri: redirectUri,
      auth_type: 'rerequest',
      display: 'popup',
      response_type: 'code',
    });
    link = `https://www.facebook.com/v8.0/dialog/oauth?${facebookStringifiedParams}`;
  } else if (serviceProvider === 'linkedin') {
    const linkedinStringifiedParams = queryString.stringify({
      client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      scope: 'r_emailaddress',
      response_type: 'code',
      redirect_uri: redirectUri,
    });
    link = `https://www.linkedin.com/oauth/v2/authorization?${linkedinStringifiedParams}`;
  } else if (serviceProvider === 'google') {
    const googleStringifiedParams = queryString.stringify({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: redirectUri,
      scope: 'https://www.googleapis.com/auth/userinfo.email',
      response_type: 'code',
      access_type: 'offline',
      prompt: 'consent',
    });
    link = `https://accounts.google.com/o/oauth2/v2/auth?${googleStringifiedParams}`;
  }
  return link;
};

export const displayErrors = (errors, setErrors) => {
  const errorObject = {};
  errors.map((error) => (errorObject[error.field] = capitalize(error.message)));
  setErrors?.(errorObject);
  return errorObject;
};

export const formatErrors = (errors) => {
  const array = Array.isArray(errors);

  if (array) {
    return errors.map((error) =>
      typeof error === 'string' ? error : error.message,
    );
  }

  return errors;
};

export const USER_TOKEN = localStorage.getItem('ibmsToken');

export const checkUser = (url) => {
  const userUrl = url.split('.');

  if (userUrl.length === 2 || (userUrl.length === 3 && userUrl[0] === 'www')) {
    return true;
  }
  return false;
};

export const showLogoutModal = (state) => {
  store.dispatch(setShowLogoutModal(state));
};

export const showSubscriptionModalAlert = () => {
  store.dispatch(showSubscriptionModal());
};

export const hideSubscriptionModalAlert = () => {
  store.dispatch(hideSubscriptionModal());
};

export const getErrorMessage = (status) => {
  switch (status) {
    case 500:
      return toast.error('Something went wrong, please try again');
    case 404:
      return toast.error('An error ocurred trying to fetch resource');
    case 403:
      return toast.error('Action Forbidden');
    case 401:
      return toast.error('You are not logged in, your session has expired');
    case 409:
      return toast.error('An error occurred, please try again');
    default:
      return;
  }
};

export const checkLoginUser = () => {
  let user;
  const token = localStorage.getItem('ibmsToken');
  if (token) {
    user = decodedToken(token);
    //get loggedin user id
    user = user && user.id;
  }
  return user;
};
