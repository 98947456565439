import { GET_VEHICLE_TYPE_SUCCESS } from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const vehicleTypes = (payload) => ({
  type: GET_VEHICLE_TYPE_SUCCESS,
  payload,
});

/**
 * Create a thunk which makes an API call to get list of vehicle types
 *
 * @returns {Function}
 */
export const vehicleTypesAction = () => async (dispatch) => {
  try {
    const response = await axios.get('/vehicle/types');
    const { data } = response.data;
    dispatch(vehicleTypes(data));

    return { success: true, data };
  } catch (error) {
    errorHandler(error);
  }
};

export const initialState = {
  vehicleTypeData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VEHICLE_TYPE_SUCCESS:
      return {
        ...state,
        vehicleTypesData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
