export const REQUEST_ACTION = 'ibms-web/quotes/REQUEST_ACTION';
export const SEND_QUOTE_REQUEST_ACTION =
  'ibms-web/quotes/SEND_QUOTE_REQUEST_ACTION';
export const QUOTE_ACTION = 'ibms-web/quotes/QUOTE_ACTION';
export const QUOTE_FAIL_ACTION = 'ibms-web/quotes/QUOTE_FAIL_ACTION';
export const QUOTE_UPDATE_ACTION = 'ibms-web/quotes/QUOTE_UPDATE_ACTION';
export const QUOTE_UPDATE_FAIL_ACTION =
  'ibms-web/quotes/QUOTE_UPDATE_FAIL_ACTION';
export const CREATE_INSURANCE_QUOTE_SUCCESS =
  'ibms-web/quotes/CREATE_INSURANCE_QUOTE_SUCCESS';
export const CREATE_INSURANCE_QUOTE_FAILURE =
  'ibms-web/quotes/CREATE_INSURANCE_QUOTE_FAILURE';
export const GET_INSURANCE_QUOTE_SUCCESS =
  'ibms-web/quotes/GET_INSURANCE_QUOTE_SUCCESS';
export const GET_INSURANCE_QUOTE_FAILURE =
  'ibms-web/quotes/GET_INSURANCE_QUOTE_FAILURE';
export const GET_POLICY_HOLDER_SUCCESS =
  'ibms-web/quotes/GET_POLICY_HOLDER_SUCCESS';
export const GET_PROPERTY_CATEGORY_SUCCESS =
  'ibms-web/quotes/GET_PROPERTY_CATEGORY_SUCCESS';
export const GET_SINGLE_INSURANCE_QUOTE_SUCCESS =
  'ibms-web/quotes/GET_SINGLE_INSURANCE_QUOTE_SUCCESS';
export const GET_SINGLE_INSURANCE_QUOTE_FAILURE =
  'ibms-web/quotes/GET_SINGLE_INSURANCE_QUOTE_FAILURE';
export const UPDATE_INSURANCE_QUOTE_SUCCESS =
  'ibms-web/quotes/UPDATE_INSURANCE_QUOTE_SUCCESS';
export const UPDATE_INSURANCE_QUOTE_FAILURE =
  'ibms-web/quotes/UPDATE_INSURANCE_QUOTE_FAILURE';
export const GET_CLIENT_INSURANCE_QUOTE_SUCCESS =
  'ibms-web/quotes/GET_CLIENT_INSURANCE_QUOTE_SUCCESS';
export const GET_INVOICES_SUCCESS = 'ibms-web/quotes/GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'ibms-web/quotes/GET_INVOICES_FAILURE';
export const ADD_INVOICE_PAYMENT_SUCCESS =
  'ibms-web/quotes/ADD_INVOICE_PAYMENT_SUCCESS';
export const ADD_INVOICE_PAYMENT_FAILURE =
  'ibms-web/quotes/ADD_INVOICE_PAYMENT_FAILURE';
export const SAVE_INSURANCE_QUOTE_SUCCESS =
  'ibms-web/quotes/SAVE_INSURANCE_QUOTE_SUCCESS';
export const SAVE_INSURANCE_QUOTE_FAILURE =
  'ibms-web/quotes/SAVE_INSURANCE_QUOTE_FAILURE';
export const SEND_QUOTE_SUCCESS = 'ibms-web/quotes/SEND_QUOTE_SUCCESS';
export const GENERATE_DEBIT_NOTE = 'ibms-web/quotes/GENERATE_DEBIT_NOTE';

export const GENERATE_CREDIT_NOTE = 'ibms-web/quotes/GENERATE_CREDIT_NOTE';
export const SEND_DEBIT_NOTE = 'ibms-web/quotes/SEND_DEBIT_NOTE';
export const UPDATE_QUOTE_APPROVAL_STATUS_SUCCESS =
  'ibms-web/quotes/UPDATE_QUOTE_APPROVAL_STATUS_SUCCESS';
export const DOWNLOAD_PDF_NOTE_SUCCESS =
  'ibms-web/quotes/DOWNLOAD_PDF_NOTE_SUCCESS';

export const DOWNLOAD_PDF_NOTE_FAILURE =
  'ibms-web/quotes/DOWNLOAD_PDF_NOTE_FAILURE';

export const DOWNLOAD_PDF_NOTE_LOADING =
  'ibms-web/quotes/DOWNLOAD_PDF_NOTE_LOADING';

export const SEND_DEBIT_NOTE_LOADING =
  'ibms-web/quotes/SEND_DEBIT_NOTE_LOADING';

export const SEND_DEBIT_NOTE_SUCCESS =
  'ibms-web/quotes/SEND_DEBIT_NOTE_SUCCESS';

export const SEND_CREDIT_NOTE_FAILURE =
  'ibms-web/quotes/SEND_CREDIT_NOTE_FAILURE';

export const SEND_CREDIT_NOTE_LOADING =
  'ibms-web/quotes/SEND_CREDIT_NOTE_LOADING';

export const SEND_CREDIT_NOTE_SUCCESS =
  'ibms-web/quotes/SEND_CREDIT_NOTE_SUCCESS';

export const SEND_DEBIT_NOTE_FAILURE =
  'ibms-web/quotes/SEND_DEBIT_NOTE_FAILURE';

export const SEND_DEBIT_INVOICE_SUCCESS =
  'ibms-web/quotes/SEND_DEBIT_INVOICE_SUCCESS';

export const SEND_RECEIPT_INVOICE_SUCCESS =
  'ibms-web/quotes/SEND_RECEIPT_INVOICE_SUCCESS';

export const GENERATE_DEBIT_INVOICE_SUCCESS =
  'ibms-web/quotes/GENERATE_DEBIT_INVOICE_SUCCESS';

export const REQUEST_QUOTE_SUCCESS = 'ibms-web/quotes/REQUEST_QUOTE_SUCCESS';

export const REQUEST_QUOTE_FAILURE = 'ibms-web/quotes/REQUEST_QUOTE_FAILURE';

export const GENERATE_RECEIPT_NOTE = 'ibms-web/quotes/GENERATE_RECEIPT_NOTE';
