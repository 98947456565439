export const REQUEST_ACTION = 'ibms-web/invoices/REQUEST_ACTION';
export const INVOICE_ACTION = 'ibms-web/invoices/INVOICE_ACTION'
export const GET_INVOICES_SUCCESS = 'ibms-web/invoices/GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'ibms-web/invoices/GET_INVOICES_FAILURE';
export const ADD_INVOICE_PAYMENT_SUCCESS =
  'ibms-web/invoices/ADD_INVOICE_PAYMENT_SUCCESS';
export const ADD_INVOICE_PAYMENT_FAILURE =
  'ibms-web/invoices/ADD_INVOICE_PAYMENT_FAILURE';
export const GENERATE_DEBIT_NOTE = 'ibms-web/invoices/GENERATE_DEBIT_NOTE';
export const SEND_DEBIT_NOTE = 'ibms-web/invoices/SEND_DEBIT_NOTE';
export const GET_SINGLE_INVOICE_SUCCESS = 'ibms-web/invoices/GET_SINGLE_INVOICE_SUCCESS';
export const GET_SINGLE_INVOICE_FAILURE = 'ibms-web/invoices/GET_SINGLE_INVOICE_FAILURE';
export const UPDATE_INVOICE_SUCCESS = 'ibms-web/invoices/UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAILURE = 'ibms-web/invoices/UPDATE_INVOICE_FAILURE';
export const REQUEST_CREATE_INVOICE_ACTION = 'ibms-web/invoices/REQUEST_CREATE_INVOICE_ACTION';
export const CREATE_INVOICE_SUCCESS = 'ibms-web/invoices/CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_FAILURE = 'ibms-web/invoices/CREATE_INVOICE_FAILURE';
export const TOOGLE_ACCOUNT_HEADER = 'ibms-web/invoices/TOOGLE_ACCOUNT_HEADER';
export const REQUEST_ADD_PAYMENT_TO_AN_INVOICE = 'ibms-web/invoices/REQUEST_ADD_PAYMENT_TO_AN_INVOICE';
export const ADD_PAYMENT_TO_AN_INVOICE_SUCCESS = 'ibms-web/invoices/ADD_PAYMENT_TO_AN_INVOICE_SUCCESS';
export const ADD_PAYMENT_TO_AN_INVOICE_FAILURE = 'ibms-web/invoices/ADD_PAYMENT_TO_AN_INVOICE_FAILURE';
