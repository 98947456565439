import React from 'react';
import { Link } from 'react-router-dom';

const HeaderLogo = (props) => {
  const appLogo = require(`../../assets/icons/${props.logoName}.png`);

  return (
    <Link
      to={props.redirect ? '/' : '#'}
      className={`${
        props.redirect ? '' : 'cursor-default'
      } flex items-center justify-center`}
      data-testid="headerLogo-testId"
    >
      <div>
        <img src={appLogo} alt="logo" className="w-32" />
      </div>
    </Link>
  );
};

HeaderLogo.defaultProps = {
  redirect: true,
};

export default HeaderLogo;
