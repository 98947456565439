import React from 'react';
import profileIcon from '../../assets/icons/profile-icon.png';

const AvatarDropDown = ({ profile, userName, userEmail, imgUrl, logOut }) => (
  <div className="w-56 border absolute flex flex-col top-3.8 right-3 bg-white rounded shadow-pc pb-2">
    <div className="pt-6 px pb-2">
      <img
        src={imgUrl ? imgUrl : profileIcon}
        alt=""
        className="rounded-full w-12 h-12 mx-auto"
      />
      <p className="text-center text-sm break-words mt-3 font-semibold">
        {userName}
      </p>
      <p className="text-center text-xs break-words font-semibold text-gray-700">
        {userEmail}
      </p>
    </div>
    <p
      className="text-blue-350 text-xs cursor-pointer px-6 py-2 hover:bg-gray-150 hover:text-blue-750 hover:font-semibold"
      onClick={profile}
    >
      Go to Profile
    </p>
    <p
      className="text-blue-350 text-xs cursor-pointer px-6 py-2 hover:bg-gray-150 hover:text-blue-750 hover:font-semibold"
      onClick={logOut}
    >
      Logout
    </p>
  </div>
);

export default AvatarDropDown;
