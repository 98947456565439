import { UPDATE_VEHICLE_TYPE_OPTION } from './actionTypes';

export const updateVehicleTypeOption = (vehicleTypes) => ({
  type: UPDATE_VEHICLE_TYPE_OPTION,
  vehicleTypes,
});

export const updateVehicleTypeOptionAction = (vehicleTypes) => (dispatch) => {
  dispatch(updateVehicleTypeOption(vehicleTypes));
};

export const initialState = {
  vehicleTypes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VEHICLE_TYPE_OPTION:
      return {
        ...state,
        vehicleTypes: action.vehicleTypes,
      };
    default:
      return state;
  }
};

export default reducer;
