import { io } from 'socket.io-client';
import store from './store';
import { getNotification } from './store/modules/notify/index';

const socket = io(
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_STATIC_BASE_API
    : window.location.origin,
);

socket.on('connect', () => {
  console.log('connected to server');

  socket.on('createQuote', (data) => {
    store.dispatch(getNotification(data));
  });

  socket.on('updateQuote', (data) => {
    store.dispatch(getNotification(data));
  });

  socket.on('readNotification', (data) => {
    store.dispatch(getNotification(data));
  });

  socket.on('disconnect', () => {
    console.log('user is disconnected');
  });
});

export default socket;
