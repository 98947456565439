import {
  REQUEST_ACTION,
  GET_UNDERWRITERS_SUCCESS,
  GET_UNDERWRITERS_FAILURE,
  ADD_UNDERWRITERS_SUCCESS,
  ADD_UNDERWRITERS_FAILURE,
  EDIT_UNDERWRITERS_SUCCESS,
  EDIT_UNDERWRITERS_FAILURE,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const getInsuranceUnderwriter = (payload) => ({
  type: GET_UNDERWRITERS_SUCCESS,
  payload,
  success: true,
});

export const getInsuranceUnderwriterFailure = (error) => ({
  type: GET_UNDERWRITERS_FAILURE,
  payload: error,
});

export const addInsuranceUnderwriter = (payload) => ({
  type: ADD_UNDERWRITERS_SUCCESS,
  payload,
});

export const addUnderwriterFailure = (error) => ({
  type: ADD_UNDERWRITERS_FAILURE,
  payload: error,
});

export const editInsuranceUnderwriter = (payload) => ({
  type: EDIT_UNDERWRITERS_SUCCESS,
  payload,
});

export const editUnderwriterFailure = (error) => ({
  type: EDIT_UNDERWRITERS_FAILURE,
  payload: error,
});

export const getUnderwritersAction = (
  underwriterId,
  payload = null,
  fetchFromCloud = true,
) => async (dispatch) => {
  try {
    dispatch(requestAction());

    if (fetchFromCloud) {
      const { data } = await axios.get(
        `/providers/${underwriterId}/underwriters`,
      );

      dispatch(getInsuranceUnderwriter(data.data));
      return data;
    } else {
      dispatch(getInsuranceUnderwriter(payload));
      return payload;
    }
  } catch (error) {
    dispatch(getInsuranceUnderwriterFailure(error?.response?.data));
    return errorHandler(error);
  }
};

export const addUnderwritersAction = (underwriterId, payload) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post(
      `/providers/${underwriterId}/underwriters`,
      payload,
    );

    dispatch(addInsuranceUnderwriter(data.data));
    return data;
  } catch (error) {
    dispatch(addUnderwriterFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const editUnderwritersAction = (underwriterId, payload) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.put(`/underwriters/${underwriterId}`, payload);
    dispatch(editInsuranceUnderwriter(data.data));

    return data;
  } catch (error) {
    dispatch(editUnderwriterFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const initialState = {
  data: [],
  isLoading: false,
  error: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_UNDERWRITERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_UNDERWRITERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case ADD_UNDERWRITERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_UNDERWRITERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case EDIT_UNDERWRITERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_UNDERWRITERS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
