export const REQUEST_ACTION = 'ibms-web/subscription/REQUEST_ACTION';
export const CHARGE_CARD_SUCCESS = 'ibms-web/subscription/CHARGE_CARD_SUCCESS';
export const CHARGE_CARD_FAILURE = 'ibms-web/subscription/CHARGE_CARD_FAILURE';
export const VALIDATE_CARD_SUCCESS =
  'ibms-web/subscription/VALIDATE_CARD_SUCCESS';
export const VALIDATE_CARD_FAILURE =
  'ibms-web/subscription/VALIDATE_CARD_FAILURE';
export const GET_PAYMENT_PLAN_SUCCESS =
  'ibms-web/subscription/GET_PAYMENT_PLAN_SUCCESS';
export const SHOW_SUBSCRIPTION_MODAL = 'SHOW_SUBSCRIPTION_MODAL';
export const HIDE_SUBSCRIPTION_MODAL = 'HIDE_SUBSCRIPTION_MODAL';
