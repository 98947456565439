export const REQUEST_ACTION = 'ibms-web/auth/REQUEST_ACTION';
export const LOGIN_SUCCESS = 'ibms-web/auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'ibms-web/auth/LOGIN_FAILURE';
export const REGISTER_SUCCESS = 'ibms-web/auth/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'ibms-web/auth/REGISTER_FAILURE';
export const VERIFY_USER_SUCCESS = 'ibms-web/auth/VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAILURE = 'ibms-web/auth/VERIFY_USER_FAILURE';
export const RESEND_SUCCESS = 'ibms-web/auth/RESEND_SUCCESS';
export const RESEND_FAILURE = 'ibms-web/auth/RESEND_FAILURE';
export const FORGOT_PASSWORD_SUCCESS = 'ibms-web/auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'ibms-web/auth/FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'ibms-web/auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'ibms-web/auth/RESET_PASSWORD_FAILURE';
export const LOG_USER_OUT = 'ibms-web/auth/LOG_USER_OUT';
export const BROKER_SIGNUP_SUCCESS = 'ibms-web/auth/BROKER_SIGNUP_SUCCESS';
export const BROKER_SIGNUP_FAILURE = 'ibms-web/auth/BROKER_SIGNUP_FAILURE';
export const CHANGE_BROKER_URL_SUCCESS =
  'ibms-web/auth/CHANGE_BROKER_URL_SUCCESS';
export const CHANGE_BROKER_URL_FAILURE =
  'ibms-web/auth/CHANGE_BROKER_URL_SUCCESS';

export const SOCIAL_AUTH_FAIL = 'ibms-web/auth/SOCIAL_AUTH_FAIL';
export const SOCIAL_AUTH_SUCCESS = 'ibms-web/auth/SOCIAL_AUTH_SUCCESS';
export const SOCIAL_AUTH_LOADING = 'ibms-web/auth/SOCIAL_AUTH_LOADING';
export const SOCIAL_AUTH_REGISTER_FAIL = 'ibms-web/auth/SOCIAL_AUTH_REGISTER_FAIL';
export const SOCIAL_AUTH_REGISTER_SUCCESS = 'ibms-web/auth/SOCIAL_AUTH_REGISTER_SUCCESS';
export const SOCIAL_AUTH_REGISTER_LOADING = 'ibms-web/auth/SOCIAL_AUTH_REGISTER_LOADING';
export const AUTH_ERROR = 'ibms-web/auth/AUTH_ERROR';
export const LOGOUT_MODAL = 'ibms-web/auth/LOGOUT_MODAL'
