import React from 'react';
import { ToastContainer } from 'react-toastify';
import Routes from '../routes';

const App = () => (
  <>
    <ToastContainer limit={1} />
    <Routes />
  </>
);

export default App;
