export const REQUEST_ACTION = 'ibms-web/policy/REQUEST_ACTION';

export const SEND_REQUEST_ACTION = 'ibms-web/policy/SEND_REQUEST_ACTION';

export const GET_EXPIRING_POLICY_SUCCESS =
  'ibms-web/policy/GET_EXPIRING_POLICY_SUCCESS';

export const GET_EXPIRING_POLICY_FAILURE =
  'ibms-web/policy/GET_EXPIRING_POLICY_FAILURE';

export const SEND_EXPIRING_POLICY_NOTIFICATION_SUCCESS =
  'ibms-web/policy/SEND_EXPIRING_POLICY_NOTIFICATION_SUCCESS';

export const GET_SINGLE_POLICY_SUCCESS =
  'ibms-web/policy/GET_SINGLE_POLICY_SUCCESS';

export const GET_SINGLE_POLICY_FAILURE =
  'ibms-web/policy/GET_SINGLE_POLICY_FAILURE';

export const SEND_CREDIT_NOTE_SUCCESS =
  'ibms-web/policy/SEND_CREDIT_NOTE_SUCCESS';

export const SEND_CREDIT_NOTE_FAILURE =
  'ibms-web/policy/SEND_CREDIT_NOTE_FAILURE';

export const GET_POLICY_SUCCESS = 'ibms-web/policy/GET_POLICY_SUCCESS';

export const GET_POLICY_FAILURE = 'ibms-web/policy/GET_POLICY_FAILURE';

export const UPDATE_POLICY_STATUS_SUCCESS =
  'ibms-web/policy/UPDATE_POLICY_STATUS_SUCCESS';

export const UPDATE_POLICY_STATUS_FAILURE =
  'ibms-web/policy/UPDATE_POLICY_STATUS_FAILURE';

export const UPLOAD_POLICY_DOCUMENT_SUCCESS =
  'ibms-web/policy/UPLOAD_POLICY_DOCUMENT_SUCCESS';

export const UPLOAD_POLICY_DOCUMENT_FAILURE =
  'ibms-web/policy/UPLOAD_POLICY_DOCUMENT_FAILURE';

export const SEND_POLICY_DOCUMENT_SUCCESS =
  'ibms-web/policy/SEND_POLICY_DOCUMENT_SUCCESS';

export const SEND_POLICY_DOCUMENT_FAILURE =
  'ibms-web/policy/SEND_POLICY_DOCUMENT_FAILURE';

export const CREATE_POLICY_PROPOSAL_FAILURE =
  'ibms-web/policy/CREATE_POLICY_PROPOSAL_FAILURE';

export const EDIT_POLICY_PROPOSAL_FAILURE =
  'ibms-web/policy/EDIT_POLICY_PROPOSAL_FAILURE';

export const CREATE_POLICY_PROPOSAL_SUCCESS =
  'ibms-web/policy/CREATE_POLICY_PROPOSAL_SUCCESS';

export const EDIT_POLICY_PROPOSAL_SUCCESS =
  'ibms-web/policy/EDIT_POLICY_PROPOSAL_SUCCESS';

export const CREATE_INVOICE_SUCCESS = 'ibms-web/policy/CREATE_INVOICE_SUCCESS';

export const SEND_PROPOSAL_DOCUMENT_SUCCESS =
  'ibms-web/policy/SEND_PROPOSAL_DOCUMENT_SUCCESS';

export const SEND_PROPOSAL_DOCUMENT_FAILURE =
  'ibms-web/policy/SEND_PROPOSAL_DOCUMENT_FAILURE';

export const GET_POLICY_PROPOSAL_DETAILS_SUCCESS =
  'ibms-web/policy/GET_POLICY_PROPOSAL_DETAILS_SUCCESS';

export const GET_POLICY_PROPOSAL_DETAILS_FAILURE =
  'ibms-web/policy/GET_POLICY_PROPOSAL_DETAILS_FAILURE';

export const CREATE_POLICY_ENDORSEMENT_SUCCESS =
  'ibms-web/policy/CREATE_POLICY_ENDORSEMENT_SUCCESS';

export const CREATE_POLICY_ENDORSEMENT_FAILURE =
  'ibms-web/policy/CREATE_POLICY_ENDORSEMENT_FAILURE';

export const GET_POLICY_ENDORSEMENT_SUCCESS =
  'ibms-web/policy/GET_POLICY_ENDORSEMENT_SUCCESS';

export const GET_SINGLE_POLICY_ENDORSEMENT =
  'ibms-web/policy/GET_SINGLE_POLICY_ENDORSEMENT';

export const UPDATE_POLICY_ENDORSEMENT_STATUS =
  'ibms-web/policy/UPDATE_POLICY_ENDORSEMENT_STATUS';

export const UPLOAD_ENDORSEMENT_DOCUMENT_SUCCESS =
  'ibms-web/policy/UPLOAD_ENDORSEMENT_DOCUMENT_SUCCESS';

export const UPLOAD_ENDORSEMENT_DOCUMENT_FAILURE =
  'ibms-web/policy/UPLOAD_ENDORSEMENT_DOCUMENT_FAILURE';

export const SEND_ENDORSEMENT_DOCUMENT_SUCCESS =
  'ibms-web/policy/SEND_ENDORSEMENT_DOCUMENT_SUCCESS';

export const SEND_ENDORSEMENT_DOCUMENT_FAILURE =
  'ibms-web/policy/SEND_ENDORSEMENT_DOCUMENT_FAILURE';

export const SEND_PLACEMENT_SLIP_SUCCESS =
  'ibms-web/policy/SEND_PLACEMENT_SLIP_SUCCESS';

export const SEND_PLACEMENT_SLIP_FAILURE =
  'ibms-web/policy/SEND_PLACEMENT_SLIP_FAILURE';
