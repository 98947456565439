import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../utils/auth';
import {
  REQUEST_ACTION,
  GET_GROUPS_FAILURE,
  GET_GROUPS_SUCCESS,
  GET_GROUP_FAILURE,
  GET_GROUP_SUCCESS,
  GET_GROUP_PERMISSIONS_SUCCESS,
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP_SUCCESS,
  ADD_USERS_TO_GROUP_SUCCESS,
  ADD_USERS_TO_GROUP_FAILURE,
  EDIT_GROUP_SUCCESS,
  ADD_PERMISSIONS_TO_GROUP_SUCCESS,
  REMOVE_PERMISSION_FROM_GROUP_SUCCESS,
  GET_GROUP_USERS_FAILURE,
  GET_GROUP_USERS_SUCCESS,
  REMOVE_USER_FROM_GROUP_SUCCESS,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const getGroupsSuccess = (payload) => ({
  type: GET_GROUPS_SUCCESS,
  payload,
});

export const getGroupsFailure = (payload) => ({
  type: GET_GROUPS_FAILURE,
  payload,
});

export const getGroupSuccess = (payload) => ({
  type: GET_GROUP_SUCCESS,
  payload,
});

export const getGroupFailure = (payload) => ({
  type: GET_GROUP_FAILURE,
  payload,
});

export const getGroupPermissionsSuccess = (payload) => ({
  type: GET_GROUP_PERMISSIONS_SUCCESS,
  payload,
});

export const getGroupPermissionsFailure = (payload) => ({
  type: GET_GROUP_PERMISSIONS_SUCCESS,
  payload,
});

export const getGroupUsersSuccess = (payload) => ({
  type: GET_GROUP_USERS_SUCCESS,
  payload,
});

export const getGroupUsersFailure = (payload) => ({
  type: GET_GROUP_USERS_FAILURE,
  payload,
});

export const createGroupSuccess = (payload) => ({
  type: CREATE_GROUP_SUCCESS,
  payload,
});

export const editGroupSuccess = (payload) => ({
  type: EDIT_GROUP_SUCCESS,
  payload,
});

export const deleteGroupSuccess = (payload) => ({
  type: DELETE_GROUP_SUCCESS,
  payload,
});

export const addUsersToGroupSuccess = (payload) => ({
  type: ADD_USERS_TO_GROUP_SUCCESS,
  payload,
});

export const addPermissionsToGroupSuccess = (payload) => ({
  type: ADD_PERMISSIONS_TO_GROUP_SUCCESS,
  payload,
});

export const removePermissionFromGroupSuccess = (payload) => ({
  type: REMOVE_PERMISSION_FROM_GROUP_SUCCESS,
  payload,
});

export const removeUserFromGroupSuccess = (payload) => ({
  type: REMOVE_USER_FROM_GROUP_SUCCESS,
  payload,
});

export const addUsersToGroupFailure = (payload) => ({
  type: ADD_USERS_TO_GROUP_FAILURE,
  payload,
});

export const getGroupsAction = () => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get('/groups');
    dispatch(getGroupsSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getGroupsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getGroupAction = (groupId) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get(`/groups/${groupId}`);
    dispatch(getGroupSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getGroupFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getGroupUsersAction = (groupId) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/groups/${groupId}/users`);
    dispatch(getGroupUsersSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getGroupUsersFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getGroupPermissionsAction = (groupId) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/groups/${groupId}/permissions`);
    dispatch(getGroupPermissionsSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getGroupPermissionsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const createGroupAction = (payload) => async (dispatch) => {
  try {
    const { data } = await axios.post('/groups', payload);
    dispatch(createGroupSuccess());
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const editGroupAction = (payload, groupId) => async (dispatch) => {
  try {
    const { data } = await axios.patch(`/groups/${groupId}`, payload);
    dispatch(editGroupSuccess(data.data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const deleteGroupAction = (groupId) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`/groups/${groupId}`);
    dispatch(deleteGroupSuccess());
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const addUsersToGroupAction = (groupId, users) => async (dispatch) => {
  const modUsers = users.map((user) => user.user_id);

  try {
    const { data } = await axios.put(`/groups/${groupId}/users`, {
      users: modUsers,
    });
    dispatch(addUsersToGroupSuccess(data));

    return data;
  } catch (error) {
    dispatch(addUsersToGroupFailure(error?.response?.data));
    return errorHandler(error);
  }
};

export const addPermissionsToGroupAction = (groupId, permissions) => async (
  dispatch,
) => {
  const modPermissions = permissions.map((permission) => permission.value);

  try {
    const { data } = await axios.put(`/groups/${groupId}/permissions`, {
      permissions: modPermissions,
    });
    dispatch(addPermissionsToGroupSuccess(data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const removePermissionFromGroupAction = (
  groupId,
  permissionId,
) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`/groups/permissions/${permissionId}`, {
      data: { groupId },
    });
    dispatch(removePermissionFromGroupSuccess(data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const removeUserFromGroupAction = (groupId, userId) => async (
  dispatch,
) => {
  try {
    const { data } = await axios.delete(`/groups/users/${userId}`, {
      data: { groupId },
    });
    dispatch(removeUserFromGroupSuccess(data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const initialState = {
  isLoading: false,
  data: [],
  group: null,
  permissions: [],
  users: [],
  update: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_GROUPS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        group: action.payload,
      };
    case GET_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_GROUP_SUCCESS:
    case ADD_USERS_TO_GROUP_SUCCESS:
    case ADD_PERMISSIONS_TO_GROUP_SUCCESS:
    case CREATE_GROUP_SUCCESS:
    case REMOVE_PERMISSION_FROM_GROUP_SUCCESS:
    case REMOVE_USER_FROM_GROUP_SUCCESS:
      return {
        ...state,
        update: !state.update,
      };
    case EDIT_GROUP_SUCCESS:
      return {
        ...state,
        group: action.payload,
      };
    case GET_GROUP_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
      };
    case GET_GROUP_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
