export const REQUEST_ACTION = 'ibms-web/claims/REQUEST_ACTION';

export const CREATE_CLAIMS_SUCCESS =
  'ibms-web/claims/CREATE_CLAIMS_SUCCESS';

export const GET_CLAIMS_SUCCESS =
  'ibms-web/claims/GET_CLAIMS_SUCCESS';

export const GET_CLAIM_SUCCESS =
'ibms-web/claims/GET_CLAIM_SUCCESS';

export const CREATE_CLAIMS_FAILURE =
  'ibms-web/claims/CREATE_CLAIMS_FAILURE';

export const ADD_COMMENT_SUCCESS =
  'ibms-web/claims/ADD_COMMENT_SUCCESS';

export const EDIT_CLAIMS_SUCCESS =
  'ibms-web/claims/EDIT_CLAIMS_SUCCESS';
  



