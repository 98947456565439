export const PRODUCT_REQUEST_ACTION = 'ibms-web/product/PRODUCT_REQUEST_ACTION';
export const DELETE_PRODUCT_REQUEST_ACTION =
  'ibms-web/product/DELETE_PRODUCT_REQUEST_ACTION';
export const CREATE_PRODUCT_SUCCESS = 'ibms-web/product/CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'ibms-web/product/CREATE_PRODUCT_FAILURE';
export const UPDATE_PRODUCT_FAILURE = 'ibms-web/product/UPDATE_PRODUCT_FAILURE';
export const UPDATE_PRODUCT_SUCCESS = 'ibms-web/product/UPDATE_PRODUCT_SUCCESS';
export const GET_ALL_PRODUCTS_SUCCESS =
  'ibms-web/product/GET_ALL_PRODUCTS_SUCCESS';
export const GET_ALL_PRODUCTS_FAILURE =
  'ibms-web/product/GET_ALL_PRODUCTS_FAILURE';
export const GET_SINGLE_PRODUCT_SUCCESS =
  'ibms-web/product/GET_SINGLE_PRODUCT_SUCCESS';
export const GET_SINGLE_PRODUCT_FAILURE =
  'ibms-web/product/GET_SINGLE_PRODUCT_FAILURE';
export const DELETE_PRODUCT_COVER_SUCCESS =
  'ibms-web/product/DELETE_PRODUCT_COVER_SUCCESS';
export const DELETE_PRODUCT_COVER_FAILURE =
  'ibms-web/product/DELETE_PRODUCT_COVER_FAILURE';
export const DELETE_PRODUCT_SUCCESS = 'ibms-web/product/DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'ibms-web/product/DELETE_PRODUCT_FAILURE';
export const RESTORE_PRODUCT_START = 'ibms-web/product/RESTORE_PRODUCT_START';
export const RESTORE_PRODUCT_SUCCESS = 'ibms-web/product/RESTORE_PRODUCT_SUCCESS';
export const RESTORE_PRODUCT_FAILURE = 'ibms-web/product/RESTORE_PRODUCT_FAILURE';
