import { errorHandler } from '../../../utils';
import axios from '../../../utils/http';
import {
  REQUEST_ACTION,
  CREATE_CLAIMS_SUCCESS,
  CREATE_CLAIMS_FAILURE,
  GET_CLAIMS_SUCCESS,
  GET_CLAIM_SUCCESS,
  ADD_COMMENT_SUCCESS,
  EDIT_CLAIMS_SUCCESS,
} from './actionTypes';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const createClaims = (payload) => ({
  type: CREATE_CLAIMS_SUCCESS,
  payload,
});

export const editClaims = (payload) => ({
  type: EDIT_CLAIMS_SUCCESS,
  payload,
});

export const getClaims = (payload) => ({
  type: GET_CLAIMS_SUCCESS,
  payload,
});

export const getClaim = (payload) => ({
  type: GET_CLAIM_SUCCESS,
  payload,
});

export const addComment = (payload) => ({
  type: ADD_COMMENT_SUCCESS,
  payload,
});

export const createClaimsFailure = () => ({
  type: CREATE_CLAIMS_FAILURE,
});

export const createClaimAction = (claimsPayload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('/claims', claimsPayload);
    dispatch(createClaims(data));

    return data;
  } catch (error) {
    dispatch(createClaimsFailure());
    return errorHandler(error);
  }
};

export const editClaimAction = (claimsPayload, id) => async (dispatch) => {
  try {
    const { data } = await axios.patch(`/claims/${id}`, claimsPayload);
    dispatch(editClaims(data));

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const uploadClaimDocsAction = (claimsPayload, id) => async (
  dispatch,
) => {
  try {
    const { data } = await axios.post(`/claims/${id}/uploads`, claimsPayload);

    dispatch(editClaims(data));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const getClaimsAction = (limit, page) => async (dispatch) => {
  limit = limit ? `limit=${limit}` : '';
  page = page ? `&page=${page}` : '';

  try {
    const { data } = await axios.get(`/claims?${limit}${page}`);

    dispatch(getClaims(data));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

// export const getSettledClaimsAction = (limit, page) => async (dispatch) => {
//   limit = limit ? `limit=${limit}` : '';
//   page = page ? `&page=${page}` : '';

//   try {
//     const { data } = await axios.get(`/claims/settleBroker?${limit}${page}`);

//     dispatch(getClaims(data));
//     return data;
//   } catch (error) {
//     return errorHandler(error);
//   }
// };

export const getClaimAction = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/claims/${id}`);

    dispatch(getClaim(data));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const addCommentAction = (text, id) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/claims/${id}/comment`, {
      comment: text,
    });

    dispatch(addComment(data));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const sendNotificationAction = (id, message) => async (dispatch) => {
  const event = message && message.trim() ? `?event=${message.trim()}` : '';

  try {
    const { data } = await axios.post(`/claims/${id}/notifications${event}`);

    dispatch(getClaim(data));
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const initialState = {
  data: null,
  claims: null,
  claim: null,
  isLoading: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_CLAIMS_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false,
      };
    case EDIT_CLAIMS_SUCCESS:
      return {
        ...state,
        claim: payload,
      };
    case GET_CLAIMS_SUCCESS:
      return {
        ...state,
        claims: payload,
      };
    case GET_CLAIM_SUCCESS:
      return {
        ...state,
        claim: payload,
      };
    case CREATE_CLAIMS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_COMMENT_SUCCESS:
      return {
        ...state,
        claim: {
          ...state.claim,
          data: {
            ...state.claim.data,
            ClaimsComments: [...state.claim.data.ClaimsComments, payload.data],
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
