import {
  REQUEST_ACTION,
  GET_ACCOUNTS_FAILURE,
  START_GETTING_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_FAILURE,
  START_CREATING_ACCOUNT,
  UPDATE_LIST_OF_ACCOUNT,
  PATCH_ACCOUNT_SUCCESS,
  PATCH_ACCOUNT_FAILURE,
  START_PATCH_ACCOUNT,
  START_DELETING_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  GET_SINGLE_ACCOUNTS_SUCCESS,
  GET_SINGLE_ACCOUNTS_FAILURE,
  GET_TRIAL_BALANCE_SUCCESS,
  GET_TRIAL_BALANCE_FAILURE,
  GET_BALANCE_SHEET_SUCCESS,
  GET_BALANCE_SHEET_FAILURE,
  GET_INCOME_STATEMENT_SUCCESS,
  GET_INCOME_STATEMENT_FAILURE,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const startGetAccounts = () => {
  return {
    type: START_GETTING_ACCOUNTS,
    gettingAccounts: true,
  };
};

export const getAccountsSuccess = (response) => {
  return {
    type: GET_ACCOUNTS_SUCCESS,
    response,
    gettingAccounts: false,
  };
};

export const getAccountsFail = (error) => ({
  type: GET_ACCOUNTS_FAILURE,
  error,
  gettingAccounts: false,
});

export const startCreateAccount = () => ({
  type: START_CREATING_ACCOUNT,
  creatingAccount: true,
});

export const createAccountSuccess = (payload) => ({
  type: CREATE_ACCOUNT_SUCCESS,
  payload,
  creatingAccount: false,
});

export const createAccountFailure = (error) => ({
  type: CREATE_ACCOUNT_FAILURE,
  error,
  creatingAccount: false,
});

export const startDeleteAccount = () => ({
  type: START_DELETING_ACCOUNT,
  deletingAccount: true,
});

export const deleteAccountSuccess = (payload) => ({
  type: DELETE_ACCOUNT_SUCCESS,
  deletingAccount: false,
  payload,
});

export const deleteAccountFailure = (error) => ({
  type: DELETE_ACCOUNT_FAILURE,
  deletingAccount: false,
  error,
});

export const startPatchAccount = () => ({
  type: START_PATCH_ACCOUNT,
  patchingAccount: true,
});

export const patchAccountSuccess = (payload) => ({
  type: PATCH_ACCOUNT_SUCCESS,
  patchingAccount: false,
  payload,
});

export const patchAccountFailure = (error) => ({
  type: PATCH_ACCOUNT_FAILURE,
  patchingAccount: false,
  error,
});

export const singleAccountSuccess = (payload) => ({
  type: GET_SINGLE_ACCOUNTS_SUCCESS,
  payload,
});

export const singleAccountFailure = (error) => ({
  type: GET_SINGLE_ACCOUNTS_FAILURE,
  error,
});

export const trialBalanceSuccess = (payload) => ({
  type: GET_TRIAL_BALANCE_SUCCESS,
  payload,
});

export const trialBalanceFailure = (error) => ({
  type: GET_TRIAL_BALANCE_FAILURE,
  error,
});

export const balanceSheetSuccess = (payload) => ({
  type: GET_BALANCE_SHEET_SUCCESS,
  payload,
});

export const balanceSheetFailure = (error) => ({
  type: GET_BALANCE_SHEET_FAILURE,
  error,
});

export const incomeStatementSuccess = (payload) => ({
  type: GET_INCOME_STATEMENT_SUCCESS,
  payload,
});

export const incomeStatementFailure = (error) => ({
  type: GET_INCOME_STATEMENT_FAILURE,
  error,
});

export const addAccountOrSubAccountAction = (payload) => async (dispatch) => {
  dispatch(startCreateAccount());

  try {
    const { data } = await axios.post('/accounting/accounts', payload);
    dispatch(createAccountSuccess(payload));

    return data;
  } catch (error) {
    dispatch(createAccountFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const updateListOfAccountAction = (type, oldData, newData) => (
  dispatch,
) => {
  switch (type) {
    case 'add':
      return dispatch({
        type: UPDATE_LIST_OF_ACCOUNT,
        data: [...oldData, newData],
      });
    case 'update':
      const index = oldData.findIndex(
        (item) => item.account_code_pk === newData.accountId,
      );
      const updateData = [
        ...oldData.slice(0, index),
        { ...oldData[index], ...newData },
        ...oldData.slice(index + 1),
      ];
      return dispatch({
        type: UPDATE_LIST_OF_ACCOUNT,
        updateData,
      });
    case 'delete':
      const deleteData = oldData.filter((item) => {
        return Number(item.account_code_pk) !== Number(newData.account_code_pk);
      });
      return dispatch({
        type: UPDATE_LIST_OF_ACCOUNT,
        deleteData,
      });
    default:
      break;
  }
};

export const patchChartOfAccountAction = (payload, accountId) => async (
  dispatch,
) => {
  dispatch(startPatchAccount());

  try {
    const { data } = await axios.patch(
      `/accounting/accounts/${accountId}`,
      payload,
    );
    dispatch(patchAccountSuccess(data));

    return data;
  } catch (err) {
    dispatch(patchAccountFailure(err));

    return errorHandler(err);
  }
};

export const getAccountsAction = (accountTypeId) => async (dispatch) => {
  dispatch(startGetAccounts());
  try {
    const { data } = await axios.get(
      `/accounting/accounts?accountTypeId=${accountTypeId}`,
    );
    dispatch(getAccountsSuccess(data));

    return data;
  } catch (error) {
    dispatch(getAccountsFail({ error }));

    return errorHandler(error);
  }
};

export const deleteAccountAction = (accountId) => async (dispatch) => {
  dispatch(startDeleteAccount());
  try {
    const { data } = await axios.delete(`/accounting/accounts/${accountId}`);
    dispatch(deleteAccountSuccess(data));

    return data;
  } catch (error) {
    dispatch(deleteAccountFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getSingleAccountAction = (accountId) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(`/accounting/accounts/${accountId}`);
    dispatch(singleAccountSuccess(data));

    return data;
  } catch (error) {
    dispatch(singleAccountFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getTrialBalanceAction = (startDate, endDate) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    startDate = startDate ? `startDate=${startDate}` : '';
    endDate = endDate ? `&endDate=${endDate}` : '';

    const { data } = await axios.get(
      `/accounting/trial-balance?${startDate}${endDate}`,
    );
    dispatch(trialBalanceSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(trialBalanceFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getBalanceSheetAction = (endDate) => async (dispatch) => {
  dispatch(requestAction());
  try {
    endDate = endDate ? `endDate=${endDate}` : '';

    const { data } = await axios.get(`/accounting/balance-sheet?${endDate}`);
    dispatch(balanceSheetSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(balanceSheetFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getIncomeStatementAction = (startDate, endDate) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    startDate = startDate ? `startDate=${startDate}` : '';
    endDate = endDate ? `&endDate=${endDate}` : '';

    const { data } = await axios.get(
      `/accounting/income-statement?${startDate}${endDate}`,
    );
    dispatch(incomeStatementSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(incomeStatementFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const initialState = {
  response: null,
  updatedData: null,
  patchingAccount: false,
  gettingAccounts: false,
  deletingAccount: false,
  creatingAccount: false,
  error: null,
  reload: true,
  isLoading: false,
  sinleAccount: null,
  trialBalanceData: [],
  balanceSheetData: [],
  incomeStatementData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case START_GETTING_ACCOUNTS:
      return {
        ...state,
        gettingAccounts: true,
      };
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        response: action.response && action.response.data,
        gettingAccounts: false,
      };
    case GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.error,
        gettingAccounts: false,
      };

    case UPDATE_LIST_OF_ACCOUNT:
      return {
        ...state,
        response: action.data,
        reload: !state.reload,
      };

    case START_CREATING_ACCOUNT:
      return {
        ...state,
        creatingAccount: true,
      };
    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        creatingAccount: false,
        createdAccount: action.payload,
      };
    case CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        creatingAccount: false,
        error: action.error,
      };

    case START_PATCH_ACCOUNT:
      return {
        ...state,
        patchingAccount: true,
      };
    case PATCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        patchingAccount: false,
        updatedData: action.payload.data,
      };
    case PATCH_ACCOUNT_FAILURE:
      return {
        ...state,
        patchingAccount: false,
        error: action.error,
      };
    case GET_SINGLE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        singleAccount: action.payload.data,
        isLoading: false,
      };
    case GET_SINGLE_ACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_TRIAL_BALANCE_SUCCESS:
      return {
        ...state,
        trialBalanceData: action.payload,
        isLoading: false,
      };
    case GET_TRIAL_BALANCE_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_BALANCE_SHEET_SUCCESS:
      return {
        ...state,
        balanceSheetData: action.payload,
        isLoading: false,
      };
    case GET_BALANCE_SHEET_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case GET_INCOME_STATEMENT_SUCCESS:
      return {
        ...state,
        incomeStatementData: action.payload,
        isLoading: false,
      };
    case GET_INCOME_STATEMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
