export const REQUEST_ACTION = 'ibms-web/transaction/REQUEST_ACTION';
export const GET_ACCOUNT_SUCCESS = 'ibms-web/transaction/GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAILURE = 'ibms-web/transaction/GET_ACCOUNT_FAILURE';

export const UPDATE_ACCOUNT_OPTION =
  'ibms-web/transaction/UPDATE_ACCOUNT_OPTION';

export const CREATE_TRANSACTION_FAILURE =
  'ibms-web/transaction/CREATE_TRANSACTION_FAILURE';

export const CREATE_TRANSACTION_SUCCESS =
  'ibms-web/transaction/CREATE_TRANSACTION_SUCCESS';

export const GET_TRANSACTION_RECORDS_SUCCESS =
  'ibms-web/transaction/GET_TRANSACTION_RECORDS_SUCCESS';

export const GET_TRANSACTION_RECORDS_FAILURE =
  'ibms-web/transaction/GET_TRANSACTION_RECORDS_FAILURE';

export const GET_SINGLE_RECORD_SUCCESS =
  'ibms-web/transaction/GET_SINGLE_RECORD_SUCCESS';

export const GET_SINGLE_RECORD_FAILURE =
  'ibms-web/transaction/GET_SINGLE_RECORD_FAILURE';

export const GET_ACCOUNT_TRANSACTION_SUCCESS =
  'ibms-web/transaction/GET_ACCOUNT_TRANSACTION_SUCCESS';

export const GET_ACCOUNT_TRANSACTION_FAILURE =
  'ibms-web/transaction/GET_ACCOUNT_TRANSACTION_FAILURE';

export const GET_CASH_FLOW_SUCCESS =
  'ibms-web/transaction/GET_CASH_FLOW_SUCCESS';

export const GET_CASH_FLOW_FAILURE =
  'ibms-web/transaction/GET_CASH_FLOW_FAILURE';

export const GET_CASH_FLOW_LOADING =
  'ibms-web/transaction/GET_CASH_FLOW_LOADING';
