import {
  REQUEST_ACTION,
  GET_PERMISSIONS_FAILURE,
  GET_PERMISSIONS_SUCCESS,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const getPermissionsSuccess = (payload) => ({
  type: GET_PERMISSIONS_SUCCESS,
  payload,
});

export const getPermissionsFailure = (payload) => ({
  type: GET_PERMISSIONS_FAILURE,
  payload,
});

export const getPermissionsAction = () => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get('/permissions');
    dispatch(getPermissionsSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getPermissionsFailure(error.response.data));

    return errorHandler(error);
  }
};

export const addPermissionToUsersAction = (users, permissionId) => async (
  dispatch,
) => {
  const modUsers = users.map((user) => user.user_id);

  try {
    const { data } = await axios.put(`/permissions/${permissionId}/users`, {
      users: modUsers,
    });

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const addPermissionToGroupsAction = (groups, permissionId) => async (
  dispatch,
) => {
  const modGroups = groups.map((group) => group.value);

  try {
    const { data } = await axios.put(`/permissions/${permissionId}/groups`, {
      groups: modGroups,
    });

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const initialState = {
  isLoading: false,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
