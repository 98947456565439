import {
  GETTING_ANALYTICS_DATA_FAILURE,
  GETTING_ANALYTICS_DATA_SUCCESS,
  START_GETTING_ANALYTICS_DATA,
} from './actionTypes';

import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const startGettingAnalyticsData = () => ({
  type: START_GETTING_ANALYTICS_DATA,
  gettingAnalyticsData: true,
});

export const getAnalyticsDataSuccess = (data) => ({
  type: GETTING_ANALYTICS_DATA_SUCCESS,
  data,
  gettingAnalyticsData: false,
});

export const getAnalyticsDataFailure = (error) => ({
  type: GETTING_ANALYTICS_DATA_FAILURE,
  error,
  gettingAnalyticsData: false,
});

export const fetchAnalyticsDataAction = (startDate, endDate) => async (
  dispatch,
) => {
  dispatch(startGettingAnalyticsData());

  try {
    const policyCountData = axios.get(
      `/count/policies?startDate=${startDate}&endDate=${endDate}`,
    );

    const sumAssuredData = axios.get(
      `/sumAssured/policies?startDate=${startDate}&endDate=${endDate}`,
    );

    const annualPremiumData = axios.get(
      `/fetch/underWrittenPremium?startDate=${startDate}&endDate=${endDate}`,
    );

    const comissionDueData = axios.get(
      `/accounting/fetch/comissionDue?startDate=${startDate}&endDate=${endDate}`,
    );

    const res = await Promise.all([
      policyCountData,
      sumAssuredData,
      annualPremiumData,
      comissionDueData,
    ]);

    const data = [
      { title: 'Policies', amount: res[0].data.data.policyCount },
      { title: 'Amount Assured', amount: res[1].data.data.sumAssured || 0 },
      { title: 'Annual Premium', amount: res[2].data.data.premiumUnderwritten },
      { title: 'Commission Due', amount: res[3].data.data.data.balance_after },
    ];

    dispatch(getAnalyticsDataSuccess(data));
    return data;
  } catch (err) {
    dispatch(getAnalyticsDataFailure(err));

    return errorHandler(err);
  }
};

export const initialState = {
  gettingAnalyticsData: false,
  error: null,
  data: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_GETTING_ANALYTICS_DATA:
      return {
        ...state,
        gettingAnalyticsData: true,
      };

    case GETTING_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        gettingAnalyticsData: false,
        data: action.data,
      };

    case GETTING_ANALYTICS_DATA_FAILURE:
      return {
        ...state,
        gettingAnalyticsData: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default reducer;
