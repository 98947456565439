import React, { useState, useEffect, useRef } from 'react';

const OptionsDropDown = ({ dropdownContent, dropDownIcon, className }) => {
  const [state, setState] = useState({ show: false });

  const toggleDropDown = (e) => {
    e.stopPropagation();
    setState({ show: !state.show });
  };

  const display = state.show ? 'block z-50' : 'hidden';

  /**
   * Hook that close popup clicks outside of the passed ref
   */
  const useClickOutside = (ref) => {
    useEffect(() => {
      // close the popup if clicked outside the div
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setState({ show: false });
        }
      };

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef);

  return (
    <div
      className={
        dropDownIcon
          ? 'relative'
          : 'flex justify-center items-center hover:bg-blue-300 rounded-full h-8 w-8 outline-none cursor-pointer'
      }
      ref={wrapperRef}
      onClick={toggleDropDown}
    >
      {dropDownIcon ? (
        dropDownIcon
      ) : (
        <span className="flex items-center w-8 justify-evenly">
          <span className="w-1 h-1 rounded-full bg-blue-850"></span>
          <span className="w-1 h-1 rounded-full bg-blue-850"></span>
          <span className="w-1 h-1 rounded-full bg-blue-850"></span>
        </span>
      )}
      <div
        className={`absolute top-2 bg-white mt-1 rounded-sm shadow-tn ${display} ${
          className ? className : 'right-0'
        }`}
        onClick={toggleDropDown}
      >
        {dropdownContent}
      </div>
    </div>
  );
};

export default OptionsDropDown;
