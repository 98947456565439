import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkUser } from '../utils/auth';
import { logOutUser } from '../store/modules/auth';
import AuthenticatedRoutes from './authenticatedRoutes';
import LazyScreenLoader from '../components/LazyScreenLoader';

const Login = lazy(() => import('../pages/Login'));
const About = lazy(() => import('../pages/About'));
const Home = lazy(() => import('../pages/LandingPage'));
const Landing = lazy(() => import('../components/GetStarted'));
const Register = lazy(() => import('../pages/Register'));
const Features = lazy(() => import('../pages/Features'));
const NotFoundPage = lazy(() => import('../pages/NotFound'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const Verification = lazy(() => import('../pages/SignUpVerification'));
const BrokerUrlUpdatePage = lazy(() => import('../pages/SetTenantUrl'));
const ChangePassword = lazy(() => import('../pages/ChangePassword'));
const PayStackRedirect = lazy(() => import('../pages/PaystackRedirect'));

const Routes = ({ signOut }) => {
  const host = window.location.hostname;

  return (
    <Suspense fallback={<LazyScreenLoader />}>
      <Switch>
        <Route exact path="/" component={checkUser(host) ? Home : Login} />
        <Route exact path="/get-started" component={Landing} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/broker-signup" component={Register} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/verify" component={Verification} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/broker-url" component={BrokerUrlUpdatePage} />
        <Route exact path="/auth/linkedin" component={Login} />
        <Route exact path="/auth/facebook" component={Login} />
        <Route exact path="/auth/google" component={Login} />
        <Route exact path="/auth/linkedin/register" component={Register} />
        <Route exact path="/auth/facebook/register" component={Register} />
        <Route exact path="/auth/google/register" component={Register} />
        <Route exact path="/features" component={Features} />
        <Route exact path="/paystack/redirect" component={PayStackRedirect} />
        <Route exact path="/about" component={About} />
        <Route path="/change-password" component={ChangePassword} />
        <AuthenticatedRoutes signOut={signOut} />
        <Route path="/*" component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signOut: (history) => dispatch(logOutUser(history)),
});

export default connect(null, mapDispatchToProps)(Routes);
