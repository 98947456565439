import {
  PRODUCT_REQUEST_ACTION,
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_SUCCESS,
  GET_ALL_PRODUCTS_SUCCESS,
  GET_ALL_PRODUCTS_FAILURE,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILURE,
  DELETE_PRODUCT_COVER_SUCCESS,
  DELETE_PRODUCT_COVER_FAILURE,
  RESTORE_PRODUCT_START,
  RESTORE_PRODUCT_SUCCESS,
  RESTORE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST_ACTION,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: PRODUCT_REQUEST_ACTION,
});

export const requestDeleteAction = () => ({
  type: DELETE_PRODUCT_REQUEST_ACTION,
});

export const createProduct = (payload) => ({
  type: CREATE_PRODUCT_SUCCESS,
  payload,
});

export const deleteProductCover = (payload) => ({
  type: DELETE_PRODUCT_COVER_SUCCESS,
  payload,
});

export const deleteProduct = () => ({
  type: DELETE_PRODUCT_SUCCESS,
  success: true,
});

export const deleteProductFailure = (error) => ({
  type: DELETE_PRODUCT_FAILURE,
  error,
});

export const deleteProductCoverFailure = (error) => ({
  type: DELETE_PRODUCT_COVER_FAILURE,
  error,
});

export const createProductFailure = (error) => ({
  type: CREATE_PRODUCT_FAILURE,
  payload: error,
});

export const updateProductFailure = (error) => ({
  type: UPDATE_PRODUCT_FAILURE,
  error,
});

export const updateProductSuccess = (payload) => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload,
});

export const getProducts = (payload) => ({
  type: GET_ALL_PRODUCTS_SUCCESS,
  payload,
});

export const getProductsFailure = (error) => ({
  type: GET_ALL_PRODUCTS_FAILURE,
  payload: error,
});

export const getSingleProduct = (payload) => ({
  type: GET_SINGLE_PRODUCT_SUCCESS,
  payload,
});

export const getSingleProductFailure = (error) => ({
  type: GET_SINGLE_PRODUCT_FAILURE,
  payload: error,
});

/**
 * Create a thunk which makes an API call to create insurance product
 *
 * @param {Object} payload
 * @param {string} insuranceCategory
 * @param {integer} quoteId
 *
 * @returns {Function}
 */
export const createProductAction = (payload) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.post('/product', payload);
    dispatch(createProduct(data));

    return data;
  } catch (error) {
    dispatch(createProductFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * @description - Create a thunk which makes an API call to delete insurance product
 * @param {integer} productId
 *
 * @returns {Function}
 */
export const deleteProductAction = (productId) => async (dispatch) => {
  dispatch(requestDeleteAction());
  try {
    const { data } = await axios.delete(`/product/${productId}`, {});
    dispatch(deleteProduct());

    return data;
  } catch (error) {
    dispatch(deleteProductFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to create insurance product
 *
 * @param {Object} payload
 * @param {string} insuranceCategory
 * @param {integer} quoteId
 *
 * @returns {Function}
 */
export const deleteProductCoverAction = (payload) => async (dispatch) => {
  try {
    const { productId, coverId } = payload;

    const { data } = await axios.delete(
      `/product/covers/${coverId}?productId=${productId}`,
      payload,
    );

    dispatch({
      type: DELETE_PRODUCT_COVER_SUCCESS,
      success: true,
    });

    return data;
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAILURE,
      error: error?.response?.data,
    });

    return errorHandler(error);
  }
};

export const updateProductAction = (
  payload,
  productId,
  isTravelProduct = false,
) => async (dispatch) => {
  dispatch(requestAction());
  try {
    const requestsArray = [];
    if (isTravelProduct) {
      const updateProductDataRequest = axios.put(`/product/${productId}`, {
        insuranceProviderId: payload.insuranceProviderId,
        insuranceCategoryId: payload.insuranceCategoryId,
        coverageType: payload.coverageType,
        productName: payload.productName,
        remark: payload.remark,
      });
      requestsArray.push(updateProductDataRequest);
      payload.insuranceTravelProducts.forEach(() => {
        requestsArray.push(
          axios.put(`/product/travel/${productId}`, { ...payload }),
          axios.put(`/product/covers/${productId}`, { ...payload }),
          payload.put(`/product/rules/${productId}`, { ...payload }),
          payload.put(`/product/limits/${productId}`, { ...payload }),
          payload.put(`/product/exceptions/${productId}`, { ...payload }),
        );
      });
      await Promise.all(requestsArray);
      dispatch(updateProductSuccess({ success: true }));
    } else {
      const updateProductDataRequest = axios.put(`/product/${productId}`, {
        insuranceProviderId: payload.insuranceProviderId,
        insuranceCategoryId: payload.insuranceCategoryId,
        coverageType: payload.coverageType,
        tenor: payload.tenor,
        tenorUnit: payload.tenorUnit,
        productName: payload.productName,
        remark: payload.remark,
        commissionType: payload.commissionType,
        commissionRate: payload.commissionRate,
      });

      requestsArray.push(updateProductDataRequest);

      if (payload.productRules) {
        const updateProductRulesDataRequest = axios.put(
          `/product/rules/${productId}`,
          {
            productRules: payload.productRules,
          },
        );
        requestsArray.push(updateProductRulesDataRequest);
      }

      if (payload.productLimits) {
        const updateProductLimitsDataRequest = axios.put(
          `/product/limits/${productId}`,
          {
            productLimits: payload.productLimits,
          },
        );
        requestsArray.push(updateProductLimitsDataRequest);
      }

      if (payload.productPolicyExceptions) {
        const updateProductPolicyExceptionRequest = axios.put(
          `/product/exceptions/${productId}`,
          {
            productPolicyExceptions: payload.productPolicyExceptions,
          },
        );
        requestsArray.push(updateProductPolicyExceptionRequest);
      }

      if (payload.premiumRates) {
        const updateProductPremiumRate = axios.put(
          `/product/premiumRate/${productId}`,
          { premiumRates: payload.premiumRates },
        );
        requestsArray.push(updateProductPremiumRate);
      }
      if (payload.covers) {
        const updateProductCovers = axios.put(`/product/covers/${productId}`, {
          covers: payload.covers,
        });
        requestsArray.push(updateProductCovers);
      }

      await Promise.all(requestsArray);
    }
    dispatch(updateProductSuccess({ success: true }));

    return { success: true };
  } catch (error) {
    dispatch(
      updateProductFailure({
        error: 'An error occured while updating product',
      }),
    );

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get all products
 *
 * @returns {Function}
 */
export const getProductsAction = (
  limit,
  page,
  underwriterId,
  lineOfBusinessId,
) => async (dispatch) => {
  dispatch(requestAction());
  try {
    limit = limit ? `limit=${limit}` : '';
    page = page ? `&page=${page}` : '';
    underwriterId = underwriterId ? `&underwriterId=${underwriterId}` : '';
    lineOfBusinessId = lineOfBusinessId
      ? `&lineOfBusinessId=${lineOfBusinessId}`
      : '';

    const { data } = await axios.get(
      `/product?${limit}${page}${underwriterId}${lineOfBusinessId}`,
    );

    dispatch(getProducts(data.data.allProducts));
    return { success: true, data };
  } catch (error) {
    dispatch(getProductsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get all products
 *
 * @returns {Function}
 */
export const getArchivedProductsAction = (limit, page) => async (dispatch) => {
  dispatch(requestAction());
  try {
    limit = limit ? `limit=${limit}` : '';
    page = page ? `&page=${page}` : '';
    const { data } = await axios.get(`/product/archive?${limit}${page}`);

    dispatch(getProducts(data.data.allArchivedInsuranceProducts));
    return { success: true, data };
  } catch (error) {
    dispatch(getProductsFailure(error?.response?.data));
    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get single products
 *
 * @param {integer} productId
 *
 * @returns {Function}
 */
export const getSingleProductAction = (productId, archive = false) => async (
  dispatch,
) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get(
      `/product/${productId}?archive=${archive}&detailed=${true}`,
    );

    dispatch(getSingleProduct(data.data.insuranceProduct));
    return { success: true, data };
  } catch (error) {
    dispatch(getProductsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

/**
 * Create a thunk which makes an API call to get single products
 *
 * @param {integer} productId
 *
 * @returns {Function}
 */
export const restoreProductAction = (productId) => async (dispatch) => {
  dispatch({ type: RESTORE_PRODUCT_START });
  try {
    const { data } = await axios.put(`/product/restore/${productId}`);

    return dispatch({
      type: RESTORE_PRODUCT_SUCCESS,
      payload: { data: data.data, success: true },
    });
  } catch (error) {
    if (!error.response) {
      return dispatch({
        type: RESTORE_PRODUCT_FAILURE,
        success: false,
        error: 'network error!',
      });
    }
    return dispatch({
      type: RESTORE_PRODUCT_FAILURE,
      success: false,
      error: error.response.data,
    });
  }
};

export const initialState = {
  data: {},
  productData: [],
  isProductLoading: false,
  isProductDeleting: false,
  restoringProduct: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_REQUEST_ACTION:
      return {
        ...state,
        isProductLoading: true,
      };
    case DELETE_PRODUCT_REQUEST_ACTION:
      return {
        ...state,
        isProductDeleting: true,
      };
    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isProductLoading: false,
      };
    case RESTORE_PRODUCT_START:
      return {
        ...state,
        restoringProduct: true,
      };
    case RESTORE_PRODUCT_SUCCESS:
      return {
        ...state,
        restoringProduct: false,
      };
    case RESTORE_PRODUCT_FAILURE:
      return {
        ...state,
        restoringProduct: false,
      };
    case CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        isProductLoading: false,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isProductLoading: false,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isProductDeleting: false,
        isProductLoading: false,
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.error,
        isProductDeleting: false,
        isProductLoading: false,
      };
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        isProductLoading: false,
      };
    case GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        productData: action.payload,
        isProductLoading: false,
      };
    case GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        isProductLoading: false,
      };
    case GET_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isProductLoading: false,
      };
    case GET_SINGLE_PRODUCT_FAILURE:
      return {
        ...state,
        isProductLoading: false,
      };
    case DELETE_PRODUCT_COVER_SUCCESS:
      return {
        ...state,
        productCover: action.payload,
      };
    case DELETE_PRODUCT_COVER_FAILURE:
      return {
        ...state,
        productCoverError: action.error,
      };
    default:
      return initialState;
  }
};

export default reducer;
