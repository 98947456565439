import {
  REQUEST_ACTION,
  GET_INSURANCE_PROVIDERS_SUCCESS,
  GET_INSURANCE_PROVIDERS_FAILURE,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const getInsuranceProvider = (payload) => ({
  type: GET_INSURANCE_PROVIDERS_SUCCESS,
  payload,
});

export const getInsuranceProviderFailure = (error) => ({
  type: GET_INSURANCE_PROVIDERS_FAILURE,
  payload: error,
});

export const getInsuranceProvidersAction = () => async (dispatch) => {
  dispatch(requestAction());
  try {
    const { data } = await axios.get('/insurance-providers/active?status=1');
    dispatch(getInsuranceProvider(data.data));

    return data;
  } catch (error) {
    dispatch(getInsuranceProviderFailure(error));

    return errorHandler(error);
  }
};

export const initialState = {
  data: [],
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INSURANCE_PROVIDERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_INSURANCE_PROVIDERS_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
