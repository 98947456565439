import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../utils/auth';
import {
  REQUEST_ACTION,
  GET_BROKER_USERS_SUCCESS,
  GET_BROKER_USERS_FAILURE,
  SEND_BROKER_INVITE_SUCCESS,
  SEND_BROKER_INVITE_FAILURE,
  GET_BROKER_USER_SUCCESS,
  GET_BROKER_USER_FAILURE,
  GET_USER_ROLES_FAILURE,
  GET_USER_ROLES_SUCCESS,
  USER_ROLES_REQUEST_ACTION,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_ROLES_FAILURE,
  ROLES_REQUEST_ACTION,
  ADD_ROLES_TO_USER_SUCCESS,
  ADD_ROLES_TO_USER_FAILURE,
  ADD_GROUPS_TO_USER_SUCCESS,
  ADD_GROUPS_TO_USER_FAILURE,
  REMOVE_ROLE_FROM_USER_SUCCESS,
  REMOVE_ROLE_FROM_USER_FAILURE,
  GET_USER_GROUPS_FAILURE,
  GET_USER_GROUPS_SUCCESS,
  GET_ALL_GROUPS_FAILURE,
  GET_ALL_GROUPS_SUCCESS,
  REMOVE_USER_FROM_GROUP_FAILURE,
  REMOVE_USER_FROM_GROUP_SUCCESS,
  GET_USER_PERMISSIONS_FAILURE,
  GET_USER_PERMISSIONS_SUCCESS,
  GET_ALL_PERMISSIONS_FAILURE,
  GET_ALL_PERMISSIONS_SUCCESS,
  ADD_PERMISSIONS_TO_USER_FAILURE,
  ADD_PERMISSIONS_TO_USER_SUCCESS,
  REMOVE_PERMISSION_FROM_USER_FAILURE,
  REMOVE_PERMISSION_FROM_USER_SUCCESS,
  CHANGE_USER_STATUS,
  DELETE_USER_SUCCESS,
  BRANCHES_REQUEST_ACTION,
  GET_ALL_BRANCHES_SUCCESS,
  GET_ALL_BRANCHES_FAILURE,
  USER_BRANCHES_REQUEST_ACTION,
  GET_USER_BRANCHES_FAILURE,
  GET_BRANCHES_SUCCESS,
  GET_BRANCHES_FAILURE,
  ADD_BRANCHES_TO_USER_SUCCESS,
  ADD_BRANCHES_TO_USER_FAILURE,
  GET_ALL_USER_BRANCHES_SUCCESS,
  GET_BRANCHES_REQUEST_ACTION,
} from './actionTypes';
import axios from '../../../utils/http';
import { errorHandler } from '../../../utils';

export const requestAction = () => ({
  type: REQUEST_ACTION,
});

export const userRolesRequestAction = () => ({
  type: USER_ROLES_REQUEST_ACTION,
});

export const rolesRequestAction = () => ({
  type: ROLES_REQUEST_ACTION,
});

export const branchesRequestAction = () => ({
  type: BRANCHES_REQUEST_ACTION,
});

export const userBranchesRequestAction = () => ({
  type: USER_BRANCHES_REQUEST_ACTION,
});

export const getBrokerUsersSuccess = (payload) => ({
  type: GET_BROKER_USERS_SUCCESS,
  payload,
});

export const getBrokerUsersFailure = (payload) => ({
  type: GET_BROKER_USERS_FAILURE,
  payload,
});

export const getBrokerUserSuccess = (payload) => ({
  type: GET_BROKER_USER_SUCCESS,
  payload,
});

export const getBrokerUserFailure = (payload) => ({
  type: GET_BROKER_USER_FAILURE,
  payload,
});

export const sendBrokerInviteSuccess = (payload) => ({
  type: SEND_BROKER_INVITE_SUCCESS,
  payload,
});

export const sendBrokerInviteFailure = (payload) => ({
  type: SEND_BROKER_INVITE_FAILURE,
  payload,
});

export const getUserRolesSuccess = (payload) => ({
  type: GET_USER_ROLES_SUCCESS,
  payload,
});

// export const getUserBranchSuccess = (payload) => ({
//   type: GET_USER_BRANCHES_SUCCESS,
//   payload,
// });

export const getUserRolesFailure = (payload) => ({
  type: GET_USER_ROLES_FAILURE,
  payload,
});

export const getUserGroupsSuccess = (payload) => ({
  type: GET_USER_GROUPS_SUCCESS,
  payload,
});

export const getUserGroupsFailure = (payload) => ({
  type: GET_USER_GROUPS_FAILURE,
  payload,
});

export const getUserPermissionsSuccess = (payload) => ({
  type: GET_USER_PERMISSIONS_SUCCESS,
  payload,
});

export const getUserPermissionsFailure = (payload) => ({
  type: GET_USER_PERMISSIONS_FAILURE,
  payload,
});

export const getAllRolesSuccess = (payload) => ({
  type: GET_ALL_ROLES_SUCCESS,
  payload,
});

export const getAllBranchesSuccess = (payload) => ({
  type: GET_ALL_BRANCHES_SUCCESS,
  payload,
});

export const getAllUserBranchesSuccess = (payload) => ({
  type: GET_ALL_USER_BRANCHES_SUCCESS,
  payload,
});

export const getAllRolesFailure = (payload) => ({
  type: GET_ALL_ROLES_FAILURE,
  payload,
});

export const getAllBranchesFailure = (payload) => ({
  type: GET_ALL_BRANCHES_FAILURE,
  payload,
});

export const getAllPermissionsSuccess = (payload) => ({
  type: GET_ALL_PERMISSIONS_SUCCESS,
  payload,
});

export const getAllPermissionsFailure = (payload) => ({
  type: GET_ALL_PERMISSIONS_FAILURE,
  payload,
});

export const getAllGroupsSuccess = (payload) => ({
  type: GET_ALL_GROUPS_SUCCESS,
  payload,
});

export const getAllGroupsFailure = (payload) => ({
  type: GET_ALL_GROUPS_FAILURE,
  payload,
});

export const addRolesToUserFailure = (payload) => ({
  type: ADD_ROLES_TO_USER_FAILURE,
  payload,
});

export const addRolesToUserSuccess = (payload) => ({
  type: ADD_ROLES_TO_USER_SUCCESS,
  payload,
});

export const addBranchesToUserSuccess = (payload) => ({
  type: ADD_BRANCHES_TO_USER_SUCCESS,
  payload,
});

export const addBranchesToUserFailure = (payload) => ({
  type: ADD_BRANCHES_TO_USER_FAILURE,
  payload,
});

export const addPermissionsToUserFailure = (payload) => ({
  type: ADD_PERMISSIONS_TO_USER_FAILURE,
  payload,
});

export const addPermissionsToUserSuccess = (payload) => ({
  type: ADD_PERMISSIONS_TO_USER_SUCCESS,
  payload,
});

export const addGroupsToUserFailure = (payload) => ({
  type: ADD_GROUPS_TO_USER_FAILURE,
  payload,
});

export const addGroupsToUserSuccess = (payload) => ({
  type: ADD_GROUPS_TO_USER_SUCCESS,
  payload,
});

export const removeRoleFromUserFailure = (payload) => ({
  type: REMOVE_ROLE_FROM_USER_FAILURE,
  payload,
});

export const removeRoleFromUserSuccess = (payload) => ({
  type: REMOVE_ROLE_FROM_USER_SUCCESS,
  payload,
});

export const removePermissionFromUserFailure = (payload) => ({
  type: REMOVE_PERMISSION_FROM_USER_FAILURE,
  payload,
});

export const removePermissionFromUserSuccess = (payload) => ({
  type: REMOVE_PERMISSION_FROM_USER_SUCCESS,
  payload,
});

export const removeUserFromGroupFailure = (payload) => ({
  type: REMOVE_USER_FROM_GROUP_FAILURE,
  payload,
});

export const removeUserFromGroupSuccess = (payload) => ({
  type: REMOVE_USER_FROM_GROUP_SUCCESS,
  payload,
});

export const changeUserStatusSuccess = (payload) => ({
  type: CHANGE_USER_STATUS,
  payload,
});

export const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
});

export const getBrokerUsersAction = () => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get('/user');
    dispatch(getBrokerUsersSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getBrokerUsersFailure(error?.response?.data));
    return errorHandler(error);
  }
};

export const getBrokerUserAction = (userId) => async (dispatch) => {
  dispatch(requestAction());

  try {
    const { data } = await axios.get(`/user/${userId}`);
    dispatch(getBrokerUserSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getBrokerUserFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const createBrokerUserAction = (payload) => async (dispatch) => {
  try {
    const { data } = await axios.post('/user', payload);
    dispatch(sendBrokerInviteSuccess(data));

    return data;
  } catch (error) {
    dispatch(getBrokerUsersFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getUserRolesAction = (userId) => async (dispatch) => {
  dispatch(userRolesRequestAction());

  try {
    const { data } = await axios.get(`/user/role/${userId}`);
    dispatch(getUserRolesSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getUserRolesFailure(error?.response?.data));

    return errorHandler(error);
  }
};

// export const getUserBranchesAction = (userId) => async (dispatch) => {
//   dispatch(userBranchesRequestAction());

//   try {
//     const { data } = await axios.get(`/broker/list/branch/${userId}`);
//     dispatch(getUserBranchSuccess(data.data));

//     return data;
//   } catch (error) {
//     dispatch(getUserRolesFailure(error?.response?.data));

//     return errorHandler(error);
//   }
// };

// export const getUserBranchAc = (userId) => async (dispatch) => {
//   dispatch(userRolesRequestAction());

//   try {
//     const { data } = await axios.get(`/user/role/${userId}`);
//     dispatch(getUserRolesSuccess(data.data));

//     return data;
//   } catch (error) {
//     dispatch(getUserRolesFailure(error?.response?.data));

//     return errorHandler(error);
//   }
// };

export const getUserGroupsAction = (userId) => async (dispatch) => {
  dispatch(userRolesRequestAction());

  try {
    const { data } = await axios.get(`/user/group/${userId}`);
    dispatch(getUserGroupsSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getUserGroupsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getUserPermissionsAction = (userId) => async (dispatch) => {
  dispatch(userRolesRequestAction());

  try {
    const { data } = await axios.get(`/user/permission/${userId}`);
    dispatch(getUserPermissionsSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getUserPermissionsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getAllRolesAction = () => async (dispatch) => {
  dispatch(rolesRequestAction());

  try {
    const { data } = await axios.get('/roles');
    dispatch(getAllRolesSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getAllRolesFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getAllBranchesAction = () => async (dispatch) => {
  dispatch(branchesRequestAction());

  try {
    const { data } = await axios.get('/broker/branch');
    dispatch(getAllBranchesSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(
      getAllBranchesFailure(error?.response?.data || 'An error occured'),
    );

    return errorHandler(error);
  }
};

export const getAllUserBranchesAction = (userId) => async (dispatch) => {
  dispatch(userBranchesRequestAction());

  try {
    const { data } = await axios.get(`/broker/list/branch/${userId}`);
    dispatch(getAllUserBranchesSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(
      getAllBranchesFailure(error?.response?.data || 'An error occured'),
    );

    return errorHandler(error);
  }
};

export const getAllGroupsAction = () => async (dispatch) => {
  dispatch(rolesRequestAction());

  try {
    const { data } = await axios.get('/groups');
    dispatch(getAllGroupsSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getAllGroupsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getAllPermissionsAction = () => async (dispatch) => {
  dispatch(rolesRequestAction());

  try {
    const { data } = await axios.get('/permissions');
    dispatch(getAllPermissionsSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(getAllPermissionsFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const addRolesToUserAction = (payload, userId) => async (dispatch) => {
  const roles = payload.map((role) => role.value);

  try {
    const { data } = await axios.put(`/roles/user/${userId}`, { roles });
    dispatch(addRolesToUserSuccess(data.data));
    toast.success('Roles successfully assigned to user');
    return data;
  } catch (error) {
    dispatch(addRolesToUserFailure(error?.response?.data));
    return errorHandler(error);
  }
};

export const addUsersToBranchAction = (payload, userId) => async (dispatch) => {
  try {
    const { data } = await axios.post(`/broker/branch/assign/${userId}`, {
      branches: payload.map((item) => ({
        branchId: item.value,
      })),
    });
    dispatch(addBranchesToUserSuccess(data.data));
    toast.success('Branches successfully assigned to user');
    return data;
  } catch (error) {
    dispatch(addBranchesToUserFailure(error?.response?.data));
    return errorHandler(error);
  }
};

export const addPermissionsToUserAction = (payload, userId) => async (
  dispatch,
) => {
  const permissions = payload.map((permission) => permission.value);

  try {
    const { data } = await axios.put(`/permissions/user/${userId}`, {
      permissions,
    });
    dispatch(addPermissionsToUserSuccess(data.data));
    toast.success('Permissions successfully assigned to user');
    return data;
  } catch (error) {
    dispatch(addPermissionsToUserFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const addGroupsToUserAction = (payload, userId) => async (dispatch) => {
  const groups = payload.map((group) => group.value);

  try {
    const { data } = await axios.put(`/groups/user/${userId}`, { groups });
    dispatch(addGroupsToUserSuccess(data.data));
    toast.success('Group successfully assigned to user');
    return data;
  } catch (error) {
    dispatch(addGroupsToUserFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const removeRoleFromUserAction = (roleId, userId) => async (
  dispatch,
) => {
  try {
    const { data } = await axios.delete(`/roles/user/${userId}`, {
      data: { roleId },
    });
    dispatch(removeRoleFromUserSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(removeRoleFromUserFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const removePermissionFromUserAction = (permissionId, userId) => async (
  dispatch,
) => {
  try {
    const { data } = await axios.delete(`/permissions/user/${userId}`, {
      data: { permissionId },
    });
    dispatch(removePermissionFromUserSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(removePermissionFromUserFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const getBranchesRequestAction = () => ({
  type: GET_BRANCHES_REQUEST_ACTION,
});

export const getBranchesSuccess = (payload) => ({
  type: GET_BRANCHES_SUCCESS,
  payload,
});

export const getBranchesFailure = (payload) => ({
  type: GET_BRANCHES_FAILURE,
  payload,
});

export const getBranchesAction = () => async (dispatch) => {
  dispatch(getBranchesRequestAction());
  try {
    const { data } = await axios.get('/broker/branch');
    dispatch(getBranchesSuccess(data.data));
    return data;
  } catch (error) {
    dispatch(getBranchesFailure(error?.response?.data));
    return errorHandler(error);
  }
};

export const removeUserFromGroupAction = (groupId, userId) => async (
  dispatch,
) => {
  try {
    const { data } = await axios.delete(`/groups/user/${userId}`, {
      data: { groupId },
    });
    dispatch(removeUserFromGroupSuccess(data.data));

    return data;
  } catch (error) {
    dispatch(removeUserFromGroupFailure(error?.response?.data));

    return errorHandler(error);
  }
};

export const changeUserStatusAction = (suspended, userId, reload) => async (
  dispatch,
) => {
  try {
    const { data } = await axios.patch(`/auth/user/${userId}`, { suspended });
    if (reload) dispatch(changeUserStatusSuccess());

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const deleteUserAction = (userId, redirect) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`/user/${userId}`);
    if (!redirect) dispatch(deleteUserSuccess());

    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const initialState = {
  users: [],
  user: null,
  isLoading: false,
  userRolesIsLoading: false,
  rolesIsLoading: false,
  branchesIsLoading: false,
  userBranchesIsLoading: false,
  userRoles: [],
  userGroups: [],
  userPermissions: [],
  roles: [],
  branches: [],
  userBranches: [],
  groups: [],
  permissions: [],
  update: false, // dummy state to force update components
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case USER_ROLES_REQUEST_ACTION:
      return {
        ...state,
        userRolesIsLoading: true,
      };
    case GET_ALL_USER_BRANCHES_SUCCESS:
      return {
        ...state,
        userBranchesIsLoading: false,
        userBranches: action.payload,
      };
    case BRANCHES_REQUEST_ACTION:
      return {
        ...state,
        branchesIsLoading: true,
      };
    case USER_BRANCHES_REQUEST_ACTION:
      return {
        ...state,
        userBranchesIsLoading: true,
      };
    case ROLES_REQUEST_ACTION:
      return {
        ...state,
        rolesIsLoading: true,
      };
    case GET_BROKER_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    case GET_BROKER_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case GET_BROKER_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    case GET_BROKER_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_BROKER_INVITE_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.data],
      };
    case SEND_BROKER_INVITE_FAILURE:
      return {
        ...state,
      };
    case GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        userRolesIsLoading: false,
        userRoles: action.payload,
      };
    case GET_USER_ROLES_FAILURE:
      return {
        ...state,
        userRolesIsLoading: false,
      };
    case GET_USER_BRANCHES_FAILURE:
      return {
        ...state,
        userBranchesIsLoading: false,
      };
    case GET_BRANCHES_SUCCESS:
      return {
        ...state,
        branchesIsLoading: false,
        branches: action.payload,
      };
    case GET_BRANCHES_FAILURE:
      return {
        ...state,
        branchesIsLoading: false,
      };

    case ADD_BRANCHES_TO_USER_SUCCESS:
      return {
        ...state,
        update: !state.update,
      };

    case GET_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        userRolesIsLoading: false,
        userPermissions: action.payload,
      };
    case GET_USER_PERMISSIONS_FAILURE:
      return {
        ...state,
        userRolesIsLoading: false,
      };
    case GET_USER_GROUPS_SUCCESS:
      return {
        ...state,
        userRolesIsLoading: false,
        userGroups: action.payload,
      };
    case GET_USER_GROUPS_FAILURE:
      return {
        ...state,
        userRolesIsLoading: false,
      };
    case GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        rolesIsLoading: false,
        roles: action.payload,
      };
    case GET_ALL_BRANCHES_SUCCESS:
      return {
        ...state,
        branchesIsLoading: false,
        branches: action.payload,
      };
    case GET_ALL_ROLES_FAILURE:
      return {
        ...state,
        rolesIsLoading: false,
      };
    case GET_ALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        rolesIsLoading: false,
        permissions: action.payload,
      };
    case GET_ALL_PERMISSIONS_FAILURE:
      return {
        ...state,
        rolesIsLoading: false,
      };
    case GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        rolesIsLoading: false,
        groups: action.payload,
      };
    case GET_ALL_GROUPS_FAILURE:
      return {
        ...state,
        rolesIsLoading: false,
      };
    case DELETE_USER_SUCCESS:
    case REMOVE_ROLE_FROM_USER_SUCCESS:
    case ADD_GROUPS_TO_USER_SUCCESS:
    case ADD_PERMISSIONS_TO_USER_SUCCESS:
    case CHANGE_USER_STATUS:
    case ADD_ROLES_TO_USER_SUCCESS:
    case REMOVE_PERMISSION_FROM_USER_SUCCESS:
    case REMOVE_USER_FROM_GROUP_SUCCESS:
      return {
        ...state,
        update: !state.update,
      };
    default:
      return state;
  }
};

export default reducer;
