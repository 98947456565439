import { format } from 'date-fns';

const formatNumber = (number) => `0${number}`.slice(-2);

/**
 * @description - Formats the value of the argument which should be in seconds to its equivalent minutes and seconds
 * @param {Number} time - the number of seconds the function will split into minutes and seconds
 * @returns {Object} - formatted value of time in mins and seconds
 */
export const formatTime = (time) => {
  const mins = Math.floor(time / 60);
  const secs = time - mins * 60;
  return { mins: formatNumber(mins), secs: formatNumber(secs) };
};

export const formatDate = (date) => {
  return format(new Date(date), 'yyyy-MM-dd');
};

/**
 * @description - this function subtracts the number of days from the date argument
 * @param {string} date - This is the date to subtract number of days from
 * @param {Number} durationInDays - the number of days to subtract from date
 * @returns {string} - returns string after computation of subtraction is done
 */
export const calculateDateDifference = (date, durationInDays) => {
  const dateObj = new Date(date);
  const dateNumber = dateObj.getDate();
  dateObj.setDate(dateNumber - durationInDays);
  return formatDate(dateObj);
};

export const timeSince = (date) => {
  if (!date) {
    return 'Never';
  }

  const delta = new Date() - new Date(date);
  const seconds = Math.floor(delta * 0.001);

  if (seconds <= 59) {
    return `${seconds}s ago`;
  }

  if (seconds <= 3599) {
    const minutes = Math.floor(seconds * 0.0166667);
    return `${minutes}m(s) ago`;
  }

  if (seconds <= 86400) {
    const hours = Math.floor(seconds * 0.000277778);
    return `${hours}h(s) ago`;
  }

  if (seconds <= 604800) {
    const days = Math.floor(seconds * 0.00001157407);
    return `${days}d(s) ago`;
  }

  if (seconds <= 2.628e6) {
    const weeks = Math.floor(seconds * 0.00000165357);
    return `${weeks}wk(s) ago`;
  }

  if (seconds < 3.154e7) {
    const months = Math.floor(seconds * 3.8052e-7);
    return `${months}mn(s) ago`;
  }

  const years = Math.floor(seconds * 3.171e-8);
  return `${years}yr(s) ago`;
};
