import React from 'react';
import { MoonLoader } from 'react-spinners';

// page loader
export const PageLoader = () => (
  <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full h-full bg-black opacity-75">
    <MoonLoader size={40} color={'#0396A6'} loading />
  </div>
);

// component loader
export const ComponentLoader = ({ loader, text }) => (
  <div className="h-full">
    <div className="flex flex-col justify-center items-center h-full">
      {loader || <MoonLoader size={30} color={'#0396A6'} loading />}
      <p className="font-sans text-sm text-center">
        {text || 'Loading Data ...'}
      </p>
    </div>
  </div>
);
