import React, { useRef, useState } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import './datePicker.css';
import calendarIcon from '../../assets/icons/calendar.svg';

const DatesPicker = (props) => {
  const ref = useRef();
  const [blur, setBlur] = useState(false);
  const [active, setActive] = useState(false);

  const handleBlur = () => {
    if (!props.value || !props.value.length) {
      props.required &&
        props.setErrors &&
        props.setErrors({ [props.name]: 'This field is required' });
    }
    setActive(false);
    setBlur(true);
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="relative">
      <input
        className={`w-full border-2 h-12 rounded-md pt-5 pb-2 pl-2 pr-8 mx-0 m-0 text-gray-700 text-sm leading-tight focus:outline-none ${
          props.errorMessage && (blur || props.value)
            ? 'border-red-500'
            : 'focus:border-blue-750 border-gray-350'
        } border-solid placeholder-gray-450 focus:placeholder-gray-700`}
        value={value}
        onClick={() => {
          setActive(true);
          onClick();
        }}
        name={props.placeholder}
        readOnly
        disabled={props.isDisabled}
      />
      <label
        htmlFor={props.placeholder}
        className={`absolute left-0.62 w-10/12 text-elipsis text-gray-450 whitespace-no-wrap overflow-hidden pointer-events-none labelTrans 
        ${
          active || props.value
            ? 'top-0.4 text-xs font-bold'
            : 'text-sm top-0.9'
        }`}
      >
        {props.placeholder}
      </label>
      <img
        src={calendarIcon}
        alt=""
        className="w-4 absolute bottom-1 right-0.75"
      />
    </div>
  );

  return (
    <div className={`relative ${props.className}`}>
      <DatePicker
        {...props}
        selected={props.selected}
        onChange={props.onChange}
        onClickOutside={handleBlur}
        customInput={<CustomInput />}
        customInputRef={ref}
      />
      {blur || props.value ? (
        <p className="mt-1 text-xs text-red-500">{props.errorMessage}</p>
      ) : null}
    </div>
  );
};

export default DatesPicker;
