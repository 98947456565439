export const REQUEST_ACTION = 'ibms-web/groups/REQUEST_ACTION';
export const GET_GROUPS_SUCCESS =
'ibms-web/groups/GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE =
'ibms-web/groups/GET_GROUPS_FAILURE';
export const GET_GROUP_SUCCESS =
'ibms-web/groups/GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE =
'ibms-web/groups/GET_GROUP_FAILURE';
export const GET_GROUP_PERMISSIONS_SUCCESS =
'ibms-web/groups/GET_GROUP_PERMISSIONS_SUCCESS';
export const GET_GROUP_PERMISSIONS_FAILURE =
'ibms-web/groups/GET_GROUP_PERMISSIONS_FAILURE';
export const CREATE_GROUP_SUCCESS =
'ibms-web/groups/CREATE_GROUP_SUCCESS';
export const EDIT_GROUP_SUCCESS =
'ibms-web/groups/EDIT_GROUP_SUCCESS';
export const DELETE_GROUP_SUCCESS =
'ibms-web/groups/DELETE_GROUP_SUCCESS';
export const ADD_USERS_TO_GROUP_SUCCESS =
'ibms-web/groups/ADD_USERS_TO_GROUP_SUCCESS';
export const ADD_USERS_TO_GROUP_FAILURE =
'ibms-web/groups/ADD_USERS_TO_GROUP_FAILURE';
export const ADD_PERMISSIONS_TO_GROUP_SUCCESS =
'ibms-web/groups/ADD_PERMISSIONS_TO_GROUP_SUCCESS';
export const REMOVE_PERMISSION_FROM_GROUP_SUCCESS =
'ibms-web/groups/REMOVE_PERMISSION_FROM_GROUP_SUCCESS';
export const REMOVE_USER_FROM_GROUP_SUCCESS =
'ibms-web/groups/REMOVE_USER_FROM_GROUP_SUCCESS';
export const GET_GROUP_USERS_FAILURE =
'ibms-web/groups/GET_GROUP_USERS_FAILURE';
export const GET_GROUP_USERS_SUCCESS =
'ibms-web/groups/GET_GROUP_USERS_SUCCESS';



