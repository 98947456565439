export const REQUEST_ACTION = 'ibms-web/roles/REQUEST_ACTION';
export const GET_ROLES_SUCCESS =
'ibms-web/roles/GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE =
'ibms-web/roles/GET_ROLES_FAILURE';
export const GET_ROLE_PERMISSIONS_SUCCESS =
'ibms-web/roles/GET_ROLE_PERMISSIONS_SUCCESS';
export const GET_ROLE_PERMISSIONS_FAILURE =
'ibms-web/roles/GET_ROLE_PERMISSIONS_FAILURE';
export const GET_ROLE_USERS_SUCCESS =
'ibms-web/roles/GET_ROLE_USERS_SUCCESS';
export const GET_ROLE_USERS_FAILURE =
'ibms-web/roles/GET_ROLE_USERS_FAILURE';
export const ADD_USERS_TO_ROLE_SUCCESS =
'ibms-web/roles/ADD_USERS_TO_ROLE_SUCCESS';
export const ADD_USERS_TO_ROLE_FAILURE =
'ibms-web/roles/ADD_USERS_TO_ROLE_FAILURE';


