/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchBox from '../SearchBox';
import UserAvatar from '../UserAvatar';
import capitalize from '../../utils/capitalize';
import {
  getUserDetailsAction,
  getUserPermissionsAction,
} from '../../store/modules/user';
import { logOutUser } from '../../store/modules/auth';
import {
  getNotificationAction,
  updateNotificationAction,
} from '../../store/modules/notify';
import { decodedToken } from '../../utils/auth';
import useErrorHandler from '../hooks/useErrorHandler';

const TopNavBar = (props) => {
  const {
    getProfile,
    userDetails,
    signOut,
    history,
    avatar,
    getNotification,
    removeNotification,
    getUserPermission,
    notification,
  } = props;

  const [dropDownState, setDropDownState] = useState(true);
  const [notificationState, setNotificationState] = useState(true);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [permission, setPermission] = useState();
  const [editProfilePermission, setEditProfilePermission] = useState(false);
  const [isAgent, setIsAgent] = useState(false);

  const { handleReqError } = useErrorHandler();

  useEffect(() => {
    const token = localStorage.getItem('ibmsToken');
    const tokenData = token ? decodedToken(token) : null;
    const checkAgent = tokenData && tokenData.userType === 'agent';
    if (checkAgent) setIsAgent(true);
  }, []);

  useEffect(() => {
    const getPermission = async () => {
      const response = await getUserPermission();
      const payload = response?.payload;
      if (payload) {
        const userPerm =
          Array.isArray(payload) &&
          payload.some(
            (permission) =>
              permission.name === 'Approve/Disapprove Transaction',
          );
        setPermission(userPerm);

        const userEditProfilePerm =
          Array.isArray(payload) &&
          payload.some(
            (permission) =>
              permission.name === 'Update Broker Profile' ||
              permission.name === 'Create Broker Profile',
          );
        setEditProfilePermission(userEditProfilePerm);
      }
    };

    getPermission();
  }, [getUserPermission]);

  useEffect(() => {
    (async () => {
      const res = getProfile();

      if (!res?.success) {
        handleReqError(res);
      }
    })();
  }, []);

  useEffect(() => {
    const fetchNotification = async () => {
      const res = await getNotification();

      if (res?.success) {
        setNotificationData(res.data);
        setNotificationCount(res.data.length);
      } else {
        handleReqError(res);
      }
    };

    fetchNotification();
  }, [notification.length]);

  // Function to toggle dropdown menu
  const toggleDropDownState = () => {
    setDropDownState(!dropDownState);
  };

  // Function to toggle notification list
  const toggleNotification = () => {
    setNotificationState(!notificationState);
  };

  return (
    <div className="fixed z-40 justify-between hidden py-3 bg-white border-b border-gray-300 border-solid md:flex px-14 md:w-3/4 lg:w-4/5">
      <SearchBox
        searchItem={props.searchItem}
        clearSearch={props.clearSearch}
        handleChange={props.handleChange}
        autoFocus={props.autoFocus}
        placeholder="Search system..."
      />
      <UserAvatar
        imgUrl={avatar}
        userName={capitalize(
          userDetails &&
            userDetails.basicDetails &&
            userDetails.basicDetails.username,
        )}
        userEmail={
          userDetails &&
          userDetails.basicDetails &&
          userDetails.basicDetails.email
        }
        toggleNotification={toggleNotification}
        toggleDropDownMenu={toggleDropDownState}
        dropDownHidden={dropDownState}
        notificationHidden={notificationState}
        profile={() => {
          setDropDownState(!dropDownState);
          history.push(isAgent ? '/profile' : '/settings');
        }}
        logOut={() => signOut(history)}
        notificationData={notificationData}
        notificationCount={notificationCount}
        setNotificationState={setNotificationState}
        removeNotification={removeNotification}
        permission={permission}
        editProfilePermission={editProfilePermission}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const brokerProfile = state.brokerProfileSettings.brokerAgentProfileData.data;

  return {
    userDetails: state.user.userData,
    avatar:
      (brokerProfile && brokerProfile.Agent.avatar_uri) || state.user.avatar,
    notification: state.notify.notificationData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  signOut: (history) => dispatch(logOutUser(history)),
  getProfile: () => dispatch(getUserDetailsAction()),
  getNotification: () => dispatch(getNotificationAction()),
  removeNotification: (notificationId) =>
    dispatch(updateNotificationAction(notificationId)),
  getUserPermission: () => dispatch(getUserPermissionsAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TopNavBar));
